// @ts-nocheck
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, MenuItem, MenuList } from "@material-ui/core";
import { forwardRef } from "react";
import { useAttributesMenuItemStyles } from "./AttributesMenu.styles";

const AttributesMenuItem = forwardRef(({ label }, ref) => {
  const attributesMenuItemClasses = useAttributesMenuItemStyles();
  return (
    <MenuItem
      button
      dense
      ref={ref}
      className={attributesMenuItemClasses.menuItem}
      style={{ alignItems: "flex-start" }}
    >
      <FontAwesomeIcon
        fixedWidth
        icon={faCircle}
        className={attributesMenuItemClasses.menuItemIcon}
      />
      <Box className={attributesMenuItemClasses.menuItemLabel}>
        <Box className={attributesMenuItemClasses.menuItemLabelTitle}>
          {label}
        </Box>
      </Box>
    </MenuItem>
  );
});

const AttributesMenu = () => {
  // TODO: fetch attributes
  const data = [];
  return (
    <MenuList>
      {data?.attributeNames?.nodes?.map(({ name }, index) => (
        <AttributesMenuItem key={index} label={name} />
      ))}
    </MenuList>
  );
};

export default AttributesMenu;
