import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare, faMinusSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo, useMemo } from "react";

const useCheckboxStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    color: "inherit",
    borderRadius: theme.shape.borderRadius,
  },
}));

const useFontAwesomeCheckboxStyles = makeStyles({
  label: {
    width: "1rem",
    height: "1rem",
    display: "flex",
    alignItems: "inherit",
    justifyContent: "inherit",
  },
});

const FontAwesomeCheckbox = memo((props) => {
  const checkboxClasses = useCheckboxStyles();
  const fontAwesomeCheckboxClasses = useFontAwesomeCheckboxStyles();
  const { label } = fontAwesomeCheckboxClasses;
  const icon = useMemo(
    () => (
      <span className={label}>
        <FontAwesomeIcon fixedWidth icon={faSquare} />
      </span>
    ),
    [label]
  );
  const checkedIcon = useMemo(
    () => (
      <span className={label}>
        <FontAwesomeIcon fixedWidth icon={faCheckSquare} />
      </span>
    ),
    [label]
  );
  const indeterminateIcon = useMemo(
    () => (
      <span className={label}>
        <FontAwesomeIcon fixedWidth icon={faMinusSquare} />
      </span>
    ),
    [label]
  );
  const TouchRippleProps = { center: false };
  return (
    <Checkbox
      color="default"
      classes={checkboxClasses}
      icon={icon}
      checkedIcon={checkedIcon}
      indeterminateIcon={indeterminateIcon}
      TouchRippleProps={TouchRippleProps}
      {...props}
    />
  );
});

export default FontAwesomeCheckbox;
