import { memo, useState } from "react";
import { ConfigurationProvider } from "contexts";

const ConfigurationWrapper = memo(({ children }: any) => {
  const {
    NODE_ENV,
    PUBLIC_URL,
    REACT_APP_ENVIRONMENT,
    REACT_APP_GRAPHQL_URL,
    REACT_APP_WEBSOCKET_URL,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_OPTIMIZELY_SDK_KEY,
    REACT_APP_DISWA_HOST,
    REACT_APP_DISWA_PORT,
    REACT_APP_DISWA_USE_TLS,
  } = process.env;
  const { protocol, hostname, port } = window.location;
  const rHostname = NODE_ENV === "development" ? hostname : `hive.${hostname}`;
  const rPort = NODE_ENV === "development" ? "3001" : port;
  const rHttpProtocol = protocol;
  const rWsProtocol = protocol.replace("http", "ws");
  const {
    nodeEnv = NODE_ENV,
    publicUrl = PUBLIC_URL,
    ENVIRONMENT: environment = REACT_APP_ENVIRONMENT,
    GRAPHQL_URL: graphqlUrl = REACT_APP_GRAPHQL_URL ||
      `${rHttpProtocol}//${[rHostname, rPort].join(":")}/graphql`,
    WEBSOCKET_URL: websocketUrl = REACT_APP_WEBSOCKET_URL ||
      `${rWsProtocol}//${[rHostname, rPort].join(":")}/graphql`,
    AUTH0_DOMAIN: auth0Domain = REACT_APP_AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: auth0ClientId = REACT_APP_AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: auth0Audience = REACT_APP_AUTH0_AUDIENCE,
    OPTIMIZELY_SDK_KEY: optimizelySdkKey = REACT_APP_OPTIMIZELY_SDK_KEY,
    DISWA_HOST: diswaHost = REACT_APP_DISWA_HOST,
    DISWA_PORT: diswaPort = REACT_APP_DISWA_PORT,
    DISWA_USE_TLS: diswaUseTls = REACT_APP_DISWA_USE_TLS,
  } = window as any;
  const [configuration] = useState({
    environment,
    nodeEnv,
    publicUrl,
    graphqlUrl,
    websocketUrl,
    auth0Domain,
    auth0ClientId,
    auth0Audience,
    optimizelySdkKey,
    optimizelyDatafile: null,
    diswaHost: diswaHost || "localhost",
    diswaPort: parseInt(diswaPort) || 443,
    diswaUseTls: diswaUseTls === "true" || false,
  });
  return (
    <ConfigurationProvider value={{ configuration }}>
      {children}
    </ConfigurationProvider>
  );
});

export default ConfigurationWrapper;
