// @ts-nocheck
import {
  DataRoomsTableHeadRow,
  DataRoomTableBodyRow,
  DataTable,
} from "components";
import { DataViewWrapper } from "wrappers";

const defaultViews = [
  {
    name: "compact",
    label: "Compact",
    isDisabled: false,
    isSelected: false,
  },
  {
    name: "detailed",
    label: "Detailed",
    info: "Includes meta info and tags",
    isDisabled: false,
    isSelected: true,
  },
];

const defaultColumns = [
  {
    name: "dataRoom",
    label: "DataRoom",
    isDisabled: true,
    isSelected: true,
  },
  {
    name: "owner",
    label: "Owner",
    isDisabled: false,
    isSelected: true,
  },
  {
    name: "participants",
    label: "Participants",
    isDisabled: false,
    isSelected: true,
  },
];

const defaultGroups = [];

const DataRoomsTable = ({
  columns = defaultColumns,
  selectedColumns,
  groups = defaultGroups,
  selectedGroups,
  views = defaultViews,
  selectedViews,
  ...props
}) => (
  <DataViewWrapper
    columns={columns}
    selectedColumns={selectedColumns}
    groups={groups}
    selectedGroups={selectedGroups}
    views={views}
    selectedViews={selectedViews}
  >
    <DataTable
      TableHeadRow={DataRoomsTableHeadRow}
      TableBodyRow={DataRoomTableBodyRow}
      {...props}
    />
  </DataViewWrapper>
);

export default DataRoomsTable;
