import { gql } from "@apollo/client";

export const DATA_ROOM_SHARE_EVERYTHING = gql`
  fragment DataRoomShareEverything on DataRoomShare {
    id
    dataRoomShareId
    dataRoomId
    userEmail
    isFavorite
    updatedAt
    createdAt
    ownerEmail
  }
`;
