// @ts-nocheck
import { useSelections } from "ahooks";
import { memo, useEffect } from "react";
import { DataViewProvider, useData } from "contexts";

const DataViewWrapper = memo(
  ({
    onSelect = () => {},
    views,
    selectedViews,
    columns,
    selectedColumns,
    groups,
    selectedGroups,
    children,
  }) => {
    const { data, dataKeyName, nodeIdKeyName } = useData();
    const nodes = data?.[dataKeyName]?.nodes || [];
    const ids = nodes.filter(Boolean).map((node) => node[nodeIdKeyName]);
    const selections = useSelections(ids);
    selections.all = ids;
    const { selected } = selections;
    useEffect(() => onSelect(selected), [selected, onSelect]);
    const viewsSelections = useSelections(
      views,
      selectedViews ||
        views.filter((view) => view.isSelected).map((view) => view.name)
    );
    viewsSelections.all = views;
    const columnsSelections = useSelections(
      columns,
      selectedColumns ||
        columns
          .filter((column) => column.isSelected)
          .map((column) => column.name)
    );
    columnsSelections.all = columns;
    const groupsSelections = useSelections(
      groups,
      selectedGroups ||
        groups.filter((group) => group.isSelected).map((group) => group.name)
    );
    groupsSelections.all = groups;
    return (
      <DataViewProvider
        value={{
          selections,
          groupsSelections,
          columnsSelections,
          viewsSelections,
        }}
      >
        {children}
      </DataViewProvider>
    );
  }
);

export default DataViewWrapper;
