// @ts-nocheck
import { memo } from "react";
import { useDataView } from "contexts";
import FontAwesomeCheckbox from "./FontAwesomeCheckbox";
import FontAwesomeRadiobox from "./FontAwesomeRadiobox";

const Select = ({ id, type }) => {
  const { selections } = useDataView();
  const {
    toggle,
    isSelected,
    setSelected,
    allSelected,
    partiallySelected,
    toggleAll,
  } = selections;
  const checked = isSelected(id);
  switch (type) {
    case "all":
      return (
        <FontAwesomeCheckbox
          onChange={toggleAll}
          checked={allSelected}
          indeterminate={partiallySelected}
        />
      );
    case "any":
      return (
        <FontAwesomeCheckbox
          onChange={toggle.bind(this, id)}
          checked={checked}
        />
      );
    case "one":
    default:
      return (
        <FontAwesomeRadiobox
          onChange={setSelected.bind(this, [id])}
          checked={checked}
        />
      );
  }
};

Select.All = memo((props) => (
  <Select type="all" {...props} variant="standard" />
));

Select.Any = memo((props) => (
  <Select type="any" {...props} variant="standard" />
));

Select.One = memo((props) => (
  <Select type="one" {...props} variant="standard" />
));

export default Select;
