import { faCheck, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import { useSelections } from "ahooks";
import { Field, Form, Formik } from "formik";
import { DataRoomTable, DataRoomTableDefinition } from "models";
import { Fragment, useMemo, useState } from "react";
import { DataRoomTableColumnConstructor } from "./DataRoomTableColumnConstructor";
import { dataRoomTableInitialValues } from "./DataRoomTableConstructorInitialValues";
import { dataRoomTableValidationSchema } from "./DataRoomTableConstrustorValidationScheme";
import DataRoomTableTile from "./DataRoomTableTile";

export enum DataRoomTableConstructorMode {
  EDIT = "edit",
  READONLY = "readonly",
  STATUS = "status",
  ACTION = "action",
}

interface DataRoomTableConstructorModeFlags {
  readonly: boolean;
  withStatus: boolean;
  withUploading: boolean;
}

interface DataRoomTableConstructorProps {
  tables: DataRoomTable[];
  onUpdate: (table: DataRoomTable) => void;
  onCreate: (tableDefinition: DataRoomTableDefinition) => void;
  onDelete: (table: DataRoomTable) => void;
  renderDataUploading?: (table: DataRoomTable) => React.ReactNode;
  mode: DataRoomTableConstructorMode;
}

interface DataRoomTableFormProps {
  onSubmit: (tableDefinition: DataRoomTableDefinition) => void;
  onCancel?: () => void;
  isEditing?: boolean;
  disabled?: boolean;
  initialValues?: DataRoomTableDefinition;
}

const DataRoomTableForm: React.FC<DataRoomTableFormProps> = ({
  initialValues = dataRoomTableInitialValues,
  isEditing = false,
  onSubmit,
  onCancel,
  disabled,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(column, helpers) => {
      onSubmit(column);
      helpers.resetForm();
    }}
    validationSchema={dataRoomTableValidationSchema}
    validateOnMount
  >
    {(formProps) => (
      <Form style={{ width: "100%" }}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item container xs={12} flexWrap="nowrap" alignItems="flex-end">
            <Field<
              DataRoomTableDefinition["name"],
              DataRoomTableDefinition
            > name="name">
              {({ field }) => (
                <FormControl fullWidth>
                  <FormLabel component="legend" style={{ fontSize: ".75rem" }}>
                    Table name
                  </FormLabel>
                  <TextField
                    fullWidth
                    disabled={disabled}
                    autoComplete="off"
                    size="small"
                    {...field}
                    variant="outlined"
                  ></TextField>
                </FormControl>
              )}
            </Field>
            {isEditing && (
              <IconButton size="small" onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} fixedWidth></FontAwesomeIcon>
              </IconButton>
            )}
            <IconButton
              size="small"
              disabled={!formProps.isValid}
              type="submit"
            >
              <FontAwesomeIcon
                icon={isEditing ? faCheck : faPlus}
                fixedWidth
              ></FontAwesomeIcon>
            </IconButton>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
);

const DataRoomTableConstructor: React.FC<DataRoomTableConstructorProps> = ({
  tables,
  onCreate,
  onUpdate,
  onDelete,
  mode,
  renderDataUploading,
}) => {
  const [rowToEditIndex, setRowToEditIndex] = useState<number>(-1);
  const indexes = useMemo(
    () => Array.from({ length: tables.length }).map((_, i) => i),
    [tables.length]
  );
  const { isSelected, toggle } = useSelections(indexes, []);
  const hasEditableTable = rowToEditIndex !== -1;
  const {
    readonly,
    withStatus,
    withUploading,
  }: DataRoomTableConstructorModeFlags = useMemo(
    () => ({
      readonly: mode !== DataRoomTableConstructorMode.EDIT,
      withStatus:
        mode === DataRoomTableConstructorMode.STATUS ||
        mode === DataRoomTableConstructorMode.ACTION,
      withUploading: mode === DataRoomTableConstructorMode.ACTION,
    }),
    [mode]
  );
  return (
    <Box>
      {tables.length > 0 && (
        <List
          style={{ background: "#f9f9f9", padding: "0", marginBottom: "8px" }}
        >
          {tables.map((table, index) => (
            <Fragment key={index}>
              {rowToEditIndex === index ? (
                <ListItem>
                  <DataRoomTableForm
                    onSubmit={(tableDefinition) => {
                      onUpdate({
                        ...table,
                        ...tableDefinition,
                      });
                      setRowToEditIndex(-1);
                    }}
                    isEditing
                    onCancel={() => setRowToEditIndex(-1)}
                    initialValues={table}
                  ></DataRoomTableForm>
                </ListItem>
              ) : (
                <DataRoomTableTile
                  index={index}
                  table={table}
                  withStatus={withStatus}
                  withUploading={withUploading}
                  readonly={readonly}
                  allowEditing={!hasEditableTable}
                  isCollapsed={!isSelected(index)}
                  onEdit={setRowToEditIndex}
                  onDelete={onDelete}
                  onToggleCollapsion={toggle}
                  renderDataUploading={renderDataUploading}
                />
              )}
              <Collapse in={isSelected(index)} timeout="auto" unmountOnExit>
                <Grid container>
                  <Grid item xs={12}>
                    <DataRoomTableColumnConstructor
                      value={table.columns}
                      readonly={readonly}
                      onChange={(columns) => {
                        onUpdate({
                          ...table,
                          columns,
                        });
                      }}
                    ></DataRoomTableColumnConstructor>
                  </Grid>
                </Grid>
              </Collapse>
              {index !== tables.length - 1 && (
                <Divider style={{ borderBottomWidth: "1px" }} />
              )}
            </Fragment>
          ))}
        </List>
      )}
      {!readonly && (
        <>
          <List style={{ background: "#f9f9f9", padding: "8px" }}>
            <ListItem style={{ padding: 0 }}>
              <DataRoomTableForm
                onSubmit={(table) => {
                  onCreate(table);
                  toggle(tables.length);
                }}
                disabled={hasEditableTable}
              ></DataRoomTableForm>
            </ListItem>
          </List>
        </>
      )}
    </Box>
  );
};

export default DataRoomTableConstructor;
