// @ts-nocheck
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faFileCheck as faFileCheckRegular,
  faFolderOpen as faFolderOpenRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  RadioGroup,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { format, isValid as isDateValid } from "date-fns";
import { FastField, Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { CustomInput } from "components";
import FontAwesomeRadiobox from "components/base/Select/FontAwesomeRadiobox";

const useDialogTitleStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const useDialogContentStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    "&:first-child": {
      paddingTop: theme.spacing(1),
    },
  },
}));

const useDialogActionsStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const useRadioGroupStyles = makeStyles((theme: Theme) => ({
  root: {
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
  },
}));

const useFontControlLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 0,
  },
  label: {
    fontSize: "0.875rem",
  },
}));

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "1rem",
    lineHeight: 1.5,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
  },
}));

const useLoadingButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "1rem",
    fontWeight: 700,
    letterSpacing: "-0.25px",
    lineHeight: 1.5,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: theme.spacing(1),
      "& > *:first-child": {
        fontSize: "1.125rem",
      },
    },
  },
  loadingIndicatorStart: {
    left: theme.spacing(1),
  },
}));

const useDataRoomCreateDialogStyles = makeStyles((theme: Theme) => ({
  configurationSourceImportWrapper: {
    display: "flex",
    alignItems: "center",
  },
  configurationSourceImportFromWrapper: {
    marginRight: theme.spacing(1),
  },
  configurationSourceImportFileWrapper: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    lineHeight: 1,
    padding: theme.spacing(0.5),
    margin: theme.spacing(-0.5),
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: theme.palette.action.selected,
    },
  },
}));

const CONFIGURATION_SOURCE_NONE = "none";
const CONFIGURATION_SOURCE_IMPORT = "import";
const CONFIGURATION_SOURCE_CLONE = "clone";
const CONFIGURATION_SOURCE_TEMPLATE = "template";
const IS_FEATURE_CONFIGURATION_SOURCE_NONE_ENABLED = true;
const IS_FEATURE_CONFIGURATION_SOURCE_IMPORT_ENABLED = true;
const IS_FEATURE_CONFIGURATION_SOURCE_CLONE_ENABLED = false;
const IS_FEATURE_CONFIGURATION_SOURCE_TEMPLATE_ENABLED = false;

const DataRoomCreateDialog = ({
  initialConfigurationSource = CONFIGURATION_SOURCE_NONE,
  open,
  loading,
  onCancel,
  onConfirm,
}: any) => {
  const dialogTitleClasses = useDialogTitleStyles();
  const dialogContentClasses = useDialogContentStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const radioGroupClasses = useRadioGroupStyles();
  const fontControlLabelClasses = useFontControlLabelStyles();
  const buttonClasses = useButtonStyles();
  const loadingButtonClasses = useLoadingButtonStyles();
  const dataRoomCreateDialogClasses = useDataRoomCreateDialogStyles();
  const {
    configurationSourceImportWrapper,
    configurationSourceImportFromWrapper,
    configurationSourceImportFileWrapper,
  } = dataRoomCreateDialogClasses;
  const [configuration, setConfiguration] = useState({});
  const [configurationSource, setConfigurationSource] = useState(
    initialConfigurationSource
  );
  const changeConfigurationSource = (event) => {
    setConfigurationSource(event.target.value);
  };
  const [importFile, setImportFile] = useState();
  const { lastModifiedDate, name, size, type } = importFile || {};
  const changeFile = (event) => {
    event.stopPropagation();
    selectFile();
  };
  const selectFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      const file = event.target.files[0];
      setImportFile(file);
      event.target.value = "";
    };
    input.click();
  };
  useEffect(() => {
    setConfigurationSource(initialConfigurationSource);
  }, [initialConfigurationSource]);
  useEffect(() => {
    if (importFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        try {
          const content = JSON.parse(event.target.result);
          // TODO:
          // * validate JSON
          // * if name and description in form are empty then use values from the JSON
          setConfiguration(content);
        } catch (error) {
          console.error(error);
        }
      };
      reader.readAsText(importFile);
    }
  }, [importFile]);
  useEffect(() => {
    if (open) {
      setConfigurationSource(initialConfigurationSource);
    } else {
      setImportFile(undefined);
    }
  }, [open, initialConfigurationSource]);
  const onSubmit = ({ name, description }: any) => {
    onConfirm({
      name,
      description,
      configuration,
    });
  };
  const initialValues = {
    name: "",
    description: "",
  };
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Must specify name")
      .min(1, "Minimum 1 character"),
  });
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={!loading ? onCancel : null}
    >
      <DialogTitle classes={dialogTitleClasses} disableTypography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="div">
            <strong>New data room</strong>
          </Typography>
          <Box>
            <IconButton color="inherit" onClick={!loading ? onCancel : null}>
              <FontAwesomeIcon fixedWidth icon={faTimes} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ values, handleReset, handleSubmit, isValid, isSubmitting }) => (
          <form onReset={handleReset} onSubmit={handleSubmit}>
            <DialogContent classes={dialogContentClasses}>
              <Box>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <strong>Name</strong>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <FastField
                      name="name"
                      as={CustomInput}
                      autoComplete="off"
                      type="text"
                      placeholder="Collaborative analytics"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <strong>Description</strong>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <FastField
                      name="description"
                      autoComplete="off"
                      as={CustomInput}
                      type="text"
                      placeholder="We use this clean data room to jointly analyse our data and get valuable insights"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <strong>Start configuration</strong>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <FormControl component="fieldset" variant="standard">
                      <RadioGroup
                        classes={radioGroupClasses}
                        row
                        value={configurationSource}
                        onChange={changeConfigurationSource}
                      >
                        {IS_FEATURE_CONFIGURATION_SOURCE_NONE_ENABLED ? (
                          <FormControlLabel
                            value={CONFIGURATION_SOURCE_NONE}
                            control={
                              <FontAwesomeRadiobox
                                style={{
                                  fontSize: "0.875rem",
                                  padding: "0.25rem",
                                }}
                              />
                            }
                            label="None"
                            classes={fontControlLabelClasses}
                            variant="standard"
                          />
                        ) : null}
                        {IS_FEATURE_CONFIGURATION_SOURCE_IMPORT_ENABLED ? (
                          <FormControlLabel
                            value={CONFIGURATION_SOURCE_IMPORT}
                            control={
                              <FontAwesomeRadiobox
                                style={{
                                  fontSize: "0.875rem",
                                  padding: "0.25rem",
                                }}
                              />
                            }
                            label={
                              <div className={configurationSourceImportWrapper}>
                                <div
                                  className={
                                    configurationSourceImportFromWrapper
                                  }
                                >
                                  Import from
                                </div>
                                {importFile ? (
                                  <Tooltip
                                    title={
                                      <div>
                                        <div>
                                          {name} will be used as the start
                                          configuration
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: 400,
                                            opacity: 0.75,
                                          }}
                                        >
                                          ({type}, {size} bytes,
                                          {isDateValid(lastModifiedDate)
                                            ? ` last modified on ${format(
                                                lastModifiedDate,
                                                "PPPP'\nat 'pppp"
                                              )}`
                                            : null}
                                          )
                                        </div>
                                      </div>
                                    }
                                    placement="top"
                                    disableHoverListener={
                                      configurationSource !==
                                      CONFIGURATION_SOURCE_IMPORT
                                    }
                                    disableFocusListener
                                    disableTouchListener
                                  >
                                    <div
                                      className={
                                        configurationSourceImportFileWrapper
                                      }
                                      onClick={changeFile}
                                    >
                                      <FontAwesomeIcon
                                        icon={faFileCheckRegular}
                                        style={{ marginRight: "4px" }}
                                      />
                                      {name}
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div
                                    className={
                                      configurationSourceImportFileWrapper
                                    }
                                    onClick={changeFile}
                                  >
                                    <FontAwesomeIcon
                                      icon={faFolderOpenRegular}
                                      style={{ marginRight: "4px" }}
                                    />
                                    Select file
                                  </div>
                                )}
                              </div>
                            }
                            classes={fontControlLabelClasses}
                            variant="standard"
                          />
                        ) : null}
                        {IS_FEATURE_CONFIGURATION_SOURCE_CLONE_ENABLED ? (
                          <FormControlLabel
                            value={CONFIGURATION_SOURCE_CLONE}
                            control={
                              <FontAwesomeRadiobox
                                style={{
                                  fontSize: "0.875rem",
                                  padding: "0.25rem",
                                }}
                              />
                            }
                            label="Clone data room"
                            classes={fontControlLabelClasses}
                            variant="standard"
                            disabled
                          />
                        ) : null}
                        {IS_FEATURE_CONFIGURATION_SOURCE_TEMPLATE_ENABLED ? (
                          <FormControlLabel
                            value={CONFIGURATION_SOURCE_TEMPLATE}
                            control={
                              <FontAwesomeRadiobox
                                style={{
                                  fontSize: "0.875rem",
                                  padding: "0.25rem",
                                }}
                              />
                            }
                            label="Use template"
                            classes={fontControlLabelClasses}
                            variant="standard"
                            disabled
                          />
                        ) : null}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <Divider />
            <DialogActions classes={dialogActionsClasses}>
              <Button
                classes={buttonClasses}
                color="inherit"
                variant="outlined"
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </Button>
              <LoadingButton
                classes={loadingButtonClasses}
                color="inherit"
                variant="outlined"
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={loading}
                loadingPosition="start"
                startIcon={<FontAwesomeIcon icon={faCheck} />}
              >
                Create
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DataRoomCreateDialog;
