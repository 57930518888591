import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_EVERYTHING } from "gqls";

export const DELETE_DATA_ROOM_QUERY = gql`
  mutation deleteDataRoomQuery($input: DeleteDataRoomQueryInput!) {
    deleteDataRoomQuery(input: $input) {
      clientMutationId
      dataRoomQuery {
        ...DataRoomQueryEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_EVERYTHING}
`;
