import { Box, CircularProgress, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { memo, useCallback } from "react";
import TimeAgo, { Formatter } from "react-timeago";
import SqlEditor from "components/base/SqlEditor/SqlEditor";

interface DataRoomQueryEditorProps {
  dataRoomQueryId: string;
  defaultValue: string;
  onChange: (dataRoomQueryId: string, sqlSelectStatement: string) => void;
  readonly?: boolean;
  isSaving: boolean;
  updatedAt?: string;
}

const DataRoomQueryEditor: React.FC<DataRoomQueryEditorProps> = memo(
  ({
    defaultValue,
    onChange,
    dataRoomQueryId,
    readonly,
    isSaving,
    updatedAt,
  }) => {
    const timeFormatter = useCallback<Formatter>(
      (value, unit, suffix) =>
        unit === "second" && value <= 59
          ? "just now"
          : `${value} ${unit}${value === 1 ? "" : "s"} ${suffix}`,
      []
    );
    return (
      <Box style={{ position: "relative" }}>
        <SqlEditor
          defaultValue={defaultValue}
          editorOptions={{ readOnly: readonly }}
          onChange={(value) => onChange(dataRoomQueryId, value as string)}
        />
        <Box style={{ position: "absolute", bottom: "8px", right: "16px" }}>
          {updatedAt && !isSaving && !readonly ? (
            <Typography variant="caption" color={grey["400"]} fontSize=".65rem">
              Last saved <TimeAgo date={updatedAt} formatter={timeFormatter} />
            </Typography>
          ) : undefined}
          {isSaving && <CircularProgress size={16} color="inherit" />}
        </Box>
      </Box>
    );
  }
);

export default DataRoomQueryEditor;
