import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axaLogoSrc from "assets/logos/axa-logo.png";
import bayerLogoSrc from "assets/logos/bayer-logo.png";
import creditSuisseLogoSrc from "assets/logos/credit-suisse-logo.png";
import dbLogoSrc from "assets/logos/db-logo.png";
import { ReactComponent as DecentriqPlatformLogo } from "assets/logos/decentriq-platform-logo.svg";
import ergoLogoSrc from "assets/logos/ergo-logo.png";
import evonikLogoSrc from "assets/logos/evonik-logo.png";
import generaliLogoSrc from "assets/logos/generali-logo.png";
import groupemutuelLogoSrc from "assets/logos/groupemutuel-logo.png";
import imLogoSrc from "assets/logos/im-logo.jpg";
import lynxLogoSrc from "assets/logos/lynxcare-logo.png";
import mobiLogoSrc from "assets/logos/mobi-logo.png";
import pfLogoSrc from "assets/logos/pf-logo.png";
import phamaxLogoSrc from "assets/logos/phamax-logo.png";
import progressiveLogoSrc from "assets/logos/progressive-logo.png";
import rocheLogoSrc from "assets/logos/roche-logo.png";
import sanofiLogoSrc from "assets/logos/sanofi-logo.png";
import { ReactComponent as SwissReLogo } from "assets/logos/swiss-re-logo.svg";
import ubsLogoSrc from "assets/logos/ubs-logo.png";
import vkbLogoSrc from "assets/logos/vkb-logo.png";
import { ReactComponent as ZurichLogo } from "assets/logos/zurich-logo.svg";

const CUSTOM_LOGO_ENABLED = true;

const useCustomLogoStyles = makeStyles((theme) => ({
  powered: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const customBrandingLogos = {
  "swissre.com": <SwissReLogo style={{ height: "2.5rem" }} />,
  "db.com": (
    <img src={dbLogoSrc} style={{ height: "2.5rem" }} alt="Deutsche Bank" />
  ),
  "zuerich.ch": <ZurichLogo style={{ height: "2.5rem" }} />,
  "postfinance.ch": (
    <img src={pfLogoSrc} style={{ height: "2.5rem" }} alt="Post Finance" />
  ),
  "im-associates.eu": (
    <img src={imLogoSrc} style={{ height: "2.5rem" }} alt="IM Assosiates" />
  ),
  "axa.ch": <img src={axaLogoSrc} style={{ height: "2.5rem" }} alt="AXA" />,
  "bayer.com": (
    <img src={bayerLogoSrc} style={{ height: "2.5rem" }} alt="Bayer" />
  ),
  "credit-suisse.com": (
    <img
      src={creditSuisseLogoSrc}
      style={{ height: "2.5rem" }}
      alt="Credit Suisse"
    />
  ),
  "evonik.com": (
    <img src={evonikLogoSrc} style={{ height: "2.5rem" }} alt="Evonik" />
  ),
  "ergo.de": <img src={ergoLogoSrc} style={{ height: "2.5rem" }} alt="Ergo" />,
  "ubs.com": <img src={ubsLogoSrc} style={{ height: "2.5rem" }} alt="UBS" />,
  "progressive.com": (
    <img
      src={progressiveLogoSrc}
      style={{ height: "1.5rem" }}
      alt="Progressive"
    />
  ),
  "lynxcare.eu": (
    <img src={lynxLogoSrc} style={{ height: "2.5rem" }} alt="Lynxcare" />
  ),
  "roche.com": (
    <img src={rocheLogoSrc} style={{ height: "2.5rem" }} alt="Roche" />
  ),
  "generali.com": (
    <img src={generaliLogoSrc} style={{ width: "6.5rem" }} alt="Generali" />
  ),
  "sanofi.com": (
    <img src={sanofiLogoSrc} style={{ height: "2.5rem" }} alt="Sanofi" />
  ),
  "mobi.ch": (
    <img src={mobiLogoSrc} style={{ height: "2.5rem" }} alt="Mobiliar" />
  ),
  "groupemutuel.ch": (
    <img
      src={groupemutuelLogoSrc}
      style={{ height: "2.5rem" }}
      alt="GroupeMutuel"
    />
  ),
  "vkb.de": <img src={vkbLogoSrc} style={{ height: "3rem" }} alt="VKB" />,
  "phamax.com": (
    <img src={phamaxLogoSrc} style={{ height: "2.5rem" }} alt="Phamax" />
  ),
};

const CustomBrandingLogo = () => {
  const brandingClasses = useCustomLogoStyles();
  const { isAuthenticated, user, isLoading } = useAuth0();
  if (isLoading) {
    return <Box style={{ height: "2.5rem" }}></Box>;
  }
  const standartLogo = (
    <DecentriqPlatformLogo
      viewBox="-4 -4 428 48"
      style={{ height: "1.2rem", strokeWidth: "4px" }}
    />
  );
  if (!CUSTOM_LOGO_ENABLED) {
    return standartLogo;
  }
  if (!isAuthenticated) {
    return standartLogo;
  }
  const domain =
    user?.email?.slice(user?.email?.indexOf("@") + 1).toLowerCase() || "";
  // @ts-ignore
  const hasCustomBranding = !!customBrandingLogos[domain];
  if (hasCustomBranding) {
    return (
      <Box style={{ display: "flex", alignItems: "center" }}>
        {/* @ts-ignore*/}
        {customBrandingLogos[domain]}
        <Box
          style={{
            display: "inline-flex",
            flexDirection: "column",
            marginLeft: "1rem",
          }}
        >
          <Typography variant="caption" className={brandingClasses.powered}>
            powered by
          </Typography>
          <DecentriqPlatformLogo
            viewBox="-4 -4 428 48"
            style={{ height: ".7rem", strokeWidth: "4px" }}
          />
        </Box>
      </Box>
    );
  }
  return standartLogo;
};

export default CustomBrandingLogo;
