import { DataRoomQueryDefinition } from "models";
import * as yup from "yup";

export const dataRoomQueryValidationSchema: yup.SchemaOf<DataRoomQueryDefinition> =
  yup
    .object()
    .noUnknown()
    .shape({
      name: yup.string().required("Query name is required"),
    })
    .defined();
