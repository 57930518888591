// @ts-nocheck
import { useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { DataProvider } from "contexts";

const DataWrapper = ({
  query,
  variables: propVariables = {},
  filterBy = [],
  orderByOptions = [],
  pageSize: pageSizeProp = 10,
  dataKeyName,
  nodeIdKeyName,
  onSelect = () => {},
  children,
}) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(pageSizeProp);
  const [orderBy, setOrderBy] = useState(
    orderByOptions.find((orderByOption) => orderByOption.default)?.orderBy ||
      orderByOptions[0]?.orderBy ||
      propVariables.orderBy ||
      []
  );
  const [condition, setCondition] = useState({});
  const mergeCondition = useCallback(
    (newCondition) => setCondition({ ...condition, ...newCondition }),
    [condition]
  );
  // const filter = search
  //   ? {
  //       or: filterBy.map((key) => ({
  //         [key]: { includesInsensitive: search },
  //       })),
  //     }
  //   : undefined;
  const offset = (page - 1) * pageSize;
  const {
    data,
    loading,
    refetch,
    fetchMore: fetchMoreFunc,
  } = useQuery(query, {
    variables: {
      ...propVariables,
      condition: {
        ...propVariables.condition,
        ...condition,
      },
      // filter,
      first: pageSize,
      offset,
      orderBy,
    },
  });
  const dataNodesCount = data?.[dataKeyName]?.nodes?.length || 0;
  const dataTotalCount = data?.[dataKeyName]?.totalCount || 0;
  const fetchMore = () =>
    fetchMoreFunc({
      variables: {
        offset: dataNodesCount,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          [dataKeyName]: {
            ...fetchMoreResult[dataKeyName],
            nodes: [
              ...prev[dataKeyName].nodes,
              ...fetchMoreResult[dataKeyName].nodes,
            ],
          },
        };
      },
    });
  return (
    <DataProvider
      value={{
        data,
        dataKeyName,
        nodeIdKeyName,
        loading,
        refetch,
        fetchMore,
        search,
        setSearch,
        page,
        setPage,
        pageSize,
        setPageSize,
        offset,
        dataTotalCount,
        orderBy,
        setOrderBy,
        condition,
        setCondition,
        mergeCondition,
      }}
    >
      {children}
    </DataProvider>
  );
};
export default DataWrapper;
