import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, isAuthenticated } = useAuth0();
  useEffect(() => {
    if (!isLoading) {
      navigate(isAuthenticated ? "/datarooms" : `/sign-in${location.search}`);
    }
  }, [isLoading, isAuthenticated, location, navigate]);
  return null;
};

export default HomePage;
