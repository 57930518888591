// @ts-nocheck
import { Skeleton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";
import { useBlockStyles } from "styles/block";

const useSkeletonStyles = makeStyles((theme: Theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const useMetaWrapperStyles = makeStyles((theme: Theme) => ({
  metaWrapper: {
    fontSize: "0.75rem",
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap",
    lineHeight: 1.5,
    width: "100%",
    height: "auto",
    overflow: "hidden",
    maskImage: "linear-gradient(to right, black 90%, transparent 100%)",
  },
}));

const BlockLoading = memo(({ withMeta = false, withAttributes = false }) => {
  const blockClasses = useBlockStyles();
  const skeletonClasses = useSkeletonStyles();
  const metaWrapperClasses = useMetaWrapperStyles();
  return (
    <div className={blockClasses.wrapper}>
      <div className={blockClasses.statusWrapper}>
        <Skeleton
          variant="rectangular"
          height={24}
          width={24}
          classes={skeletonClasses}
        />
      </div>
      <div className={blockClasses.nameMetaActionsWrapper}>
        <div className={blockClasses.nameActionsWrapper}>
          <div className={blockClasses.nameWrapper}>
            <Skeleton
              variant="rectangular"
              height={24}
              width="100%"
              classes={skeletonClasses}
            />
          </div>
        </div>
        {(withAttributes || withMeta) && (
          <div className={metaWrapperClasses.metaWrapper}>
            {[...Array(Math.floor(Math.random() * 6) + 1).keys()].map(
              (index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={18}
                  width={Math.floor(Math.random() * (100 - 10 + 1) + 10)}
                  style={{ marginTop: 4, marginRight: 4 }}
                  classes={skeletonClasses}
                />
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default BlockLoading;
