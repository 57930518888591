import { DataRoomTableColumnDefinition, DataRoomTableDefinition } from "models";

export const dataRoomTableColumnInitialValues: DataRoomTableColumnDefinition = {
  name: "",
  primitiveType: undefined,
  nullable: false,
};

export const dataRoomTableInitialValues: DataRoomTableDefinition = {
  name: "",
  columns: [],
};
