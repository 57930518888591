// @ts-nocheck
import {
  faPen as faPenRegular,
  faQuestionCircle as faQuestionCircleRegular,
  faShieldKeyhole as faShieldKeyholeRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";
import { StatusTooltipTitle } from "components";

const useStateIconStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typography.htmlFontSize,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

export const StateIcon = memo(({ status }) => {
  const statusIconClasses = useStateIconStyles();
  switch ((status || "").toUpperCase()) {
    case "BUSY":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <CircularProgress
            color="inherit"
            size="100%"
            className={statusIconClasses.icon}
          />
        </span>
      );
    case "PUBLISHED":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            fixedWidth
            icon={faShieldKeyholeRegular}
            className={statusIconClasses.icon}
          />
        </span>
      );
    case "DRAFT":
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            icon={faPenRegular}
            className={statusIconClasses.icon}
          />
        </span>
      );
    default:
      return (
        <span className={statusIconClasses.iconWrapper}>
          <FontAwesomeIcon
            fixedWidth
            icon={faQuestionCircleRegular}
            className={statusIconClasses.icon}
            color={BLUEGREY}
          />
        </span>
      );
  }
});

const StateIconWithTooltip = memo(
  ({
    status = "N/A",
    statusUpdatedAt,
    statusTimePrefix = "",
    statusTimeSuffix = "",
    className,
  }) => {
    return (
      <Tooltip
        title={
          <StatusTooltipTitle
            status={status}
            statusUpdatedAt={statusUpdatedAt}
            statusTimePrefix={statusTimePrefix}
            statusTimeSuffix={statusTimeSuffix}
          />
        }
        placement="top-start"
        disableFocusListener
        disableTouchListener
      >
        <span className={className}>
          <StateIcon status={status} />
        </span>
      </Tooltip>
    );
  }
);

export default StateIconWithTooltip;
