// @ts-nocheck
import { Skeleton, TableCell, TableRow, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { ContactBlock, DataRoomBlock, Select } from "components";
import { useDataRoomsTableCellStyles } from "components/entities/dataRooms/DataRoomsTable/DataRoomsTable.styles";
import { useData, useDataView } from "contexts";
import { useCustomTableStyles } from "styles/table";

const useSkeletonStyles = makeStyles((theme: Theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const DataRoomTableBodyRow = ({
  withSelections = false,
  withMultiselections = false,
  forceWideCells = false,
  node: dataRoom,
  node: { id, dataRoomId },
  style,
}) => {
  // Styles
  const customTableClasses = useCustomTableStyles({ forceWideCells });
  const dataRoomsTableCellClasses = useDataRoomsTableCellStyles();
  const skeletonClasses = useSkeletonStyles();
  // Data
  const { loading } = useData();
  const { contact } = dataRoom || {};
  // Data view
  const { columnsSelections, viewsSelections } = useDataView();
  const { isSelected } = columnsSelections;
  const { selected } = viewsSelections;
  const withMeta = selected.includes("detailed");
  // Actions
  const onClickStopPropagation = (event) => event.stopPropagation();
  return (
    <TableRow
      component="div"
      className={customTableClasses.MuiTableRow}
      onClick={onClickStopPropagation}
    >
      {withMultiselections || withSelections ? (
        <TableCell component="div" padding="checkbox">
          {loading ? (
            <Skeleton
              variant="rectangular"
              height={24}
              width={24}
              classes={skeletonClasses}
              style={{ margin: 4 }}
            />
          ) : withMultiselections ? (
            <Select.Any id={hiveId} />
          ) : withSelections ? (
            <Select.One id={hiveId} />
          ) : null}
        </TableCell>
      ) : null}
      <TableCell component="div" padding="none">
        {isSelected("dataRoom") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.dataRoomWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <DataRoomBlock
              loading={loading}
              dataRoom={dataRoom}
              withMeta={withMeta}
              withAttributes={withMeta}
              nameWeight="primary"
            />
          </TableCell>
        )}
        {isSelected("owner") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <ContactBlock
              loading={loading}
              contact={contact}
              withMeta={withMeta}
              withAttributes={withMeta}
            />
          </TableCell>
        )}
        {isSelected("participants") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <ContactBlock
              contact={contact}
              withMeta={withMeta}
              withAttributes={withMeta}
            />
          </TableCell>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DataRoomTableBodyRow;
