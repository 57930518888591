// @ts-nocheck
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { SafeQuery } from "@decentriq/safequery/lib/safequery";
import { faShieldKeyhole as faShieldKeyholeDuotone } from "@fortawesome/pro-duotone-svg-icons";
import {
  faArchive,
  faArrowUpFromBracket,
  faClone,
  faCopy,
  faFileDownload,
  faICursor,
  faLink,
  faStar,
  faStarHalf,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { faFileContract as faFileContractRegular } from "@fortawesome/pro-regular-svg-icons";
import { faShieldKeyhole as faShieldKeyholeSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Portal } from "@material-ui/core";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "react-use";
import {
  Actions,
  DataRoomArchiveDialog,
  DataRoomDeleteDialog,
  DataRoomPublishDialog,
  PublishedAndProtectedDialog,
} from "components";
import { useApiCore } from "contexts/apicore/apicore";
import {
  CREATE_DATA_ROOM,
  DELETE_DATA_ROOM_BY_ID,
  UPDATE_DATA_ROOM,
  UPDATE_DATA_ROOM_SHARE,
} from "gqls";

const IS_ADD_TO_FAVORITES_ENABLED = false;
const USE_DATA_ROOM_ARCHIVE_DIALOG = false;

export enum DataRoomActionsType {
  OpenPublishedAndProtectedDialog,
  Publish,
  ToggleFavorite,
  CopyLink,
  Rename,
  Duplicate,
  Archive,
  Restore,
  Delete,
  RestoreOrDelete,
  ArchiveRestoreOrDelete,
  Export,
}

// TODO: Figure out this crap
const closeSnackbarAction = (close: any) => (key: any) =>
  (
    <IconButton onClick={() => close(key)}>
      <FontAwesomeIcon
        fixedWidth
        icon={faTimes}
        color="white"
      ></FontAwesomeIcon>
    </IconButton>
  );

const prettifyPassword = (password: any) => {
  if (!password) {
    return null;
  }
  const trimmedPassword = password.trim();
  if (trimmedPassword) {
    return trimmedPassword;
  }
  return null;
};

const DataRoomActions = memo(
  ({
    id,
    dataRoomId,
    dataRoom = {},
    actions: actionsFunc = (actions: any) => actions,
    inline = false,
    moreTooltipTitle,
    moreTooltipPlacement,
    moreIcon,
  }: any) => {
    const {
      name,
      description,
      isPublished,
      isFavorite,
      isArchived,
      dataRoomShareId,
      dataRoomShares,
      dataRoomTables,
      dataRoomQueries,
      ownerEmail,
      mrenclave,
      updatedAt,
      createdAt,
    } = dataRoom;
    const updatedAtDate = parseISO(updatedAt);
    const createdAtDate = parseISO(createdAt);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuth0();
    const { email: currentUserEmail } = user;
    const { getClient } = useApiCore();
    // Open "Published and protected" dialog
    const [
      isPublishedAndProtectedDialogOpen,
      {
        setTrue: openPublishedAndProtectedDialog,
        setFalse: closePublishedAndProtectedDialog,
      },
    ] = useBoolean(false);
    // Toggle favorite
    const [upsertDataRoomShareMutation] = useMutation(UPDATE_DATA_ROOM_SHARE, {
      refetchQueries: ["dataRooms", "dataRoomsTotalCount"],
    });
    const toggleFavorite = () => {
      upsertDataRoomShareMutation({
        variables: {
          input: {
            dataRoomShareId,
            patch: {
              isFavorite: !isFavorite,
            },
          },
        },
      })
        .then(() => {
          enqueueSnackbar(
            `Data room was ${
              !isFavorite ? "added to" : "removed from"
            } Favorites`
          );
        })
        .catch(() => {
          enqueueSnackbar(
            `Can't ${
              !isFavorite ? "add data room to" : "remove data room from"
            } Favorites`,
            {
              variant: "error",
            }
          );
        });
    };
    // Publish data room
    const [isDataRoomPublishLoading, setIsDataRoomPublishLoading] =
      useState(false);
    const [publishDataRoomMutation] = useMutation(UPDATE_DATA_ROOM, {
      refetchQueries: ["dataRooms", "dataRoomsTotalCount"],
    });
    const [
      isDataRoomPublishDialogOpen,
      {
        setTrue: openDataRoomPublishDialog,
        setFalse: closeDataRoomPublishDialog,
      },
    ] = useBoolean(false);
    const publishDataRoom = async () => {
      setIsDataRoomPublishLoading(true);
      const prettyPassword = prettifyPassword(undefined);
      try {
        const client = await getClient({ password: prettifyPassword });
        const safeQuery = await SafeQuery.create(
          {
            id: dataRoomId,
            title: name,
            description,
            tables: dataRoomTables?.nodes?.map(
              ({ name, sqlCreateStatement, dataRoomTableShares }) => ({
                name,
                sqlCreateStatement,
                dataProviders: dataRoomTableShares?.nodes?.map(
                  ({ userEmail }) => userEmail
                ),
              })
            ),
            queries: dataRoomQueries?.nodes?.map(
              ({ name, sqlSelectStatement, dataRoomQueryShares }) => ({
                name,
                sqlSelectStatement,
                dataAnalysts: dataRoomQueryShares?.nodes?.map(
                  ({ userEmail }) => userEmail
                ),
              })
            ),
          },
          client,
          prettyPassword
        );
        if (safeQuery.dataRoomHash !== undefined) {
          await publishDataRoomMutation({
            variables: {
              input: {
                patch: {
                  dataRoomHash: safeQuery.dataRoomHash.toString(),
                  hasPassword: !!prettyPassword,
                },
                dataRoomId,
              },
            },
          })
            .then(() => {
              setIsDataRoomPublishLoading(false);
              closeDataRoomPublishDialog();
              enqueueSnackbar("Data room was published");
            })
            .catch(() => {
              setIsDataRoomPublishLoading(false);
              enqueueSnackbar("Can't update data room", { variant: "error" });
            });
        }
        return safeQuery;
      } catch (error) {
        enqueueSnackbar(
          [
            error.message,
            error.tableIndex !== null && error.tableIndex !== undefined
              ? `Table index: ${error.tableIndex}`
              : null,
            error.columnIndex !== null && error.columnIndex !== undefined
              ? `Column index: ${error.columnIndex}`
              : null,
            error.queryIndex !== null && error.queryIndex !== undefined
              ? `Query index: ${error.queryIndex}`
              : null,
            error.roleIndex !== null && error.roleIndex !== undefined
              ? `Role index: ${error.roleIndex}`
              : null,
            error.permissionIndex !== null &&
            error.permissionIndex !== undefined
              ? `Permission index: ${error.permissionIndex}`
              : null,
            error.constraintIndex !== null &&
            error.constraintIndex !== undefined
              ? `Contsraint index: ${error.constraintIndex}`
              : null,
          ]
            .filter(Boolean)
            .join(", "),
          {
            variant: "error",
            persist: true,
            action: closeSnackbarAction(closeSnackbar),
          }
        );
      } finally {
        setIsDataRoomPublishLoading(false);
      }
    };
    // Restore data room
    const [restoreDataRoomMutation] = useMutation(UPDATE_DATA_ROOM_SHARE, {
      refetchQueries: ["dataRooms", "dataRoomsTotalCount"],
    });
    const restoreDataRoom = () => {
      restoreDataRoomMutation({
        variables: {
          input: {
            patch: {
              isArchived: false,
            },
            dataRoomShareId,
          },
        },
      })
        .then(() => {
          closeDataRoomArchiveDialog();
          enqueueSnackbar("Data room was restored");
        })
        .catch(() => {
          closeDataRoomArchiveDialog();
          enqueueSnackbar("Can't restore data room", { variant: "error" });
        });
    };
    // Archive data room
    const [
      isDataRoomArchiveDialogOpen,
      {
        setTrue: openDataRoomArchiveDialog,
        setFalse: closeDataRoomArchiveDialog,
      },
    ] = useBoolean(false);
    const [archiveDataRoomMutation, { loading: isDataRoomArchiveLoading }] =
      useMutation(UPDATE_DATA_ROOM_SHARE, {
        refetchQueries: ["dataRooms", "dataRoomsTotalCount"],
      });
    const archiveDataRoom = () => {
      archiveDataRoomMutation({
        variables: {
          input: {
            patch: {
              isArchived: true,
            },
            dataRoomShareId,
          },
        },
      })
        .then(() => {
          closeDataRoomArchiveDialog();
          enqueueSnackbar("Data room was archived");
        })
        .catch(() => {
          closeDataRoomArchiveDialog();
          enqueueSnackbar("Can't archive data room", { variant: "error" });
        });
    };
    // Delete data room
    const [
      isDataRoomDeleteDialogOpen,
      {
        setTrue: openDataRoomDeleteDialog,
        setFalse: closeDataRoomDeleteDialog,
      },
    ] = useBoolean(false);
    const [deleteDataRoomByIdMutation, { loading: isDataRoomDeleteLoading }] =
      useMutation(DELETE_DATA_ROOM_BY_ID, {
        refetchQueries: ["dataRooms", "dataRoomsTotalCount"],
      });
    const deleteDataRoom = () => {
      deleteDataRoomByIdMutation({
        variables: {
          input: {
            id,
          },
        },
      })
        .then(() => {
          closeDataRoomDeleteDialog();
          enqueueSnackbar("Data room was deleted");
        })
        .catch(() => {
          closeDataRoomDeleteDialog();
          enqueueSnackbar("Can't delete data room", { variant: "error" });
        });
    };
    // Export data room
    const exportDataRoom = () => {
      const { name, description, ownerEmail } = dataRoom;
      const content = {
        name,
        description,
        ownerEmail,
        dataRoomShares:
          dataRoomShares?.nodes?.map(({ userEmail }) => ({
            userEmail,
          })) || [],
        dataRoomTables:
          dataRoomTables?.nodes?.map(
            ({ name, sqlCreateStatement, dataRoomTableShares }) => ({
              name,
              sqlCreateStatement,
              dataRoomTableShares:
                dataRoomTableShares?.nodes?.map(({ userEmail }) => ({
                  userEmail,
                })) || [],
            })
          ) || [],
        dataRoomQueries:
          dataRoomQueries?.nodes?.map(
            ({ name, sqlSelectStatement, dataRoomQueryShares }) => ({
              name,
              sqlSelectStatement,
              dataRoomQueryShares:
                dataRoomQueryShares?.nodes?.map(({ userEmail }) => ({
                  userEmail,
                })) || [],
            })
          ) || [],
      };
      const file = new File(
        [JSON.stringify(content, null, 2)],
        `${name}.json`,
        {
          type: "application/octet-stream;charset=utf-8",
        }
      );
      saveAs(file);
    };
    // Copy link
    const [{ value: copiedToClipboard }, copyToClipboard] =
      useCopyToClipboard();
    const copyLinkToDataRoom = () =>
      copyToClipboard(`${window.location.origin}/datarooms/${dataRoomId}`);
    useEffect(() => {
      if (copiedToClipboard)
        enqueueSnackbar(
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon
              fixedWidth
              icon={faCopy}
              style={{ fontSize: "1rem", marginRight: "0.5rem" }}
            />
            Copied to clipboard
          </Box>
        );
    }, [enqueueSnackbar, copiedToClipboard]);
    // Duplicate data room
    const [duplicateDataRoomMutation] = useMutation(CREATE_DATA_ROOM, {
      refetchQueries: ["dataRooms", "dataRoomsTotalCount"],
    });
    const duplicateDataRoom = () =>
      duplicateDataRoomMutation({
        variables: {
          input: {
            dataRoom: {
              name: `Copy of ${name}`,
              description,
              mrenclave: "",
              dataRoomShares: {
                create: [
                  ...new Set([
                    currentUserEmail,
                    ...dataRoomTables?.nodes
                      ?.map(({ dataRoomTableShares }) =>
                        dataRoomTableShares?.nodes?.map(
                          ({ userEmail }) => userEmail
                        )
                      )
                      ?.flat(),
                    ...dataRoomQueries?.nodes
                      ?.map(({ dataRoomQueryShares }) =>
                        dataRoomQueryShares?.nodes?.map(
                          ({ userEmail }) => userEmail
                        )
                      )
                      ?.flat(),
                  ]),
                ].map((userEmail) => ({ userEmail })),
              },
              dataRoomTables: {
                create: dataRoomTables?.nodes?.map(
                  ({
                    name,
                    index,
                    sqlCreateStatement,
                    dataRoomTableShares,
                  }) => ({
                    name,
                    index,
                    sqlCreateStatement,
                    dataRoomTableShares: {
                      create: dataRoomTableShares?.nodes?.map(
                        ({ userEmail }) => ({ userEmail })
                      ),
                    },
                  })
                ),
              },
              dataRoomQueries: {
                create: dataRoomQueries?.nodes?.map(
                  ({
                    name,
                    index,
                    sqlSelectStatement,
                    dataRoomQueryShares,
                  }) => ({
                    name,
                    index,
                    sqlSelectStatement,
                    dataRoomQueryShares: {
                      create: dataRoomQueryShares?.nodes?.map(
                        ({ userEmail }) => ({ userEmail })
                      ),
                    },
                  })
                ),
              },
            },
          },
        },
      })
        .then(({ data }) => {
          const { createDataRoom } = data;
          const { dataRoom } = createDataRoom;
          const { dataRoomId } = dataRoom;
          navigate(`/datarooms/${dataRoomId}`);
        })
        .catch((error) => {
          enqueueSnackbar("Can't create new data room", { variant: "error" });
        });
    // Actions
    const actions = actionsFunc({
      buttons: [
        {
          component: IconButton,
          isEnabled: isPublished,
          states: {
            normal: {
              handle: openPublishedAndProtectedDialog,
              icon: (
                <FontAwesomeIcon
                  fixedWidth
                  icon={faShieldKeyholeSolid}
                  // style={{ fontSize: "1.25rem" }}
                />
              ),
              square: true,
              tooltipTitle: (
                <div>
                  <div>Published and protected</div>
                  <div style={{ fontWeight: 400 }}>
                    This data room is now protected by{" "}
                    <strong>Decentriq</strong> using end-to-end encryption with
                    Intel® SGX
                  </div>
                </div>
              ),
              tooltipPlacement: "bottom-end",
            },
            onHover: {
              icon: (
                <FontAwesomeIcon
                  fixedWidth
                  icon={faShieldKeyholeDuotone}
                  // style={{ fontSize: "1.25rem" }}
                />
              ),
            },
          },
          type: DataRoomActionsType.OpenPublishedAndProtectedDialog,
        },
        {
          component: Button,
          isEnabled: !dataRoom?.isPublished,
          states: {
            normal: {
              handle: openDataRoomPublishDialog,
              icon: (
                <FontAwesomeIcon
                  icon={faFileContractRegular}
                  style={{ fontSize: "1.25rem" }}
                />
              ),
              name: "Encrypt and publish",
              tooltipTitle: (
                <div>
                  <div>Encrypt and publish</div>
                  <div style={{ fontWeight: 400 }}>
                    Publishing the data room means that the data room is going
                    to be encrypted and locked. You won't be able to edit
                    tables, queries, or participants after the data room is
                    published.
                  </div>
                </div>
              ),
              tooltipPlacement: "bottom-end",
            },
          },
          type: DataRoomActionsType.Publish,
        },
        {
          component: IconButton,
          isEnabled: isFavorite,
          states: {
            normal: {
              handle: toggleFavorite,
              icon: faStar,
              square: true,
              tooltipPlacement: "bottom-end",
              tooltipTitle: "Favorited",
            },
            onHover: {
              handle: toggleFavorite,
              icon: faStarHalf,
              tooltipTitle: "Remove from Favorites",
            },
          },
          type: DataRoomActionsType.ToggleFavorite,
        },
        {
          component: IconButton,
          isEnabled: isArchived,
          states: {
            normal: {
              handle: restoreDataRoom,
              icon: faArchive,
              isRed: true,
              square: true,
              tooltipPlacement: "bottom-end",
              tooltipTitle: "Archived",
            },
            onHover: {
              handle: restoreDataRoom,
              icon: faArrowUpFromBracket,
              isRed: false,
              tooltipTitle: "Restore",
            },
            onShift: {
              handle: openDataRoomDeleteDialog,
              icon: faTrashAlt,
              isRed: true,
              tooltipTitle: "Delete...",
            },
          },
          type: DataRoomActionsType.RestoreOrDelete,
        },
      ],
      menuLists: [
        [
          {
            isEnabled: IS_ADD_TO_FAVORITES_ENABLED,
            states: {
              normal: {
                handle: toggleFavorite,
                icon: isFavorite ? faStarHalf : faStar,
                name: isFavorite ? "Remove from Favorites" : "Add to Favorites",
              },
            },
            type: DataRoomActionsType.ToggleFavorite,
          },
          {
            isEnabled: true,
            states: {
              normal: {
                handle: copyLinkToDataRoom,
                icon: faLink,
                name: "Copy link",
              },
            },
            type: DataRoomActionsType.CopyLink,
          },
          {
            isEnabled: false,
            states: {
              normal: {
                handle: () => console.log("Not implemented yet"),
                icon: faICursor,
                name: "Rename...",
              },
            },
            type: DataRoomActionsType.Rename,
          },
          {
            isEnabled: true,
            states: {
              normal: {
                handle: duplicateDataRoom,
                icon: faClone,
                name: "Duplicate",
              },
            },
            type: DataRoomActionsType.Duplicate,
          },
          {
            isEnabled: true,
            states: {
              normal: {
                handle: isArchived
                  ? restoreDataRoom
                  : USE_DATA_ROOM_ARCHIVE_DIALOG
                  ? openDataRoomArchiveDialog
                  : archiveDataRoom,
                icon: isArchived ? faArrowUpFromBracket : faArchive,
                isRed: !isArchived,
                name: isArchived
                  ? "Restore"
                  : `Archive${USE_DATA_ROOM_ARCHIVE_DIALOG ? "..." : ""}`,
              },
              onShift: {
                handle: openDataRoomDeleteDialog,
                icon: faTrashAlt,
                isRed: true,
                name: "Delete...",
              },
            },
            type: DataRoomActionsType.ArchiveRestoreOrDelete,
          },
        ],
        [
          {
            isEnabled: true,
            states: {
              normal: {
                handle: exportDataRoom,
                icon: faFileDownload,
                name: "Export to JSON",
              },
            },
            type: DataRoomActionsType.Export,
          },
        ],
        [
          {
            isEnabled: true,
            states: {
              normal: {
                name: (
                  <div
                    style={{
                      fontSize: "0.8125rem",
                      lineHeight: "1.1875rem",
                      opacity: 0.75,
                      whiteSpace: "pre",
                      maxHeight: "7.5rem",
                      overflow: "auto",
                    }}
                  >
                    {isValid(createdAtDate) ? (
                      <div>
                        <div>
                          <strong>Created by</strong>
                        </div>
                        <div style={{ fontWeight: 500 }}>{ownerEmail}</div>
                        <div>on {format(createdAtDate, "PPPP'\nat 'pppp")}</div>
                      </div>
                    ) : null}
                    {isValid(updatedAtDate) ? (
                      <div>
                        <div style={{ marginTop: 4 }}>
                          <strong>Last edited by</strong>
                        </div>
                        <div style={{ fontWeight: 500 }}>{ownerEmail}</div>
                        <div>on {format(updatedAtDate, "PPPP'\nat 'pppp")}</div>
                      </div>
                    ) : null}
                    <div style={{ marginTop: 4 }}>
                      <strong>Enclave version</strong>
                    </div>
                    <div>{mrenclave || "latest"}</div>
                  </div>
                ),
              },
            },
            type: DataRoomActionsType.Details,
          },
        ],
      ],
    });
    const actionsTypes = [actions.buttons || [], actions.menuLists || []]
      .flat(Infinity)
      .map(({ type }) => type);
    return (
      <Actions
        actions={actions}
        inline={inline}
        moreTooltipTitle={moreTooltipTitle}
        moreTooltipPlacement={moreTooltipPlacement}
        moreIcon={moreIcon}
      >
        <Portal>
          {actionsTypes.includes(
            DataRoomActionsType.OpenPublishedAndProtectedDialog
          ) && (
            <PublishedAndProtectedDialog
              open={isPublishedAndProtectedDialogOpen}
              onClose={closePublishedAndProtectedDialog}
            />
          )}
          {actionsTypes.includes(DataRoomActionsType.Publish) && (
            <DataRoomPublishDialog
              name={name}
              open={isDataRoomPublishDialogOpen}
              loading={isDataRoomPublishLoading}
              onCancel={closeDataRoomPublishDialog}
              onConfirm={publishDataRoom}
            />
          )}
          {actionsTypes.includes(
            DataRoomActionsType.ArchiveRestoreOrDelete
          ) && (
            <DataRoomArchiveDialog
              name={name}
              open={isDataRoomArchiveDialogOpen}
              loading={isDataRoomArchiveLoading}
              onCancel={closeDataRoomArchiveDialog}
              onConfirm={archiveDataRoom}
            />
          )}
          {actionsTypes.includes(
            DataRoomActionsType.ArchiveRestoreOrDelete
          ) && (
            <DataRoomDeleteDialog
              name={name}
              open={isDataRoomDeleteDialogOpen}
              loading={isDataRoomDeleteLoading}
              onCancel={closeDataRoomDeleteDialog}
              onConfirm={deleteDataRoom}
            />
          )}
        </Portal>
      </Actions>
    );
  }
);

export default DataRoomActions;
