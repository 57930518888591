import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useMetaWrapperStyles = makeStyles((theme: Theme) => ({
  metaWrapper: {
    fontSize: "0.75rem",
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap",
    lineHeight: 1.5,
    width: "100%",
    height: "auto",
    overflow: "hidden",
    maskImage: "linear-gradient(to right, black 90%, transparent 100%)",
  },
}));
