// @ts-nocheck
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import { memo, useEffect } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as VirtualizedTable } from "react-window";
import {
  DataFilter,
  DataSearch,
  OrderByMenu,
  PaginationWrapper,
  ViewSettingsMenu,
} from "components";
import { useData, useDataView } from "contexts";
import { useCustomTableStyles } from "styles/table";
import { useDataStyles } from "./Data.styles";

const DataControls = memo(
  ({
    BulkActions,
    Actions,
    // withFilter,
    withSearch,
    orderByOptions,
    onSelect,
  }) => {
    const dataContainerClasses = useDataStyles({
      stickyControls: true,
    });
    const { condition, setCondition, search, setSearch, orderBy, setOrderBy } =
      useData();
    const { selections } = useDataView();
    const { selected } = selections;
    useEffect(() => onSelect(selected), [selected, onSelect]);
    const onBulkActionSucceeded = () => console.log("Clear selection");
    const onBulkActionFailed = () => console.log("Show error");
    const withSettings = false;
    const withFilter = false;
    const shouldShowDataControls =
      Actions || BulkActions || withSearch || withFilter || withSettings;
    return shouldShowDataControls ? (
      <div className={dataContainerClasses.dataControls}>
        {BulkActions && selected.length > 0 ? (
          <Box
            sx={{
              mr: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              className={dataContainerClasses.dataActionsAngle}
              sx={{
                mr: 1,
              }}
            >
              With {selected.length} selected
            </Box>
            <BulkActions
              ids={selected}
              onBulkActionSucceeded={onBulkActionSucceeded}
              onBulkActionFailed={onBulkActionFailed}
            />
          </Box>
        ) : Actions ||
          withFilter ||
          withSearch ||
          withSettings ||
          orderByOptions.length > 0 ? (
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {Actions && (
              <Box>
                <Actions />
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flex: "0",
                flexBasis: Actions ? "auto" : "100%",
                justifyContent: "space-between",
              }}
            >
              {withFilter && (
                <DataFilter condition={condition} onChange={setCondition} />
              )}
              {withSearch && (
                <DataSearch
                  value={search}
                  onChange={(search) => setSearch(search)}
                />
              )}
              {orderByOptions.length > 0 && (
                <div className={dataContainerClasses.dataSort}>
                  <OrderByMenu
                    orderBy={orderBy}
                    orderByOptions={orderByOptions}
                    onChange={(value) => setOrderBy(value)}
                  />
                </div>
              )}
              <ViewSettingsMenu />
            </Box>
          </Box>
        ) : null}
      </div>
    ) : null;
  }
);

const DataTableHead = memo(
  ({ TableHeadRow, withSelections, withMultiselections, forceWideCells }) => {
    return (
      <TableHead component="div">
        <TableHeadRow
          withSelections={withSelections}
          withMultiselections={withMultiselections}
          forceWideCells={forceWideCells}
        />
      </TableHead>
    );
  }
);

const DataTableBody = memo(
  ({
    TableBodyRow,
    withSelections,
    withMultiselections,
    forceWideCells,
    infiniteScroll,
    dataKeyName,
    nodeIdKeyName,
  }) => {
    const { loading, data, pageSize } = useData();
    const nodes = (data && data[dataKeyName]?.nodes) || [];
    const nodesCount = nodes.length;
    return (
      <TableBody component="div">
        {infiniteScroll ? (
          <AutoSizer>
            {({ height, width }) => {
              return (
                <VirtualizedTable
                  width={width}
                  height={height}
                  itemCount={nodesCount}
                  itemSize={61}
                >
                  {({ index, style }) => {
                    const node = nodes[index];
                    return (
                      <TableBodyRow
                        key={node[nodeIdKeyName] || index}
                        node={node}
                        withSelections={withSelections}
                        withMultiselections={withMultiselections}
                        forceWideCells={forceWideCells}
                        style={style}
                      />
                    );
                  }}
                </VirtualizedTable>
              );
            }}
          </AutoSizer>
        ) : (
          (loading
            ? [
                ...Array(
                  nodesCount > 0 ? Math.min(pageSize, nodesCount) : 1
                ).keys(),
              ]
            : nodes
          ).map((node, index) => {
            return (
              <TableBodyRow
                key={index}
                node={node}
                withSelections={withSelections}
                withMultiselections={withMultiselections}
                forceWideCells={forceWideCells}
              />
            );
          })
        )}
      </TableBody>
    );
  }
);

const DataTable = memo(
  ({
    TableHeadRow = () => {},
    TableBodyRow = () => {},
    withSelections,
    withMultiselections,
    forceWideCells = false,
    infiniteScroll = false,
    dataKeyName,
    nodeIdKeyName,
    // Bleh
    BulkActions,
    Actions,
    withFilter,
    withSearch,
    orderByOptions,
    onSelect,
  }) => {
    const dataContainerClasses = useDataStyles({
      stickyControls: true,
    });
    const customTableClasses = useCustomTableStyles();
    return (
      <div className={dataContainerClasses.dataContainer}>
        <DataControls
          BulkActions={BulkActions}
          Actions={Actions}
          withFilter={withFilter}
          withSearch={withSearch}
          orderByOptions={orderByOptions}
          onSelect={onSelect}
        />
        <div className={dataContainerClasses.data}>
          <div className={dataContainerClasses.dataViewport}>
            <TableContainer
              component="div"
              className={customTableClasses.MuiTableContainer}
            >
              <Table component="div">
                <DataTableHead
                  TableHeadRow={TableHeadRow}
                  withSelections={withSelections}
                  withMultiselections={withMultiselections}
                  forceWideCells={forceWideCells}
                />
                <DataTableBody
                  TableBodyRow={TableBodyRow}
                  withSelections={withSelections}
                  withMultiselections={withMultiselections}
                  forceWideCells={forceWideCells}
                  infiniteScroll={infiniteScroll}
                  dataKeyName={dataKeyName}
                  nodeIdKeyName={nodeIdKeyName}
                />
              </Table>
            </TableContainer>
            {!infiniteScroll && <PaginationWrapper />}
          </div>
        </div>
      </div>
    );
  }
);

export default DataTable;
