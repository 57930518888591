import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useCustomDrawerStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  dialogTitle: {
    margin: theme.spacing(1, 1, 1, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dataContainer: {
    margin: theme.spacing(0, 3),
    flex: 1,
    overflow: "auto",
    display: "flex",
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
}));
