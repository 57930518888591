// @ts-nocheck
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faFileUpload,
  faPlus,
  faRulerTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, MenuItem, Portal, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useKeyPressEvent } from "react-use";
import { DataRoomCreateDialog, MainbarMenuItem } from "components";
import { CREATE_DATA_ROOM } from "gqls";

const CONFIGURATION_SOURCE_NONE = "none";
const CONFIGURATION_SOURCE_IMPORT = "import";
const CONFIGURATION_SOURCE_TEMPLATE = "template";
const IS_FEATURE_NEW_DATA_ROOM_IMPORT_ENABLED = false;
const IS_FEATURE_NEW_DATA_ROOM_USE_TEMPLATE_ENABLED = false;

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    borderColor: "transparent",
    padding: theme.spacing(0, 0.5),
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    margin: theme.spacing(-0.5, 0),
    minWidth: "auto",
  },
}));

const NewDataRoomMainbarMenuItem = memo(() => {
  const buttonClasses = useButtonStyles();
  const { user = {} } = useAuth0();
  const navigate = useNavigate();
  const { email: currentUserEmail } = user || {};
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { enqueueSnackbar } = useSnackbar();
  const [
    isDataRoomCreateDialogOpen,
    { setTrue: openDataRoomCreateDialog, setFalse: closeDataRoomCreateDialog },
  ] = useBoolean(false);
  const [initialConfigurationSource, setInitialConfigurationSource] = useState(
    CONFIGURATION_SOURCE_NONE
  );
  const [createDataRoomMutation, { loading: isCreateDataRoomLoading }] =
    useMutation(CREATE_DATA_ROOM, {
      onCompleted: ({ createDataRoom }) => {
        const dataRoomId = createDataRoom?.dataRoom?.dataRoomId;
        closeDataRoomCreateDialog();
        enqueueSnackbar("New data room created");
        if (dataRoomId) {
          navigate(`/datarooms/${dataRoomId}`);
        }
      },
      onError: (error) => {
        closeDataRoomCreateDialog();
        enqueueSnackbar("Can't create new data room", { variant: "error" });
      },
      refetchQueries: ["dataRooms"],
    });
  const createDataRoom = ({
    name = "",
    description = "",
    configuration = {},
  }) => {
    const {
      dataRoomShares = [],
      dataRoomTables = [],
      dataRoomQueries = [],
    } = configuration;
    const dataRoom = {
      name: name.trim(),
      description: description.trim(),
      mrenclave: "",
      dataRoomShares: {
        create: [{ userEmail: currentUserEmail }].concat(
          dataRoomShares
            ?.filter(({ userEmail }) => userEmail.trim() !== currentUserEmail)
            .map(({ userEmail }) => ({ userEmail: userEmail.trim() })) || []
        ),
      },
      dataRoomTables: {
        create: dataRoomTables?.map(
          ({
            name = "",
            sqlCreateStatement = "",
            dataRoomTableShares = [],
            index = 0,
          }) => ({
            name: name.trim() || "",
            index: index || 0,
            sqlCreateStatement: sqlCreateStatement.trim() || "",
            dataRoomTableShares: {
              create: dataRoomTableShares?.map(({ userEmail }) => ({
                userEmail: userEmail.trim(),
              })),
            },
          })
        ),
      },
      dataRoomQueries: {
        create: dataRoomQueries?.map(
          ({
            name = "",
            sqlSelectStatement = "",
            dataRoomQueryShares = [],
            index = 0,
          }) => ({
            name: name.trim() || "",
            index: index || 0,
            sqlSelectStatement: sqlSelectStatement.trim() || "",
            dataRoomQueryShares: {
              create: dataRoomQueryShares?.map(({ userEmail }) => ({
                userEmail: userEmail.trim(),
              })),
            },
          })
        ),
      },
    };
    // TODO: validate dataRoom
    createDataRoomMutation({
      variables: {
        input: {
          dataRoom,
        },
      },
    });
  };
  const createDataRoomPredefined = createDataRoom.bind(this, {
    name: "Untitled",
  });
  const onNewDataRoomClick = (event) => {
    event.stopPropagation();
    setInitialConfigurationSource(CONFIGURATION_SOURCE_NONE);
    openDataRoomCreateDialog();
  };
  const onImportClick = (event) => {
    event.stopPropagation();
    setInitialConfigurationSource(CONFIGURATION_SOURCE_IMPORT);
    openDataRoomCreateDialog();
  };
  const onUseTemplateClick = (event) => {
    event.stopPropagation();
    setInitialConfigurationSource(CONFIGURATION_SOURCE_TEMPLATE);
    openDataRoomCreateDialog();
  };
  const stopPropagation = useCallback((event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }, []);
  return (
    <Fragment>
      <MainbarMenuItem
        wrapper={MenuItem}
        icon={faPlus}
        text={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flex: "1 1 auto",
              fontSize: "0.875rem",
            }}
          >
            <div>New data room{isShiftDown ? null : "..."}</div>
            <div
              style={{
                display: "flex",
                marginRight: "-4px",
              }}
            >
              {IS_FEATURE_NEW_DATA_ROOM_IMPORT_ENABLED ? (
                <Button
                  classes={buttonClasses}
                  color="inherit"
                  variant="outlined"
                  style={{ fontWeight: 400 }}
                  onClick={onImportClick}
                  onMouseDown={stopPropagation}
                  onMouseUp={stopPropagation}
                >
                  <FontAwesomeIcon
                    icon={faFileUpload}
                    style={{ marginRight: "4px" }}
                  />
                  Import
                </Button>
              ) : null}
              {IS_FEATURE_NEW_DATA_ROOM_USE_TEMPLATE_ENABLED ? (
                <Button
                  classes={buttonClasses}
                  color="inherit"
                  variant="outlined"
                  style={{ fontWeight: 400 }}
                  onClick={onUseTemplateClick}
                  onMouseDown={stopPropagation}
                  onMouseUp={stopPropagation}
                >
                  <FontAwesomeIcon
                    icon={faRulerTriangle}
                    style={{ marginRight: "4px" }}
                  />
                  Use template
                </Button>
              ) : null}
            </div>
          </div>
        }
        onClick={isShiftDown ? createDataRoomPredefined : onNewDataRoomClick}
      />
      <Portal>
        <DataRoomCreateDialog
          initialConfigurationSource={initialConfigurationSource}
          open={isDataRoomCreateDialogOpen}
          loading={isCreateDataRoomLoading}
          onCancel={closeDataRoomCreateDialog}
          onConfirm={createDataRoom}
        />
      </Portal>
    </Fragment>
  );
});

export default NewDataRoomMainbarMenuItem;
