import { Avatar, Tooltip, Typography } from "@material-ui/core";
import { green, grey } from "@material-ui/core/colors";

interface DataRoomTableParticipantAvatarProps {
  name: string;
  active?: boolean;
  size?: string;
  tooltip?: string;
  fontSize?: string;
}

const DataRoomTableParticipantAvatar: React.FC<DataRoomTableParticipantAvatarProps> =
  ({ name, tooltip, active = false, size = "32px", fontSize = "14px" }) => (
    <Avatar
      style={{
        backgroundColor: "white",
        height: size,
        color: grey["400"],
        width: size,
        margin: "0 2px",
        border: `1px solid ${active ? green["200"] : grey["300"]}`,
        position: "relative",
      }}
    >
      <Tooltip title={tooltip || name} placement="top">
        <Typography fontSize={fontSize} variant="body2">
          {name.substr(0, 2).toUpperCase()}
          {active && (
            <Typography
              variant="caption"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                color: green["700"],
                lineHeight: 1,
                width: "100%",
                textAlign: "center",
              }}
            >
              ✓
            </Typography>
          )}
        </Typography>
      </Tooltip>
    </Avatar>
  );

export default DataRoomTableParticipantAvatar;
