import { GettingStarted } from "containers";
import { Main } from "layouts";

const DataRoomsPage = () => {
  return (
    <Main>
      <GettingStarted />
    </Main>
  );
};

export default DataRoomsPage;
