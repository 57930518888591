import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_SHARE_EVERYTHING } from "gqls";

export const CREATE_DATA_ROOM_TABLE_SHARE = gql`
  mutation createDataRoomTableShare($input: CreateDataRoomTableShareInput!) {
    createDataRoomTableShare(input: $input) {
      clientMutationId
      dataRoomTableShare {
        ...DataRoomTableShareEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_SHARE_EVERYTHING}
`;
