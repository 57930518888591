import { Box, Grid } from "@material-ui/core";
import { ReactComponent as DqLogo } from "assets/logos/decentriq-platform-symbol-square.svg";
import GettingStartedActions from "./GettingStartedActions";

const GettingStarted = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      style={{ height: "calc(100vh - 50px)" }}
    >
      <DqLogo
        strokeWidth="1px"
        style={{ marginBottom: "16px", height: "14rem" }}
      />
      <Box style={{ minHeight: "24px" }}>
        <GettingStartedActions />
      </Box>
    </Grid>
  );
};

export default GettingStarted;
