export const DEFAULT_REFERER = "/";
export const FEATURE_DARK_THEME = "dark_theme";

export const dataRoomParticipation = {
  ALL: "ALL",
  AUTHENTICATED: "AUTHENTICATED",
};

export const dataRoomComputing = {
  SUBMISSION_END_REACHED: "SUBMISSION_END_REACHED",
  DATA_ROOM_MINIMUM_RESPONSES_REACHED: "DATA_ROOM_MINIMUM_RESPONSES_REACHED",
};

export const dataRoomResultVisibility = {
  ALL: "ALL",
  OWNER: "OWNER",
  OWNER_AND_PARTICIPANTS: "OWNER_AND_PARTICIPANTS",
  PARTICIPANTS: "PARTICIPANTS",
};

export const dataRoomMode = {
  UPDATE: "edit",
  PREVIEW: "preview",
  SUBMIT: "submit",
  RESULTS: "results",
};

export const actionType = {
  ADD_NEW: "ADD_NEW",
  EXPORT_TO_JSON: "EXPORT_TO_JSON",
  IMPORT_FROM_JSON: "IMPORT_FROM_JSON",
  DELETE_ALL: "DELETE_ALL",
  TOGGLE_ALL: "TOGGLE_ALL",
};

export const queryKind = {
  NUMERICAL: "NumericQuestion",
  CATEGORICAL: "CategoricalQuestion",
};
