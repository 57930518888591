import { useCallback, useState } from "react";

type EntityId = string | number;

export interface NormalizedStateHookOptions<E> {
  initialValues: E[];
  id: (entity: E) => EntityId;
}

type NormalizedStateAction<E> = (entity: E) => void;
type NormalizedStatePartialAction<E> = (entity: Partial<E>) => void;
type NormalizedStateRemoveAction = (id: EntityId) => void;

interface NormalizedStateHookResult<E> {
  state: NormalizedState<E>;
  entities: E[];
  byId: NormalizedState<E>["byId"];
  add: NormalizedStateAction<E>;
  update: NormalizedStatePartialAction<E>;
  addOrUpdate: NormalizedStatePartialAction<E>;
  remove: NormalizedStateRemoveAction;
}

interface NormalizedState<E> {
  byId: {
    [key in EntityId]: E;
  };
  all: EntityId[];
}

export const useNormalizedState = <E>(
  options: NormalizedStateHookOptions<E>
): NormalizedStateHookResult<E> => {
  const [state, setState] = useState<NormalizedState<E>>(
    options.initialValues.reduce(
      (acc, v) => {
        const id = options.id(v);
        const nextAcc: NormalizedState<E> = {
          byId: {
            ...acc.byId,
            [id]: v,
          },
          all: [...acc.all, id],
        };
        return nextAcc;
      },
      { byId: {}, all: [] } as NormalizedState<E>
    )
  );
  const add: NormalizedStateAction<E> = useCallback(
    (entity: E) =>
      setState((state) => ({
        byId: {
          ...state.byId,
          [options.id(entity)]: entity,
        },
        all: [...state.all, options.id(entity)],
      })),
    [setState, options]
  );
  const update: NormalizedStatePartialAction<E> = useCallback(
    (entity: Partial<E>) => {
      const id = options.id(entity as E);
      if (!id) throw Error("Entity id must be provided");
      setState((state) => ({
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            ...entity,
          },
        },
      }));
    },
    [setState, options]
  );
  const remove: NormalizedStateRemoveAction = useCallback(
    (id: EntityId) =>
      setState((state) => {
        const byId: NormalizedState<E>["byId"] = {};
        const all = state.all.filter((i) => {
          const shouldStay = i !== id;
          if (shouldStay) {
            byId[i] = state.byId[i];
          }
          return shouldStay;
        });
        return {
          byId,
          all,
        };
      }),
    [setState]
  );
  const addOrUpdate: NormalizedStatePartialAction<E> = useCallback(
    (entity: Partial<E>) => {
      const id = options.id(entity as E);
      if (!id) throw Error("Entity id must be provided");
      if (state.byId[id]) {
        update(entity);
        return;
      }
      add(entity as E);
    },
    [options, add, update, state.byId]
  );
  return {
    state,
    entities: state.all.map((id) => state.byId[id]),
    byId: state.byId,
    add,
    update,
    addOrUpdate,
    remove,
  };
};
