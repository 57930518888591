// @ts-nocheck
import { DataRooms } from "components";
import { DATA_ROOMS } from "gqls";
import { DataWrapper } from "wrappers";

const DataRoomsContainer = ({
  query = DATA_ROOMS,
  variables = {
    orderBy: ["NAME_ASC"],
  },
  filterBy = ["name"],
  orderByOptions = [],
  pageSize = 10,
  nodeIdKeyName = "dataRoomId",
  dataKeyName = "dataRooms",
  onSelect = () => {},
  DataView = DataRooms.Table,
  ...rest
}) => {
  return (
    <DataWrapper
      query={query}
      variables={variables}
      filterBy={filterBy}
      orderByOptions={orderByOptions}
      pageSize={pageSize}
      dataKeyName={dataKeyName}
      nodeIdKeyName={nodeIdKeyName}
      onSelect={onSelect}
    >
      <DataView
        dataKeyName={dataKeyName}
        nodeIdKeyName={nodeIdKeyName}
        orderByOptions={orderByOptions}
        onSelect={onSelect}
        {...rest}
      />
    </DataWrapper>
  );
};

const DataRoomsContainerList = (props) => (
  <DataRoomsContainer {...props} DataView={DataRooms.List} />
);

DataRoomsContainer.List = DataRoomsContainerList;

export default DataRoomsContainer;
