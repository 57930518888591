import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_EVERYTHING } from "gqls";

export const DATA_ROOM_QUERIES = gql`
  query dataRoomQueries(
    $filter: DataRoomQueryFilter
    $first: Int
    $offset: Int
    $orderBy: [DataRoomQueriesOrderBy!]
  ) {
    dataRoomQueries(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      totalCount
      nodes {
        ...DataRoomQueryEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_EVERYTHING}
`;
