import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_EVERYTHING } from "gqls";

export const UPDATE_DATA_ROOM_QUERY_BY_ID = gql`
  mutation updateDataRoomQueryById($input: UpdateDataRoomQueryByIdInput!) {
    updateDataRoomQueryById(input: $input) {
      clientMutationId
      dataRoomQuery {
        ...DataRoomQueryEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_EVERYTHING}
`;
