import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_SHARE_EVERYTHING } from "gqls";

export const DELETE_DATA_ROOM_TABLE_SHARE = gql`
  mutation deleteDataRoomTableShare($input: DeleteDataRoomTableShareInput!) {
    deleteDataRoomTableShare(input: $input) {
      clientMutationId
      dataRoomTableShare {
        ...DataRoomTableShareEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_SHARE_EVERYTHING}
`;
