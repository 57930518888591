import { faCheck, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Field, Form, Formik } from "formik";
import { DataRoomTableColumnDefinition } from "models";
import { useCallback, useRef } from "react";
import { ValidationError } from "yup";
import { dataRoomTableColumnInitialValues } from "./DataRoomTableConstructorInitialValues";
import { dataRoomTablePrimitiveTypeOptions } from "./DataRoomTableConstructorModels";
import { dataRoomTableColumnValidationSchema } from "./DataRoomTableConstrustorValidationScheme";

interface DataRoomTableColumnFormProps {
  onSubmit: (column: DataRoomTableColumnDefinition) => void;
  columnNames: string[];
  onCancel?: () => void;
  isEditing?: boolean;
  disabled?: boolean;
  initialValues?: DataRoomTableColumnDefinition;
}

const DataRoomTableColumnForm: React.FC<DataRoomTableColumnFormProps> = ({
  initialValues = dataRoomTableColumnInitialValues,
  isEditing = false,
  onSubmit,
  columnNames,
  onCancel,
  disabled,
}) => {
  const nameFieldRef = useRef<HTMLInputElement>(null);
  const validate = useCallback(
    async (values: DataRoomTableColumnDefinition) => {
      try {
        await dataRoomTableColumnValidationSchema.validate(values, {
          context: { columnNames },
        });
        return {};
      } catch (error) {
        return error;
      }
    },
    [columnNames]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (column, helpers) => {
        onSubmit(column);
        nameFieldRef.current?.focus();
        helpers.resetForm();
      }}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => {
        return (
          <Form style={{ width: "100%" }}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={5}>
                <Field<
                  DataRoomTableColumnDefinition["name"],
                  DataRoomTableColumnDefinition
                > name="name">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        component="legend"
                        style={{ fontSize: ".75rem" }}
                      >
                        Name
                      </FormLabel>
                      <TextField
                        fullWidth
                        disabled={disabled}
                        autoComplete="off"
                        size="small"
                        autoFocus
                        inputRef={nameFieldRef}
                        {...field}
                        variant="outlined"
                      ></TextField>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Field<
                  DataRoomTableColumnDefinition["primitiveType"],
                  DataRoomTableColumnDefinition
                > name="primitiveType">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        component="legend"
                        style={{ fontSize: ".75rem" }}
                      >
                        Primitive type
                      </FormLabel>
                      <Select
                        id="select"
                        fullWidth
                        size="small"
                        disabled={disabled}
                        {...field}
                        variant="outlined"
                      >
                        {dataRoomTablePrimitiveTypeOptions?.map(
                          ({ label: l, value: v }, index) => (
                            <MenuItem key={index} value={v}>
                              {l}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={3}>
                <Field<
                  DataRoomTableColumnDefinition["nullable"],
                  DataRoomTableColumnDefinition
                > name="nullable">
                  {({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          size="small"
                          checked={field.value}
                          {...field}
                        />
                      }
                      label="Nullable"
                    />
                  )}
                </Field>
              </Grid>
              <Grid
                container
                item
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                {isEditing && (
                  <IconButton size="small" onClick={onCancel}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      fixedWidth
                    ></FontAwesomeIcon>
                  </IconButton>
                )}
                <IconButton size="small" disabled={disabled} type="submit">
                  <FontAwesomeIcon
                    icon={isEditing ? faCheck : faPlus}
                    fixedWidth
                  ></FontAwesomeIcon>
                </IconButton>
              </Grid>
            </Grid>
            <Typography
              variant="caption"
              style={{
                position: "absolute",
                color: red["400"],
                bottom: "-16px",
              }}
            >
              {(formProps.errors as ValidationError).message}
            </Typography>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataRoomTableColumnForm;
