// @ts-nocheck
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { Actions } from "components";

const ContactActions = ({ id, contactId, inline = false }: any) => {
  const actions = [
    {
      icon: <FontAwesomeIcon fixedWidth icon={faTrashAlt} />,
      title: "Delete contact",
      handle: () => alert("Should delete contact"),
      isPrimary: false,
      isEnabled: true,
      isRed: true,
    },
  ];
  return <Actions inline={inline} actions={actions} />;
};

export default memo(ContactActions);
