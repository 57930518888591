import { faCheck, faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Field, Form, Formik } from "formik";
import { DataRoomParticipant, DataRoomParticipantDefinition } from "models";
import { useCallback, useRef } from "react";
import { ValidationError } from "yup";
import { dataRoomParticipantInitialValues } from "./DataRoomParticipantsInitialValues";
import { dataRoomParticipantValidationScheme } from "./DataRoomParticipantsValidationScheme";

export interface DataRoomParticipationOption {
  title: string;
  value: string;
}

interface DataRoomParticipantFormProps {
  initialValues?: DataRoomParticipant;
  queries: DataRoomParticipationOption[];
  tables: DataRoomParticipationOption[];
  usedEmails: string[];
  onSubmit: (participantDefinition: DataRoomParticipantDefinition) => void;
  onCancel?: () => void;
  isEditing?: boolean;
  disabled?: boolean;
}

const DataRoomParticipantForm: React.FC<DataRoomParticipantFormProps> = ({
  usedEmails,
  queries,
  tables,
  onSubmit,
  onCancel,
  disabled = false,
  isEditing = false,
  initialValues = dataRoomParticipantInitialValues,
}) => {
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const validate = useCallback(
    async (values: DataRoomParticipantDefinition) => {
      try {
        await dataRoomParticipantValidationScheme.validate(values, {
          context: { emails: usedEmails },
        });
        return {};
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    [usedEmails]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (participant, helpers) => {
        onSubmit(participant);
        emailFieldRef.current?.focus();
        helpers.resetForm();
      }}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => {
        return (
          <Form style={{ width: "100%" }} autoComplete="off">
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={3}>
                <Field<
                  DataRoomParticipantDefinition["userEmail"],
                  DataRoomParticipantDefinition
                > name="userEmail">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        component="legend"
                        style={{ fontSize: ".75rem" }}
                      >
                        Email, is an Auditor
                      </FormLabel>
                      <TextField
                        fullWidth
                        disabled={disabled}
                        size="small"
                        autoFocus
                        inputRef={emailFieldRef}
                        {...field}
                        inputProps={{ "aria-autocomplete": "none" }}
                        autoComplete="new-password"
                        variant="outlined"
                      ></TextField>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field<
                  DataRoomParticipantDefinition["tables"],
                  DataRoomParticipantDefinition
                > name="tables">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        component="legend"
                        style={{ fontSize: ".75rem" }}
                      >
                        Data Provider for
                      </FormLabel>
                      <Select
                        id="select"
                        displayEmpty
                        fullWidth
                        multiple
                        {...field}
                        size="small"
                        renderValue={(selected) =>
                          tables
                            .filter(({ value: id }) => selected.includes(id))
                            .map(({ title }) => title)
                            .join(", ")
                        }
                        variant="outlined"
                      >
                        {tables.map(({ value, title }) => (
                          <MenuItem key={value} value={value}>
                            <Checkbox checked={field.value.includes(value)} />
                            <ListItemText primary={title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field<
                  DataRoomParticipantDefinition["queries"],
                  DataRoomParticipantDefinition
                > name="queries">
                  {({ field }) => (
                    <FormControl fullWidth>
                      <FormLabel
                        component="legend"
                        style={{ fontSize: ".75rem" }}
                      >
                        and Data Analyst for
                      </FormLabel>
                      <Select
                        id="query-select"
                        fullWidth
                        multiple
                        {...field}
                        size="small"
                        renderValue={(selected) =>
                          queries
                            .filter(({ value: id }) => selected.includes(id))
                            .map(({ title }) => title)
                            .join(", ")
                        }
                        variant="outlined"
                      >
                        {queries.map(({ value, title }) => (
                          <MenuItem key={value} value={value}>
                            <Checkbox checked={field.value.includes(value)} />
                            <ListItemText primary={title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid
                container
                item
                xs={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                {isEditing && (
                  <IconButton size="small" onClick={onCancel}>
                    <FontAwesomeIcon
                      icon={faTimes}
                      fixedWidth
                    ></FontAwesomeIcon>
                  </IconButton>
                )}
                <IconButton size="small" disabled={disabled} type="submit">
                  <FontAwesomeIcon
                    icon={isEditing ? faCheck : faPlus}
                    fixedWidth
                  ></FontAwesomeIcon>
                </IconButton>
              </Grid>
            </Grid>
            <Typography
              variant="caption"
              style={{ position: "absolute", color: red["400"] }}
            >
              {(formProps.errors as ValidationError).message}
            </Typography>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DataRoomParticipantForm;
