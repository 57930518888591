import { createContext, useContext } from "react";

const NAVIGATION_DRAWER_VARIANT_TEMPORARY = "temporary";

export const MainContext = createContext({
  showEntitiesIds: true,
  setShowEntitiesIds: () => {},
  prefersColorSchemeDark: false,
  setPrefersColorSchemeDark: () => {},
  isDarkThemeEnabled: false,
  setIsDarkThemeEnabled: () => {},
  isNavigationDrawerOpen: false,
  toggleNavigationDrawer: () => {},
  openNavigationDrawer: () => {},
  closeNavigationDrawer: () => {},
  navigationDrawerVariant: NAVIGATION_DRAWER_VARIANT_TEMPORARY,
  toggleNavigationDrawerVariant: () => {},
  setNavigationDrawerVariantTemporary: () => {},
  setNavigationDrawerVariantPermanent: () => {},
});

export const MainProvider = MainContext.Provider;

export const useMain = () => useContext(MainContext);

export default useMain;
