import { TextField, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FastField, useFieldValue, useStatus } from "formik";
import { memo } from "react";

const useInputLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    transform: "none",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    display: "inline-flex",
  },
  asterisk: {
    color: "red",
    fontSize: "1.25rem",
  },
}));

const useInputStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5, 0),
  },
  multiline: {
    padding: theme.spacing(0.5, 0),
  },
  input: {
    fontSize: "1rem",
    lineHeight: "1.25rem !important",
    minHeight: "1.25rem !important",
    padding: 0,
  },
}));

const NameField = memo(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type '{ childre... Remove this comment to see the full error message
  ({ label, name, startAdornment, endAdornment, readOnly, ...props }) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isEditing' does not exist on type '((sta... Remove this comment to see the full error message
    const [{ isEditing }] = useStatus();
    const [isRequired] = useFieldValue(name.replace(/name$/g, "isRequired"));
    const inputLabelClasses = useInputLabelStyles();
    const inputClasses = useInputStyles();
    return (
      <FastField name={name}>
        {({ field, meta: { error, touched } }) => (
          <TextField
            variant="standard"
            color="secondary"
            fullWidth
            multiline
            label={label}
            InputLabelProps={{
              required: isEditing ? true : isRequired,
              shrink: true,
              classes: inputLabelClasses,
            }}
            InputProps={{
              classes: inputClasses,
              style: { fontWeight: "bold" },
              startAdornment,
              endAdornment,
              disableUnderline: !isEditing,
              readOnly: !isEditing || readOnly,
              disabled: !isEditing,
            }}
            error={Boolean(error)}
            helperText={error}
            {...field}
            {...props}
          />
        )}
      </FastField>
    );
  }
);

export default NameField;
