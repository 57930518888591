import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_EVERYTHING } from "gqls";

export const DATA_ROOM_TABLES = gql`
  query dataRoomTables(
    $filter: DataRoomTableFilter
    $first: Int
    $offset: Int
    $orderBy: [DataRoomTablesOrderBy!]
  ) {
    dataRoomTables(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      totalCount
      nodes {
        ...DataRoomTableEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_EVERYTHING}
`;
