import { faTrashAlt as faTrashAltDuotone } from "@fortawesome/pro-duotone-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const useDialogStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const useDialogContentStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:first-of-type": {
      paddingTop: theme.spacing(2),
    },
  },
}));

const useDialogActionsStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2, 2, 2),
    justifyContent: "space-between",
  },
}));

const useLoadingButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "1rem",
    fontWeight: 700,
    letterSpacing: "-0.25px",
    lineHeight: 1.5,
    // borderColor: theme.palette.text.disabled,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: theme.spacing(1),
      "& > *:first-child": {
        fontSize: "1.125rem",
      },
    },
  },
  loadingIndicatorStart: {
    left: theme.spacing(1),
  },
}));

const useButtonClasses = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "1rem",
    lineHeight: 1.5,
    // borderColor: theme.palette.text.disabled,
    padding: theme.spacing(0.375, 0.75),
    minWidth: "auto",
  },
}));

const DataRoomDeleteDialog = ({
  name,
  open,
  loading,
  onCancel,
  onConfirm,
}: any) => {
  const dialogContentClasses = useDialogContentStyles();
  const dialogClasses = useDialogStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const buttonClasses = useButtonClasses();
  const loadingButtonClasses = useLoadingButtonStyles();
  return (
    <Dialog
      classes={dialogClasses}
      open={open}
      onClose={!loading ? onCancel : () => {}}
    >
      <DialogContent classes={dialogContentClasses}>
        <Box sx={{ mb: 1, textAlign: "center" }}>
          <FontAwesomeIcon fixedWidth icon={faTrashAltDuotone} size="3x" />
        </Box>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          style={{ lineHeight: 1.25 }}
        >
          <strong>
            Are you sure
            <br />
            you want to delete
            <br />
            {name}?
          </strong>
        </Typography>
        <Typography variant="body2" align="center">
          You can't restore the information, all data will be lost.
          <br />
          You will lose access to this data room, the data room
          <br />
          itself will be scheduled for purge 10 days.
          <br />
          Drop us an email if you change your mind and want
          <br />
          to restore it or if you'd like it to be purged immediately.
        </Typography>
      </DialogContent>
      <DialogActions classes={dialogActionsClasses}>
        <Button
          classes={buttonClasses}
          color="inherit"
          variant="outlined"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </Button>
        <LoadingButton
          classes={loadingButtonClasses}
          color="inherit"
          variant="outlined"
          onClick={onConfirm}
          loading={loading}
          loadingPosition="start"
          startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DataRoomDeleteDialog;
