import {
  ApiCoreWrapper,
  ApolloWrapper,
  Auth0Wrapper,
  ConfigurationWrapper,
  MainWrapper,
  OptimizelyWrapper,
  PagesWrapper,
  RouterWrapper,
  SnackbarWrapper,
  StylesWrapper,
  WorkersWrapper,
} from "wrappers";

const App = () => {
  return (
    <RouterWrapper>
      <ConfigurationWrapper>
        <MainWrapper>
          <Auth0Wrapper>
            <OptimizelyWrapper>
              <ApiCoreWrapper>
                <ApolloWrapper>
                  <WorkersWrapper>
                    <StylesWrapper>
                      <SnackbarWrapper>
                        <PagesWrapper />
                      </SnackbarWrapper>
                    </StylesWrapper>
                  </WorkersWrapper>
                </ApolloWrapper>
              </ApiCoreWrapper>
            </OptimizelyWrapper>
          </Auth0Wrapper>
        </MainWrapper>
      </ConfigurationWrapper>
    </RouterWrapper>
  );
};

export default App;
