import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { DataRoomLogsPreview } from "components";

interface DataRoomAuditLogsProps {
  onFetchLogs: () => Promise<string>;
}

const closeSnackbarAction = (close: any) => (key: any) =>
  (
    <IconButton onClick={() => close(key)}>
      <FontAwesomeIcon
        fixedWidth
        icon={faTimes}
        color="white"
      ></FontAwesomeIcon>
    </IconButton>
  );

const DataRoomAuditLogs: React.FC<DataRoomAuditLogsProps> = ({
  onFetchLogs,
}) => {
  const [auditLogsResult, setAuditLogResult] = useState<{
    isLoading: boolean;
    data?: string;
  }>({
    isLoading: false,
    data: undefined,
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleFetchLogs = useCallback(async () => {
    try {
      setAuditLogResult((state) => ({
        ...state,
        isLoading: true,
      }));
      const result = await onFetchLogs();
      setAuditLogResult(() => ({
        data: result,
        isLoading: false,
      }));
    } catch (error) {
      setAuditLogResult(() => ({
        data: undefined,
        isLoading: false,
      }));
      enqueueSnackbar(`Can't fetch logs: ${error.message}`, {
        variant: "error",
        persist: true,
        action: closeSnackbarAction(closeSnackbar),
      });
    }
  }, [onFetchLogs, enqueueSnackbar, closeSnackbar, setAuditLogResult]);
  useEffect(() => {
    handleFetchLogs();
  }, [handleFetchLogs]);
  return (
    <>
      <DataRoomLogsPreview
        logs={auditLogsResult.data}
        asTable
        onFetchLogs={handleFetchLogs}
        isLoading={auditLogsResult.isLoading}
      />
    </>
  );
};

export default DataRoomAuditLogs;
