import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, IconButton, Tooltip } from "@material-ui/core";
import { memo } from "react";
import { useMain } from "contexts";

const NAVIGATION_DRAWER_VARIANT_PERMANENT = "permanent";

const NavigationDrawerOpenButton = memo(() => {
  const { openNavigationDrawer, navigationDrawerVariant } = useMain();
  const isNavPermanent =
    navigationDrawerVariant === NAVIGATION_DRAWER_VARIANT_PERMANENT;
  return isNavPermanent ? null : (
    <Tooltip
      title="Menu"
      placement="bottom"
      disableFocusListener
      disableTouchListener
      enterDelay={750}
    >
      <IconButton color="inherit" onClick={openNavigationDrawer}>
        <Badge color="error" badgeContent={null}>
          <FontAwesomeIcon fixedWidth icon={faBars} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
});

export default NavigationDrawerOpenButton;
