// @ts-nocheck
import { faLockKeyhole as faLockKeyholeSolid } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { Fragment, memo } from "react";
import { NavLink } from "react-router-dom";
import {
  BlockLoading,
  DataRoomActions,
  DataRoomMeta,
  Id,
  InlineEditor,
  Label,
  LabelIcon,
  StateIcon,
} from "components";
import { useBlockStyles } from "styles/block";

const useLinkStyles = makeStyles((theme: Theme) => ({
  linkWrapper: {
    display: "flex",
    alignItems: "baseline",
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const SHOW_END_ADORNMENT = false;

const DataRoomBlock = memo(
  ({
    loading = false,
    dataRoom,
    withMeta = false,
    withAttributes = false,
    nameWeight,
  }: any) => {
    const {
      id,
      dataRoomId,
      name,
      isPublished,
      updatedAt: statusUpdatedAt,
    } = dataRoom || {};
    const blockClasses = useBlockStyles({ nameWeight });
    const linkClasses = useLinkStyles();
    return loading ? (
      <BlockLoading withMeta={withMeta} withAttributes={withAttributes} />
    ) : (
      <div className={blockClasses.wrapper}>
        <div className={blockClasses.statusWrapper}>
          <StateIcon
            status={isPublished ? "PUBLISHED" : "DRAFT"}
            statusUpdatedAt={statusUpdatedAt}
            statusTimeSuffix=" ago"
          />
        </div>
        <div className={blockClasses.nameMetaActionsWrapper}>
          <div className={blockClasses.nameActionsWrapper}>
            <div className={blockClasses.nameWrapper}>
              <NavLink
                className={linkClasses.linkWrapper}
                to={`/datarooms/${dataRoomId}`}
              >
                <InlineEditor
                  value={name || "Data room"}
                  endAdornment={
                    SHOW_END_ADORNMENT ? (
                      <Fragment>
                        {isPublished ? (
                          <LabelIcon icon={faLockKeyholeSolid} />
                        ) : (
                          <Label label="Draft" />
                        )}
                        <Id id={dataRoomId} href={`/datarooms/${dataRoomId}`} />
                      </Fragment>
                    ) : null
                  }
                />
              </NavLink>
            </div>
            <div className={blockClasses.actionsWrapper}>
              <DataRoomActions
                id={id}
                dataRoomId={dataRoomId}
                dataRoom={dataRoom}
                actions={({ menuLists }: any) => ({ menuLists })}
                inline
              />
            </div>
          </div>
          {(withAttributes || withMeta) && (
            <DataRoomMeta
              withAttributes={withAttributes}
              attributes={{}}
              withMeta={withMeta}
              meta={{}}
            />
          )}
        </div>
      </div>
    );
  }
);

export default DataRoomBlock;
