import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useContactBlockStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  avatar: {
    fontSize: theme.spacing(4),
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: "transparent",
    color: "#bdbdbd",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    "& > svg": {
      width: "100% !important",
      height: "100% !important",
    },
  },
  info: {
    flex: 1,
    display: "flex",
    alignItems: "baseline",
    flexDirection: "row",
    position: "relative",
  },
}));
