import { gql } from "@apollo/client";
import { DATA_ROOM_SHARE_EVERYTHING } from "gqls";

export const CREATE_DATA_ROOM_SHARE = gql`
  mutation createDataRoomShare($input: CreateDataRoomShareInput!) {
    createDataRoomShare(input: $input) {
      clientMutationId
      dataRoomShare {
        ...DataRoomShareEverything
      }
    }
  }
  ${DATA_ROOM_SHARE_EVERYTHING}
`;
