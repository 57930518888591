import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_EVERYTHING } from "gqls";

export const CREATE_DATA_ROOM_QUERY = gql`
  mutation createDataRoomQuery($input: CreateDataRoomQueryInput!) {
    createDataRoomQuery(input: $input) {
      clientMutationId
      dataRoomQuery {
        ...DataRoomQueryEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_EVERYTHING}
`;
