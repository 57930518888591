import { createContext, useContext } from "react";

export const ConfigurationContext = createContext({
  configuration: {} as any,
});

export const ConfigurationProvider = ConfigurationContext.Provider;

export const useConfiguration = () => useContext(ConfigurationContext);

export default useConfiguration;
