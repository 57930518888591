import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_EVERYTHING } from "gqls";

export const UPDATE_DATA_ROOM_TABLE_BY_ID = gql`
  mutation updateDataRoomTableById($input: UpdateDataRoomTableByIdInput!) {
    updateDataRoomTableById(input: $input) {
      clientMutationId
      dataRoomTable {
        ...DataRoomTableEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_EVERYTHING}
`;
