// @ts-nocheck
import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Pagination,
  PaginationItem,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo, useCallback } from "react";
import { PaginationMenu } from "components";
import { useData } from "contexts";

const useRefetchButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const RefetchButton = memo(({ refetch }) => {
  const refetchButtonClasses = useRefetchButtonStyles();
  return (
    <IconButton
      color="inherit"
      onClick={() => refetch()}
      classes={refetchButtonClasses}
      TouchRippleProps={{ center: false }}
    >
      <FontAwesomeIcon fixedWidth icon={faSync} />
    </IconButton>
  );
});

const usePaginationControlsStyles = makeStyles((theme: Theme) => ({
  controls: {
    display: "flex",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.2s linear",
    "&:hover": {
      background: "rgba(0,0,0,0.05)",
    },
  },
}));

const usePageInfoStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    padding: theme.spacing(0, 0.5),
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  of: {
    margin: theme.spacing(0, 0.5),
    fontWeight: 400,
  },
}));

const PageInfo = memo(({ offset, dataTotalCount, pageSize }) => {
  const pageInfoClasses = usePageInfoStyles();
  return (
    <div className={pageInfoClasses.wrapper}>
      {offset + 1}–{Math.min(offset + pageSize, dataTotalCount)}
      <div className={pageInfoClasses.of}>of</div>
      {dataTotalCount}
    </div>
  );
});

const usePaginationStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(0.25),
  },
}));

const usePaginationItemStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  sizeSmall: {
    height: theme.spacing(3),
    minWidth: theme.spacing(3),
    marginLeft: 0,
    marginRight: theme.spacing(0.25),
  },
}));

const PaginationBlock = memo(({ setPage, dataTotalCount, pageSize, page }) => {
  const paginationClasses = usePaginationStyles();
  const paginationItemClasses = usePaginationItemStyles();
  const paginationRenderItem = useCallback(
    (item) => <PaginationItem classes={paginationItemClasses} {...item} />,
    [paginationItemClasses]
  );
  const paginationOnChange = useCallback(
    (event, page) => setPage(page),
    [setPage]
  );
  return (
    <Pagination
      count={Math.ceil(dataTotalCount / pageSize)}
      page={page}
      size="small"
      shape="rounded"
      showFirstButton
      showLastButton
      renderItem={paginationRenderItem}
      onChange={paginationOnChange}
      classes={paginationClasses}
    />
  );
});

const usePaginationWrapperStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const PaginationWrapper = memo(() => {
  const paginationWrapperClasses = usePaginationWrapperStyles();
  const paginationControlsClasses = usePaginationControlsStyles();
  const {
    dataTotalCount,
    offset,
    page,
    pageSize,
    refetch,
    setPage,
    setPageSize,
  } = useData();
  return dataTotalCount > pageSize ? (
    <div className={paginationWrapperClasses.wrapper}>
      <div className={paginationControlsClasses.controls}>
        <Tooltip
          title={"Refetch"}
          placement="top-start"
          disableFocusListener
          disableTouchListener
        >
          <div>
            <RefetchButton refetch={refetch} />
          </div>
        </Tooltip>
        <PageInfo
          dataTotalCount={dataTotalCount}
          offset={offset}
          pageSize={pageSize}
        />
      </div>
      <div className={paginationControlsClasses.controls}>
        <PaginationBlock
          dataTotalCount={dataTotalCount}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
        />
        <PaginationMenu pageSize={pageSize} setPageSize={setPageSize} />
      </div>
    </div>
  ) : null;
});

export default PaginationWrapper;
