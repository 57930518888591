import {
  faBoxCheck,
  faChevronDown,
  faChevronLeft,
  faFileExport,
  faLockAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { saveAs } from "file-saver";
import { parse as parseCsv } from "papaparse";
import React, { memo, useCallback, useMemo } from "react";

const useSectionHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
}));

interface DataRoomLogsPreviewProps {
  logs?: string;
  asTable?: boolean;
  onFetchLogs?: () => void;
  isLoading?: boolean;
}

const DataRoomLogsPreview: React.FC<DataRoomLogsPreviewProps> = memo(
  ({ logs, asTable = false, onFetchLogs, isLoading }) => {
    const sectionHeaderClasses = useSectionHeaderStyles();
    const handleExport = useCallback((logs?: string) => {
      const file = new File([logs as string], `Audit.csv`, {
        type: "application/octet-stream;charset=utf-8",
      });
      saveAs(file);
    }, []);
    const logsData = useMemo<{ data: string[][]; rowLength: number }>(() => {
      if (asTable && logs) {
        const { data, errors } = parseCsv<string[]>(logs);
        if (!errors.length) {
          return { data, rowLength: Math.max(...data.map((r) => r.length)) };
        }
        return { data: [], rowLength: 0 };
      }
      return { data: [], rowLength: 0 };
    }, [logs, asTable]);
    const [isOpen, { toggle }] = useBoolean(true);
    const hasLogs = logs && logs.trim().length;
    return (
      <Grid container>
        <Grid
          item
          container
          justifyContent="space-between"
          xs={12}
          style={{ marginBottom: ".5rem" }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Audit logs
            </Typography>
            <Tooltip title="Activity log is integrity protected by the enclave. No one, not even Decentriq can modify it.">
              <div>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faLockAlt}
                  style={{ fontSize: "1rem", marginLeft: "8px" }}
                />
              </div>
            </Tooltip>
          </Box>
          <Box>
            <Button
              size="small"
              color="inherit"
              style={{ marginRight: "1rem" }}
              onClick={onFetchLogs}
              startIcon={
                isLoading ? (
                  <CircularProgress size={18} color="inherit" thickness={3} />
                ) : (
                  <FontAwesomeIcon fixedWidth icon={faBoxCheck} />
                )
              }
            >
              Download audit log
            </Button>
            {hasLogs && (
              <>
                <Button
                  size="small"
                  color="inherit"
                  disabled={!hasLogs}
                  style={{ marginRight: "1rem" }}
                  onClick={() => handleExport(logs)}
                  startIcon={<FontAwesomeIcon fixedWidth icon={faFileExport} />}
                >
                  Export audit log
                </Button>
                <IconButton size="small" onClick={() => toggle()}>
                  <FontAwesomeIcon
                    fixedWidth
                    icon={isOpen ? faChevronDown : faChevronLeft}
                  ></FontAwesomeIcon>
                </IconButton>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {asTable ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    {logsData.data.map((row, rIndex) => (
                      <TableRow key={rIndex}>
                        {Array.from({ length: logsData.rowLength }).map(
                          (_, cIndex) => (
                            <TableCell key={cIndex}>
                              {row[cIndex] || ""}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <textarea
                defaultValue={logs}
                readOnly
                style={{
                  maxWidth: "100%",
                  minHeight: "5rem",
                  minWidth: "100%",
                }}
              />
            )}
          </Collapse>
        </Grid>
      </Grid>
    );
  }
);

export default DataRoomLogsPreview;
