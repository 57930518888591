// @ts-nocheck
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useBlockStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    flexDirection: "row",
    alignItems: "flex-start",
    position: "relative",
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.2s linear",
    "&:hover": {
      background: theme.palette.action.hover,
      "& $actionsWrapper": {
        opacity: 1,
      },
    },
  },
  statusWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: theme.spacing(4),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0.5),
    "& > span": {
      padding: theme.spacing(0.5),
    },
  },
  nameMetaActionsWrapper: {
    width: 0,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
  },
  nameActionsWrapper: {
    display: "flex",
    width: "100%",
  },
  nameWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    width: 0,
    fontWeight: ({ nameWeight }) => {
      switch (nameWeight) {
        case "primary":
          return 600;
        case "secondary":
          return 500;
        case "inherit":
          return "inherit";
        default:
          return 400;
      }
    },
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  actionsWrapper: {
    opacity: 0,
    transition: "all 0.2s linear",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    marginLeft: theme.spacing(1),
  },
}));
