import { Box, Divider, List, ListItem } from "@material-ui/core";
import {
  DataRoomParticipant,
  DataRoomParticipantDefinition,
  DataRoomQuery,
  DataRoomTable,
} from "models";
import { Fragment, useMemo, useState } from "react";
import DataRoomParticipantForm, {
  DataRoomParticipationOption,
} from "./DataRoomParticipantForm";
import DataRoomParticipantTile from "./DataRoomParticipantTile";

export enum DataRoomParticipantsMode {
  EDIT = "edit",
  READONLY = "readonly",
}

interface DataRoomParticipantsProps {
  mode: DataRoomParticipantsMode;
  participants: DataRoomParticipant[];
  tables: DataRoomTable[];
  queries: DataRoomQuery[];
  onCreate: (participantDefinition: DataRoomParticipantDefinition) => void;
  onUpdate: (participant: DataRoomParticipant) => void;
  onDelete: (participant: DataRoomParticipant) => void;
}

const DataRoomParticipants: React.FC<DataRoomParticipantsProps> = ({
  participants,
  tables,
  queries,
  onCreate,
  onUpdate,
  onDelete,
  mode,
}) => {
  const [rowToEditIndex, setRowToEditIndex] = useState<number>(-1);
  const hasEditableParticipant = rowToEditIndex !== -1;
  const readonly = mode === DataRoomParticipantsMode.READONLY;
  const tablesOptions = useMemo<DataRoomParticipationOption[]>(
    () =>
      tables.map((t) => ({
        title: t.name,
        value: t.dataRoomTableId,
      })),
    [tables]
  );
  const queriesOptions = useMemo<DataRoomParticipationOption[]>(
    () =>
      queries.map((t) => ({
        title: t.name,
        value: t.dataRoomQueryId,
      })),
    [queries]
  );
  const usedEmails = useMemo(
    () =>
      participants
        .map((p) => p.userEmail)
        .filter((_, i) => i !== rowToEditIndex),
    [participants, rowToEditIndex]
  );
  return (
    <Box>
      {participants.length > 0 && (
        <List
          style={{ background: "#f9f9f9", marginBottom: "8px", padding: 0 }}
        >
          {participants.map((participant, index) => (
            <Fragment key={participant.userEmail}>
              {rowToEditIndex === index ? (
                <ListItem>
                  <DataRoomParticipantForm
                    usedEmails={usedEmails}
                    tables={tablesOptions}
                    queries={queriesOptions}
                    onSubmit={(participantDefinition) => {
                      onUpdate({
                        ...participant,
                        ...participantDefinition,
                      });
                      setRowToEditIndex(-1);
                    }}
                    isEditing
                    onCancel={() => setRowToEditIndex(-1)}
                    initialValues={participant}
                  />
                </ListItem>
              ) : (
                <DataRoomParticipantTile
                  index={index}
                  participant={participant}
                  tables={tablesOptions}
                  queries={queriesOptions}
                  readonly={readonly}
                  allowEditing={!hasEditableParticipant && !readonly}
                  onEdit={setRowToEditIndex}
                  onDelete={onDelete}
                />
              )}
              {index !== participants.length - 1 && (
                <Divider style={{ borderBottomWidth: "1px" }} />
              )}
            </Fragment>
          ))}
        </List>
      )}
      {!readonly && (
        <>
          <List style={{ background: "#f9f9f9", padding: "8px" }}>
            <ListItem style={{ padding: 0 }}>
              <DataRoomParticipantForm
                usedEmails={usedEmails}
                onSubmit={onCreate}
                tables={tablesOptions}
                queries={queriesOptions}
                disabled={hasEditableParticipant}
              />
            </ListItem>
          </List>
        </>
      )}
    </Box>
  );
};

export default DataRoomParticipants;
