import { useQuery } from "@apollo/client";
import { gqlToSafeQuery, Table as SqTable } from "@decentriq/safequery";
import { DataRoom as SqDataRoom } from "@decentriq/safequery/lib/schema";
import {
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Theme,
  Typography,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataRoomQueryConstructorMode,
  DataRoomTableConstructorMode,
} from "components";
import { DataRoomParticipantsMode } from "components/entities/dataRoom/DataRoomParticipants/DataRoomParticipants";
import {
  DataRoomAuditLogs,
  DataRoomParticipants,
  DataRoomQueries,
  DataRoomTables,
} from "containers";
import { useApiCore } from "contexts/apicore/apicore";
import { DATA_ROOM } from "gqls";

const useTabListStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "30px",
  },
}));

const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    fontSize: "15px",
    padding: theme.spacing(0.5, 6),
    minHeight: "30px",
    minWidth: "auto",
  },
}));

const useTabPanelStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "8px",
  },
}));

const useSectionHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
}));

const prettifyPassword = (password: any) => {
  if (!password) {
    return null;
  }
  const trimmedPassword = password.trim();
  if (trimmedPassword) {
    return trimmedPassword;
  }
  return null;
};

const useBackdropStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
  },
}));

const DataRoom = () => {
  const backdropClasses = useBackdropStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { dataRoomId } = useParams();
  const { getClient } = useApiCore();
  // Fetch dataRoom from the database
  const {
    data,
    loading: isDataRoomLoading,
    error,
  } = useQuery(DATA_ROOM, {
    variables: {
      dataRoomId,
    },
    onError: (error) => {
      console.error("Something went wrong", error);
      enqueueSnackbar("Can't fetch data room from the database", {
        variant: "error",
      });
    },
  });
  const dataRoom: SqDataRoom = data?.dataRoom || {
    isPublished: false,
    isOwner: false,
    isParticipant: false,
  };
  const { isPublished } = dataRoom;
  const isDataRoomExist = !!data?.dataRoom;
  // Navigate away if dataRoom wasn't found
  if (!isDataRoomLoading && !isDataRoomExist) {
    navigate("/");
  }
  const mapDataRoomDefinitionToGql = (dataRoom: SqDataRoom): SqDataRoom => ({
    ...dataRoom,
    tables: dataRoom.dataRoomTables?.nodes?.map((table) => ({
      name: table?.name,
      sqlCreateStatement: table?.sqlCreateStatement,
      dataProviders: (table as any)?.dataRoomTableShares?.nodes?.map(
        (share: any) => share?.userEmail as string
      ),
    })),
    queries: dataRoom.dataRoomQueries?.nodes?.map((query) => ({
      name: query?.name,
      sqlSelectStatement: query?.sqlSelectStatement,
      dataAnalysts: (query as any)?.dataRoomQueryShares?.nodes?.map(
        (share: any) => share?.userEmail
      ),
    })),
  });
  const handleIngestData = useCallback(
    async (tableDefinition: SqTable, content: string) => {
      const client = await getClient();
      const gql = mapDataRoomDefinitionToGql(dataRoom);
      const safeQueryInstance = gqlToSafeQuery(gql, client);
      return safeQueryInstance.ingestData(tableDefinition, content);
    },
    [getClient, dataRoom]
  );
  const handleGetPublishedDatasets = useCallback(async () => {
    const client = await getClient();
    const gql = mapDataRoomDefinitionToGql(dataRoom);
    const safeQueryInstance = gqlToSafeQuery(gql, client);
    return safeQueryInstance.getPublishedDatasets();
  }, [getClient, dataRoom]);
  const handleRemovePublishedDataset = useCallback(
    async (tableDefinition: SqTable, manifestHash: Uint8Array) => {
      const client = await getClient();
      const gql = mapDataRoomDefinitionToGql(dataRoom);
      const safeQueryInstance = gqlToSafeQuery(gql, client);
      safeQueryInstance.deletePublishedDataset(tableDefinition, manifestHash);
    },
    [getClient, dataRoom]
  );
  const handleRunQuery = useCallback(
    async (queryName: string) => {
      const client = await getClient();
      const gql = mapDataRoomDefinitionToGql(dataRoom);
      const safeQueryInstance = gqlToSafeQuery(gql, client);
      return safeQueryInstance.runQuery(queryName);
    },
    [getClient, dataRoom]
  );
  const handleFetchAuditLog = useCallback(async () => {
    const client = await getClient();
    const gql = mapDataRoomDefinitionToGql(dataRoom);
    const safeQueryInstance = gqlToSafeQuery(gql, client);
    return safeQueryInstance.retrieveAuditLog();
  }, [getClient, dataRoom]);
  const handleGetDataRoomStatus = useCallback(async () => {
    const client = await getClient();
    const gql = mapDataRoomDefinitionToGql(dataRoom);
    const safeQueryInstance = gqlToSafeQuery(gql, client);
    return safeQueryInstance.getStatus();
  }, [getClient, dataRoom]);
  const handleUpdateDataRoomStatus = useCallback(
    async (status) => {
      const client = await getClient();
      const gql = mapDataRoomDefinitionToGql(dataRoom);
      const safeQueryInstance = gqlToSafeQuery(gql, client);
      return safeQueryInstance.updateStatus(status);
    },
    [getClient, dataRoom]
  );
  // Render
  const loading = isDataRoomLoading;
  // Tabs
  const tabListClasses = useTabListStyles();
  const tabPanelClasses = useTabPanelStyles();
  const tabClasses = useTabStyles();
  const sectionHeaderClasses = useSectionHeaderStyles();
  const [value, setValue] = useState("Data");
  useEffect(() => {
    setValue(isPublished ? "Overview" : "Data");
  }, [isPublished]);
  const handleChange = (event: any, newValue: any) => setValue(newValue);
  return loading ? (
    <Backdrop classes={backdropClasses} open={loading} invisible>
      <CircularProgress color="inherit" thickness={1} size="2.5rem" />
    </Backdrop>
  ) : error ? null : (
    <div>
      {isPublished ? (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
            <TabList
              classes={tabListClasses}
              textColor="inherit"
              onChange={handleChange}
            >
              <Tab classes={tabClasses} label="OVERVIEW" value="Overview" />
              <Tab classes={tabClasses} label="STATUS" value="Status" />
              <Tab classes={tabClasses} label="ACTIONS" value="Actions" />
            </TabList>
          </Box>
          <TabPanel value="Overview" classes={tabPanelClasses}>
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Data
            </Typography>
            <DataRoomTables mode={DataRoomTableConstructorMode.READONLY} />
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Analysis
            </Typography>
            <DataRoomQueries mode={DataRoomQueryConstructorMode.READONLY} />
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Participants
            </Typography>
            <DataRoomParticipants mode={DataRoomParticipantsMode.READONLY} />
          </TabPanel>
          <TabPanel value="Status" classes={tabPanelClasses}>
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Data
            </Typography>
            <DataRoomTables
              mode={DataRoomTableConstructorMode.STATUS}
              onDatasetsStatus={handleGetPublishedDatasets}
            />
            <DataRoomAuditLogs onFetchLogs={handleFetchAuditLog} />
          </TabPanel>
          <TabPanel value="Actions" classes={tabPanelClasses}>
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Data
            </Typography>
            <DataRoomTables
              mode={DataRoomTableConstructorMode.ACTION}
              onIngestData={handleIngestData}
              onDatasetsStatus={handleGetPublishedDatasets}
              onDatasetRemove={handleRemovePublishedDataset}
            />
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Analysis
            </Typography>
            <DataRoomQueries
              mode={DataRoomQueryConstructorMode.ACTION}
              onRunQuery={handleRunQuery}
            />
          </TabPanel>
        </TabContext>
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
            <TabList
              classes={tabListClasses}
              textColor="inherit"
              onChange={handleChange}
            >
              <Tab classes={tabClasses} label="DATA" value="Data" />
              <Tab classes={tabClasses} label="ANALYSIS" value="Analysis" />
              <Tab
                classes={tabClasses}
                label="PARTICIPANTS"
                value="Participants"
              />
              <Tab classes={tabClasses} label="OVERVIEW" value="Overview" />
            </TabList>
          </Box>
          <TabPanel value="Data" classes={tabPanelClasses}>
            <DataRoomTables mode={DataRoomTableConstructorMode.EDIT} />
          </TabPanel>
          <TabPanel value="Analysis" classes={tabPanelClasses}>
            <DataRoomQueries mode={DataRoomQueryConstructorMode.EDIT} />
          </TabPanel>
          <TabPanel value="Participants" classes={tabPanelClasses}>
            <DataRoomParticipants mode={DataRoomParticipantsMode.EDIT} />
          </TabPanel>
          <TabPanel value="Overview" classes={tabPanelClasses}>
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Data
            </Typography>
            <DataRoomTables mode={DataRoomTableConstructorMode.READONLY} />
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Analysis
            </Typography>
            <DataRoomQueries mode={DataRoomQueryConstructorMode.READONLY} />
            <Typography variant="h6" classes={sectionHeaderClasses}>
              Participants
            </Typography>
            <DataRoomParticipants mode={DataRoomParticipantsMode.READONLY} />
          </TabPanel>
        </TabContext>
      )}
    </div>
  );
};
export default DataRoom;
