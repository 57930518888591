import Editor, { EditorProps } from "@monaco-editor/react";
import { memo } from "react";
import { ResizableBox } from "react-resizable";
import "./SqlEditor.css";

const defaultProps: EditorProps = {
  defaultLanguage: "sql",
};

const defaultEditorOptions: SqlEditorOptions = {
  minimap: { enabled: false },
};

interface SqlEditorOptions {
  minimap?: { enabled?: boolean };
  readOnly?: boolean;
}

interface SqlEditorProps extends EditorProps {
  editorOptions?: SqlEditorOptions;
}

const SqlEditor: React.FC<SqlEditorProps> = memo(
  ({ editorOptions, ...rest }) => {
    return (
      /* @ts-expect-error ts-migrate(2322) FIXME: */
      <ResizableBox height={200} axis="y">
        <Editor
          options={{
            ...defaultEditorOptions,
            ...editorOptions,
          }}
          wrapperClassName="sql-editor"
          {...defaultProps}
          {...rest}
        />
      </ResizableBox>
    );
  }
);

export const SqlEditorField: React.FC<SqlEditorProps> = memo((props) => (
  <SqlEditor {...props}></SqlEditor>
));

export default SqlEditor;
