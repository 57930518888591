import { Children, memo } from "react";
import { useMetaWrapperStyles } from "./MetaWrapper.styles";

const MetaWrapper = memo(({ children }) => {
  const metaWrapperClasses = useMetaWrapperStyles();
  return Children.count(children) ? (
    <div className={metaWrapperClasses.metaWrapper}>{children}</div>
  ) : null;
});

export default MetaWrapper;
