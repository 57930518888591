import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useViewSettingsStyles = makeStyles((theme: Theme) => ({
  groupName: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    lineHeight: 1,
    fontWeight: 500,
    fontSize: "small",
  },
}));
