import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, MenuItem, MenuList } from "@material-ui/core";
import { forwardRef } from "react";
import { useShowColumnsMenuItemStyles } from "./ShowColumnsMenu.styles";

const ShowColumnsMenuItem = forwardRef(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type '{ childre... Remove this comment to see the full error message
  ({ label, info, isDisabled, isSelected, onSelect }, ref) => {
    const ShowColumnsMenuItemClasses = useShowColumnsMenuItemStyles({
      isSelected,
    });
    return (
      <MenuItem
        button
        dense
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        innerRef={ref}
        disabled={isDisabled}
        className={ShowColumnsMenuItemClasses.menuItem}
        onClick={onSelect}
      >
        {isSelected && (
          <FontAwesomeIcon
            fixedWidth
            icon={faCheck}
            className={ShowColumnsMenuItemClasses.menuItemIcon}
          />
        )}
        <Box className={ShowColumnsMenuItemClasses.menuItemLabel}>
          <Box className={ShowColumnsMenuItemClasses.menuItemLabelTitle}>
            {label}
          </Box>
          {info && (
            <Box className={ShowColumnsMenuItemClasses.menuItemLabelInfo}>
              {info}
            </Box>
          )}
        </Box>
      </MenuItem>
    );
  }
);

const ShowColumnsMenu = ({ columnsWithSelections }: any) => {
  const { all, isSelected, toggle } = columnsWithSelections;
  return (
    <MenuList>
      {all.map(({ name, info, label, isDisabled }: any, index: any) => (
        <ShowColumnsMenuItem
          key={index}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; name: any; label: any; info: any... Remove this comment to see the full error message
          name={name}
          label={label}
          info={info}
          isDisabled={isDisabled}
          isSelected={isSelected(name)}
          onSelect={toggle.bind(null, name)}
        />
      ))}
    </MenuList>
  );
};

export default ShowColumnsMenu;
