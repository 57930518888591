import { faPlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useCallback } from "react";
import TimeAgo, { Formatter } from "react-timeago";

interface DataRoomQueryActionsProps {
  lastRunAt?: string;
  isLoading?: boolean;
  onRun: () => void;
}

const DataRoomQueryActions: React.FC<DataRoomQueryActionsProps> = ({
  isLoading,
  lastRunAt,
  onRun,
}) => {
  const timeFormatter = useCallback<Formatter>(
    (value, unit, suffix) =>
      unit === "second" && value <= 59
        ? "just now"
        : `${value} ${unit}${value === 1 ? "" : "s"} ${suffix}`,
    []
  );
  return (
    <Box style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
      <Typography variant="caption">
        {/*isLoading ? "Query running." : lastRunAt ? "Query completed." : ""*/}
        {lastRunAt ? (
          <>
            {" "}
            Last query started{" "}
            <TimeAgo date={lastRunAt} formatter={timeFormatter} />
          </>
        ) : (
          ""
        )}
      </Typography>
      {isLoading ? (
        <CircularProgress
          size={16}
          style={{ marginLeft: "16px" }}
          color="inherit"
        />
      ) : (
        <>
          <Button
            size="small"
            color="inherit"
            style={{ marginLeft: "16px" }}
            onClick={onRun}
            startIcon={<FontAwesomeIcon fixedWidth icon={faPlay} />}
          >
            {lastRunAt ? "Re-run" : "Run"}
          </Button>
        </>
      )}
    </Box>
  );
};

export default DataRoomQueryActions;
