import { faTrash, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { format, isValid, parseISO } from "date-fns";
import { DataRoomTableParticipant } from "models";

interface DataRoomTableUploadingProps {
  currentUserEmail?: string;
  participants: DataRoomTableParticipant[];
  isLoading: boolean;
  onUpload: () => void;
  onDelete: () => void;
}

const DataRoomTableActions: React.FC<DataRoomTableUploadingProps> = ({
  participants,
  currentUserEmail,
  isLoading,
  onDelete,
  onUpload,
}) => {
  const currentUserStatus = participants.find(
    ({ userEmail }) => userEmail === currentUserEmail
  );
  if (!currentUserStatus) {
    return <></>;
  }
  const hasUploadedData = currentUserStatus.uploadedAt;
  const formattedDate =
    hasUploadedData && isValid(parseISO(currentUserStatus.uploadedAt || ""))
      ? format(parseISO(currentUserStatus.uploadedAt || ""), "MMM d, HH:mm")
      : null;
  return (
    <Box style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
      {isLoading ? (
        <CircularProgress size={16} color="inherit" />
      ) : (
        <>
          {hasUploadedData ? (
            <Tooltip title={currentUserStatus.uploadedAt || ""}>
              <Typography variant="caption">
                {`You uploaded data on: ${formattedDate}`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="caption">
              You haven't uploaded data yet
            </Typography>
          )}
          <Button
            size="small"
            color="inherit"
            style={{ marginLeft: "16px" }}
            onClick={hasUploadedData ? onDelete : onUpload}
            startIcon={
              <FontAwesomeIcon
                fixedWidth
                icon={hasUploadedData ? faTrash : faUpload}
              />
            }
          >
            {hasUploadedData ? "Delete data" : "Upload"}
          </Button>
        </>
      )}
    </Box>
  );
};

export default DataRoomTableActions;
