// @ts-nocheck
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";

const useLabelStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    lineHeight: 1,
    border: "1px solid",
    padding: "0.111em 0.222em",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontSize: "0.5625em",
    top: "-0.111em",
    position: "relative",
    borderRadius: "2px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    "&:not(:only-child)": {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const Label = memo(({ label }) => {
  const labelClasses = useLabelStyles();
  const { wrapper } = labelClasses;
  return <div className={wrapper}>{label}</div>;
});

export default Label;
