import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_SHARE_EVERYTHING } from "./dataRoomTableShareEverything";

export const DATA_ROOM_TABLE_EVERYTHING = gql`
  fragment DataRoomTableEverything on DataRoomTable {
    id
    dataRoomTableId
    dataRoomId
    name
    index
    sqlCreateStatement
    dataProviders
    updatedAt
    createdAt
    ownerEmail
    dataRoom {
      id
    }
    dataRoomTableShares {
      totalCount
      nodes {
        ...DataRoomTableShareEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_SHARE_EVERYTHING}
`;
