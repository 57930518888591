import { DataRoomTableColumnDefinition, DataRoomTableDefinition } from "models";
import * as yup from "yup";

export const dataRoomTableColumnValidationSchema: yup.SchemaOf<DataRoomTableColumnDefinition> =
  yup
    .object()
    .noUnknown()
    .shape({
      name: yup
        .string()
        .required("Please type a column name")
        .test(
          "is-column-name-unique",
          ({ value }) => `Column name ${value} already exists`,
          (value, test) => {
            const isUnique = !(test.options.context?.columnNames as string[])
              .map((n) => n.toLowerCase())
              .includes((value as string).toLowerCase());
            return isUnique;
          }
        ),
      primitiveType: yup.string().required("Please select a datatype"),
      nullable: yup.boolean(),
    })
    .defined();

export const dataRoomTableValidationSchema: yup.SchemaOf<DataRoomTableDefinition> =
  yup
    .object()
    .noUnknown()
    .shape({
      name: yup.string().required("Table name is required"),
      columns: yup.array().required(),
    })
    .defined();
