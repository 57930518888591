// @ts-nocheck
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Portal } from "@material-ui/core";
import { useBoolean } from "ahooks";
import { useSnackbar } from "notistack";
import { memo } from "react";
import { useNavigate } from "react-router";
import { Actions, DataRoomCreateDialog } from "components";
import { CREATE_DATA_ROOM } from "gqls";

enum DataRoomsActionsType {
  Create,
}

const MyDataRoomsActions = memo(
  ({ inline, actions: actionsFunc = (actions: any) => actions }) => {
    const { user = {} } = useAuth0();
    const navigate = useNavigate();
    const { email: currentUserEmail } = user || {};
    const { enqueueSnackbar } = useSnackbar();
    const [createDataRoomMutation, { loading: isCreateDataRoomLoading }] =
      useMutation(CREATE_DATA_ROOM, {
        onCompleted: ({ createDataRoom }) => {
          const dataRoomId = createDataRoom?.dataRoom?.dataRoomId;
          closeDataRoomCreateDialog();
          enqueueSnackbar("New data room created");
          if (dataRoomId) {
            navigate(`/datarooms/${dataRoomId}`);
          }
        },
        onError: () => {
          closeDataRoomCreateDialog();
          enqueueSnackbar("Can't create new data room", { variant: "error" });
        },
        refetchQueries: ["dataRooms"],
      });
    const createDataRoom = ({
      name = "",
      description = "",
      configuration = {},
    }) => {
      const {
        dataRoomShares = [],
        dataRoomTables = [],
        dataRoomQueries = [],
      } = configuration || {};
      const dataRoom = {
        name: name.trim(),
        description: description.trim(),
        mrenclave: "",
        dataRoomShares: {
          create: [
            {
              userEmail: currentUserEmail,
            },
          ].concat(
            dataRoomShares
              ?.filter(({ userEmail }) => userEmail.trim() !== currentUserEmail)
              .map(({ userEmail }) => ({ userEmail: userEmail.trim() })) || []
          ),
        },
        dataRoomTables: {
          create: dataRoomTables?.map(
            ({
              name = "",
              sqlCreateStatement = "",
              dataRoomTableShares = [],
              index = 0,
            }) => ({
              name: name.trim() || "",
              index: index || 0,
              sqlCreateStatement: sqlCreateStatement.trim() || "",
              dataRoomTableShares: {
                create: dataRoomTableShares?.map(({ userEmail }) => ({
                  userEmail: userEmail.trim(),
                })),
              },
            })
          ),
        },
        dataRoomQueries: {
          create: dataRoomQueries?.map(
            ({
              name = "",
              sqlSelectStatement = "",
              dataRoomQueryShares = [],
              index = 0,
            }) => ({
              name: name.trim() || "",
              index: index || 0,
              sqlSelectStatement: sqlSelectStatement.trim() || "",
              dataRoomQueryShares: {
                create: dataRoomQueryShares?.map(({ userEmail }) => ({
                  userEmail: userEmail.trim(),
                })),
              },
            })
          ),
        },
      };
      // TODO: validate dataRoom
      createDataRoomMutation({
        variables: {
          input: {
            dataRoom,
          },
        },
      });
    };
    const createDataRoomPredefined = createDataRoom.bind(this, {
      name: "Untitled",
    });
    // Actions: New data room
    const [
      isDataRoomCreateDialogOpen,
      {
        setTrue: openDataRoomCreateDialog,
        setFalse: closeDataRoomCreateDialog,
      },
    ] = useBoolean(false);
    // Actions
    const actions = actionsFunc({
      buttons: [
        {
          isEnabled: true,
          states: {
            normal: {
              icon: faPlus,
              name: "New data room...",
              tooltipTitle: "New data room...",
              handle: openDataRoomCreateDialog,
              tooltipPlacement: "top",
              square: true,
              circle: false,
              inline: true,
            },
            onShift: {
              name: "New data room",
              tooltipTitle: "New data room",
              handle: createDataRoomPredefined,
            },
          },
          type: DataRoomsActionsType.Create,
        },
      ],
    });
    const actionsTypes = [actions.buttons || [], actions.menuLists || []]
      .flat(Infinity)
      .map(({ type }) => type);
    return (
      <Actions actions={actions} inline={inline}>
        <Portal>
          {actionsTypes.includes(DataRoomsActionsType.Create) && (
            <DataRoomCreateDialog
              open={isDataRoomCreateDialogOpen}
              loading={isCreateDataRoomLoading}
              onCancel={closeDataRoomCreateDialog}
              onConfirm={createDataRoom}
            />
          )}
        </Portal>
      </Actions>
    );
  }
);

export default MyDataRoomsActions;
