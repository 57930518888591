import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  FetchResult,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { GraphQLError, print } from "graphql";
import { Client, ClientOptions, createClient } from "graphql-ws";
import { memo } from "react";
import { useConfiguration } from "contexts";

class NetworkError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = "NetworkError";
  }
}

class WebSocketLink extends ApolloLink {
  private client: Client;
  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }
  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (error) => {
            if (error instanceof Error) {
              return sink.error(error);
            }
            if (error instanceof CloseEvent) {
              return sink.error(
                new NetworkError(
                  `Socket closed with event ${error.code}` + error.reason
                    ? `: ${error.reason}`
                    : ""
                )
              );
            }
            return sink.error(
              new Error(
                (error as GraphQLError[])
                  .map(({ message }) => message)
                  .join(", ")
              )
            );
          },
        }
      );
    });
  }
}

const ApolloWrapper = memo(({ children }: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    configuration: { graphqlUrl, websocketUrl },
  } = useConfiguration();
  const httpLink = new HttpLink({
    uri: graphqlUrl?.replace(/\/$/, ""),
    credentials: "include",
  });
  const webSocketLink = new WebSocketLink({
    url: websocketUrl?.replace(/\/$/, ""),
    connectionParams: async () => {
      const accessToken = await getAccessTokenSilently();
      return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    },
  });
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    webSocketLink,
    httpLink
  );
  const authLink = setContext(async (_, { headers, ...context }) => {
    const accessToken = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
      ...context,
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
});

export default ApolloWrapper;
