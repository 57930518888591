// @ts-nocheck
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Theme } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const useDataFilterStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "inline-flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    backgroundColor: alpha(theme.palette.common.black, 0.025),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  filterIcon: {
    width: theme.spacing(4),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const DataFilter = ({ condition, onChange }) => {
  const dataFilterClasses = useDataFilterStyles();
  return !loading && data ? (
    <Box className={dataFilterClasses.wrapper}>
      <Box className={dataFilterClasses.filterIcon}>
        <FontAwesomeIcon fixedWidth icon={faFilter} />
      </Box>
      {/* Add DataFilterConfitions */}
    </Box>
  ) : null;
};

export default DataFilter;
