import {
  faChevronDown,
  faChevronRight,
  faPencil,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import { grey, orange, red } from "@material-ui/core/colors";
import { DataRoomTable, DataRoomTableParticipant } from "models";
import React, { useCallback } from "react";
import { DataRoomParticipantAvatar } from "components";

interface DataRoomTableTileProps {
  index: number;
  table: DataRoomTable;
  withStatus: boolean;
  withUploading: boolean;
  readonly: boolean;
  allowEditing: boolean;
  isCollapsed: boolean;
  onEdit: (index: number) => void;
  onDelete: (table: DataRoomTable) => void;
  onToggleCollapsion: (index: number) => void;
  renderDataUploading?: (table: DataRoomTable) => React.ReactNode;
}

const DataRoomTableTile: React.FC<DataRoomTableTileProps> = ({
  index,
  table,
  withStatus,
  withUploading,
  readonly,
  allowEditing,
  isCollapsed,
  onDelete,
  onEdit,
  onToggleCollapsion,
  renderDataUploading,
}) => {
  const dataUploadingStatus = useCallback(
    (participants: DataRoomTableParticipant[]) =>
      `${participants.filter((p) => p.uploadedAt).length}/${
        participants.length
      }`,
    []
  );
  return (
    <ListItem>
      <Grid container spacing={1}>
        <Grid item container xs={6} alignItems="center">
          <IconButton
            size="small"
            disabled={!allowEditing}
            onClick={() => onToggleCollapsion(index)}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={isCollapsed ? faChevronRight : faChevronDown}
            ></FontAwesomeIcon>
          </IconButton>
          <Typography variant="h5">{table.name}</Typography>
          <Box style={{ display: "flex" }}>
            {table.participants.map((p, i) => (
              <DataRoomParticipantAvatar
                key={i}
                name={p.userEmail}
                active={!!p.uploadedAt && withStatus}
              />
            ))}
          </Box>
          {withStatus && (
            <Typography variant="caption">
              {dataUploadingStatus(table.participants)}
            </Typography>
          )}
          <Typography
            variant="caption"
            style={{ marginLeft: "16px", color: grey["400"] }}
          >
            Number of columns: {table.columns.length}
            {table.columns.length === 0 && (
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                fixedWidth
                color={orange[400]}
              ></FontAwesomeIcon>
            )}
          </Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          {!readonly && (
            <>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={() => {
                  onDelete(table);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth
                  color={red[200]}
                ></FontAwesomeIcon>
              </IconButton>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={() => onEdit(index)}
              >
                <FontAwesomeIcon fixedWidth icon={faPencil}></FontAwesomeIcon>
              </IconButton>
            </>
          )}
          {withUploading && renderDataUploading
            ? renderDataUploading(table)
            : null}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default DataRoomTableTile;
