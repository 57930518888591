import { faBell, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Drawer, IconButton, Typography } from "@material-ui/core";
import { useBoolean } from "ahooks";
import { useCustomDrawerStyles } from "styles/select";

const NotificationsDrawer = ({
  children,
  open,
  onClose,
  notificationsTotalCount,
}: any) => {
  const customDrawerClasses = useCustomDrawerStyles();
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "25%",
          minWidth: 480,
        },
      }}
    >
      <Box className={customDrawerClasses.dialogContainer}>
        <Box className={customDrawerClasses.dialogTitle}>
          <Typography variant="h6" component="div">
            <strong>Notifications</strong>{" "}
            {notificationsTotalCount > 0 && (
              <sup style={{ lineHeight: 0 }}>
                <small>
                  <small>{notificationsTotalCount}</small>
                </small>
              </sup>
            )}
          </Typography>
          <IconButton color="inherit" onClick={onClose}>
            <FontAwesomeIcon fixedWidth icon={faTimes} />
          </IconButton>
        </Box>
        <Box
          sx={{
            ml: 3,
            mr: 3,
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

const NotificationsDrawerWithIcon = ({ children }: any) => {
  const [
    isNotificationsDrawerOpen,
    { setTrue: openNotificationsDrawer, setFalse: closeNotificationsDrawer },
  ] = useBoolean(false);
  const notificationsTotalCount = 0;
  return (
    <>
      <IconButton
        color="inherit"
        onClick={openNotificationsDrawer}
        disabled={notificationsTotalCount === 0}
      >
        <Badge color="error" badgeContent={notificationsTotalCount}>
          {notificationsTotalCount > 0 ? (
            <FontAwesomeIcon fixedWidth icon={faBellSolid} />
          ) : (
            <FontAwesomeIcon fixedWidth icon={faBell} />
          )}
        </Badge>
      </IconButton>
      <NotificationsDrawer
        open={isNotificationsDrawerOpen}
        onClose={closeNotificationsDrawer}
      >
        {children}
      </NotificationsDrawer>
    </>
  );
};

export default NotificationsDrawerWithIcon;
