import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_EVERYTHING } from "gqls";

export const CREATE_DATA_ROOM_TABLE = gql`
  mutation createDataRoomTable($input: CreateDataRoomTableInput!) {
    createDataRoomTable(input: $input) {
      clientMutationId
      dataRoomTable {
        ...DataRoomTableEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_EVERYTHING}
`;
