export interface DataRoomTableDefinition {
  name: string;
  columns: DataRoomTableColumnDefinition[];
}

export interface DataRoomTableColumnDefinition {
  name: string;
  primitiveType: DataRoomTablePrimitiveType | undefined;
  nullable: boolean;
}

export enum DataRoomTablePrimitiveType {
  INT = "INT",
  VARCHAR = "VARCHAR",
  FLOAT = "FLOAT",
}

export interface DataRoomTable extends DataRoomTableDefinition {
  dataRoomTableId: string;
  sqlCreateStatement?: string;
  ownerEmail?: string;
  participants: DataRoomTableParticipant[];
}

export interface DataRoomTableParticipant {
  userEmail: string;
  uploadedAt: string | undefined;
}

export interface DataRoomQueryDefinition {
  name: string;
  sqlSelectStatement: string;
}

export interface DataRoomQuery extends DataRoomQueryDefinition {
  dataRoomQueryId: string;
  ownerEmail?: string;
  participants: DataRoomQueryParticipant[];
  isSaving?: boolean;
  updatedAt?: string;
}

export interface DataRoomQueryResult {
  dataRoomQueryId: string;
  lastRunAt: string;
  result?: string;
  isLoading?: boolean;
}

export interface DataRoomQueryParticipant {
  userEmail: string;
}

export interface DataRoomParticipantDefinition {
  userEmail: string;
  tables: string[];
  queries: string[];
}

export interface DataRoomParticipant extends DataRoomParticipantDefinition {}
