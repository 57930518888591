// @ts-nocheck
import { useQuery } from "@apollo/client";
import {
  faEllipsisV,
  // faPen as faPenRegular,
} from "@fortawesome/pro-regular-svg-icons";
import { faLockKeyhole as faLockKeyholeSolid } from "@fortawesome/pro-solid-svg-icons";
import {
  AppBar,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Theme,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  DataRoomActions,
  Id,
  Label,
  LabelIcon,
  NavigationDrawerOpenButton,
} from "components";
import { DataRoomDescriptionEditor, DataRoomNameEditor } from "containers";
import { DATA_ROOM } from "gqls";

const SHOW_PARTICIPANTS_IN_MAIN_BAR = false;

export const useToolbarStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    justifyContent: "space-between",
    margin: theme.spacing(1),
    minHeight: "32px",
  },
  menuLeftWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  nameEditorWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: "22px",
    lineHeight: "24px",
    "&:not(:first-child)": {
      marginLeft: theme.spacing(0.5),
    },
    "&:not(:last-child)": {
      marginRight: theme.spacing(0.25),
    },
  },
}));

const useButtonStyles2 = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 0.25),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
    marginRight: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  startIcon: {
    marginLeft: 0,
  },
}));

const useAvatarGroupStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  avatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    fontSize: "0.625rem",
    fontWeight: 600,
    color: theme.palette.text.primary,
    "&:first-child": {
      background: "transparent",
      marginLeft: `${theme.spacing(-0.25)} !important`,
      fontSize: "0.875em",
      fontWeight: 500,
      border: "none",
    },
  },
}));

const Participants = () => {
  const buttonClasses2 = useButtonStyles2();
  const avatarGroupClasses = useAvatarGroupStyles();
  return (
    <Button classes={buttonClasses2} color="inherit">
      <AvatarGroup classes={avatarGroupClasses} max={4} spacing="small">
        <Tooltip
          title={
            <div>
              <div>Remy Sharp</div>
              <div style={{ opacity: 0.75 }}>remy.sharp@apple.com</div>
              <div style={{ fontWeight: 400 }}>Owner, Auditor</div>
            </div>
          }
          tooltipPlacement="bottom"
        >
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg">
            RS
          </Avatar>
        </Tooltip>
        <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg">
          TH
        </Avatar>
        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg">
          CB
        </Avatar>
        <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg">
          AW
        </Avatar>
        <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg">
          TH
        </Avatar>
      </AvatarGroup>
    </Button>
  );
};

const Mainbar = () => {
  const toolbarClasses = useToolbarStyles();
  const { toolbar, menuLeftWrapper, nameEditorWrapper } = toolbarClasses;
  const { enqueueSnackbar } = useSnackbar();
  const { dataRoomId } = useParams();
  const hasDataRoomId = !!dataRoomId;
  const { data } = useQuery(DATA_ROOM, {
    skip: !hasDataRoomId,
    variables: {
      dataRoomId,
    },
    onError: (error) => {
      enqueueSnackbar("Can't fetch data room from the database", {
        variant: "error",
      });
    },
  });
  const { dataRoom } = data || {};
  const {
    id,
    name = "",
    description = "",
    isPublished = false,
  } = dataRoom || {};
  const isDataRoomFetched = !!dataRoom;
  return (
    <AppBar color="transparent" position="static" elevation={0}>
      <Box>
        <Toolbar disableGutters className={toolbar}>
          <div className={menuLeftWrapper}>
            <NavigationDrawerOpenButton />
            {isDataRoomFetched && (
              <div className={nameEditorWrapper}>
                <DataRoomNameEditor
                  dataRoomId={dataRoomId}
                  name={name}
                  endAdornment={
                    <Fragment>
                      {isPublished ? (
                        <LabelIcon icon={faLockKeyholeSolid} />
                      ) : (
                        <Label label="Draft" />
                      )}
                      <Id id={dataRoomId} href={`/datarooms/${dataRoomId}`} />
                    </Fragment>
                  }
                />
              </div>
            )}
          </div>
          <Box>
            {isDataRoomFetched ? (
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {SHOW_PARTICIPANTS_IN_MAIN_BAR && <Participants />}
                <DataRoomActions
                  id={id}
                  dataRoomId={dataRoomId}
                  dataRoom={dataRoom}
                  moreTooltipTitle="More"
                  moreTooltipPlacement="bottom-end"
                  moreIcon={faEllipsisV}
                />
              </Box>
            ) : null}
          </Box>
        </Toolbar>
        {isDataRoomFetched ? (
          <Box
            sx={{
              padding: "0.25rem 0.25rem 0.25rem 3rem",
              fontSize: "0.875rem",
              lineHeight: "1.375rem",
            }}
          >
            <DataRoomDescriptionEditor
              dataRoomId={dataRoomId}
              description={description}
            />
          </Box>
        ) : null}
      </Box>
    </AppBar>
  );
};

export default Mainbar;
