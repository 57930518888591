import { Container, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ReactNode } from "react";
import { Mainbar, NavigationDrawer } from "components";

const useMainContainerStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
  },
}));

const useCenterContainerStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: "1 1 0",
    minWidth: 0,
  },
  content: {
    maxHeight: "calc(100vh - 82px)",
    minHeight: "calc(100vh - 82px)",
    overflowY: "auto",
  },
}));

interface MainProps {
  children: ReactNode;
  scrollable?: boolean;
}

const Main: React.FC<MainProps> = ({ children, scrollable }) => {
  const mainContainerClasses = useMainContainerStyles();
  const centerContainerClasses = useCenterContainerStyles();
  return (
    <Container maxWidth={false} disableGutters classes={mainContainerClasses}>
      <NavigationDrawer />
      <Container
        maxWidth={false}
        disableGutters
        classes={centerContainerClasses}
      >
        <Mainbar />
        <Container
          maxWidth={false}
          disableGutters
          className={scrollable ? centerContainerClasses.content : undefined}
        >
          {children}
        </Container>
      </Container>
    </Container>
  );
};

export default Main;
