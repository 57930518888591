import { gql } from "@apollo/client";
import { DATA_ROOM_SHARE_EVERYTHING } from "gqls";

export const UPSERT_DATA_ROOM_SHARE = gql`
  mutation upsertDataRoomShare($input: UpsertDataRoomShareInput!) {
    upsertDataRoomShare(input: $input) {
      clientMutationId
      dataRoomShare {
        ...DataRoomShareEverything
      }
    }
  }
  ${DATA_ROOM_SHARE_EVERYTHING}
`;
