import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { InlineEditor } from "components";
import { InlineEditorProps } from "components/base/InlineEditor/InlineEditor";
import { UPDATE_DATA_ROOM } from "gqls";

interface DataRoomDescriptionEditorProps extends Partial<InlineEditorProps> {
  dataRoomId?: string;
  description: string;
}

const DataRoomDescriptionEditor: React.FC<DataRoomDescriptionEditorProps> = ({
  dataRoomId,
  description,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [updateDataRoomMutation] = useMutation(UPDATE_DATA_ROOM, {
    onError: (error) => {
      enqueueSnackbar("Can't update data room", { variant: "error" });
    },
    refetchQueries: ["dataRooms"],
  });
  const updateDataRoomDescription = (description: any) => {
    updateDataRoomMutation({
      variables: {
        input: {
          dataRoomId,
          patch: {
            description,
          },
        },
      },
    });
  };
  return (
    <InlineEditor
      value={description}
      placeholder="Description"
      onChange={updateDataRoomDescription}
      roundedWrapper={false}
      {...props}
    />
  );
};

export default DataRoomDescriptionEditor;
