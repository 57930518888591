import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { NormalizedStateHookOptions, useNormalizedState } from "hooks";
import { DataRoomQuery, DataRoomQueryResult } from "models";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  DataRoomQueryConstructor,
  DataRoomQueryConstructorMode,
} from "components";
import DataRoomQueryActions from "components/entities/dataRoomQuery/DataRoomQueryConstructor/DataRoomQueryActions";
import DataRoomQueryResultPane from "components/entities/dataRoomQuery/DataRoomQueryConstructor/DataRoomQueryResultPane";
import {
  CREATE_DATA_ROOM_QUERY,
  DATA_ROOM_QUERIES,
  DELETE_DATA_ROOM_QUERY,
  UPDATE_DATA_ROOM_QUERY,
} from "gqls";

interface DataRoomQueriesProps {
  mode: DataRoomQueryConstructorMode;
  onRunQuery?: (queryName: string) => Promise<string>;
}

const closeSnackbarAction = (close: any) => (key: any) =>
  (
    <IconButton onClick={() => close(key)}>
      <FontAwesomeIcon
        fixedWidth
        icon={faTimes}
        color="white"
      ></FontAwesomeIcon>
    </IconButton>
  );

const DataRoomQueries: React.FC<DataRoomQueriesProps> = ({
  mode,
  onRunQuery,
}) => {
  const { user } = useAuth0();
  const { dataRoomId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data } = useQuery(DATA_ROOM_QUERIES, {
    variables: {
      filter: { dataRoomId: { equalTo: dataRoomId } },
      orderBy: ["INDEX_ASC"],
    },
    onError: () => {
      enqueueSnackbar("Can't fetch data room queries", { variant: "error" });
    },
  });
  const [createDataRoomQueryMutation] = useMutation(CREATE_DATA_ROOM_QUERY, {
    onError: () => {
      enqueueSnackbar("Can't create data room query", { variant: "error" });
    },
  });
  const [updateDataRoomQueryMutation] = useMutation(UPDATE_DATA_ROOM_QUERY, {
    onError: () => {
      enqueueSnackbar("Can't update data room query", { variant: "error" });
    },
  });
  const [deleteDataRoomQueryMutation] = useMutation(DELETE_DATA_ROOM_QUERY, {
    onError: () => {
      enqueueSnackbar("Can't delete data room query", { variant: "error" });
    },
  });
  const onCreate = ({ name }: any) => {
    createDataRoomQueryMutation({
      variables: {
        input: {
          dataRoomQuery: {
            dataRoomId,
            name,
            sqlSelectStatement: "",
          },
        },
      },
      refetchQueries: ["dataRoom", "dataRoomQueries"],
    });
  };
  const onUpdate = useCallback(
    ({ dataRoomQueryId, name, sqlSelectStatement }: any) => {
      updateDataRoomQueryMutation({
        variables: {
          input: {
            dataRoomQueryId,
            patch: {
              name,
              sqlSelectStatement,
            },
          },
        },
      });
    },
    [updateDataRoomQueryMutation]
  );
  const resultsOptions = useMemo<
    NormalizedStateHookOptions<DataRoomQueryResult>
  >(
    () => ({
      initialValues: [],
      id: (queryResult: DataRoomQueryResult) => queryResult.dataRoomQueryId,
    }),
    []
  );
  const {
    byId: results,
    addOrUpdate: addOrUpdateResult,
    update: updateResult,
  } = useNormalizedState<DataRoomQueryResult>(resultsOptions);
  const handleSqlSelectStatementUpdate = useCallback(
    (dataRoomQueryId: string, sqlSelectStatement: string) => {
      onUpdate({
        dataRoomQueryId,
        sqlSelectStatement,
        isSaving: true,
      });
      setTimeout(() => {
        onUpdate({
          dataRoomQueryId,
          isSaving: false,
          updatedAt: new Date().toISOString(),
        });
      }, 1000);
    },
    [onUpdate]
  );
  const onDelete = ({ dataRoomQueryId }: any) => {
    deleteDataRoomQueryMutation({
      variables: {
        input: {
          dataRoomQueryId,
        },
      },
      refetchQueries: ["dataRoom", "dataRoomQueries"],
    });
  };
  const handleQueryRun = async (query: DataRoomQuery) => {
    try {
      const now = new Date().toISOString();
      addOrUpdateResult({
        dataRoomQueryId: query.dataRoomQueryId,
        isLoading: true,
        lastRunAt: now,
      });
      const result = await onRunQuery?.(query.name);
      updateResult({
        dataRoomQueryId: query.dataRoomQueryId,
        isLoading: false,
        result,
      });
      enqueueSnackbar("Query run successfully");
    } catch (error) {
      enqueueSnackbar(`Can't run query data: ${error.message}`, {
        variant: "error",
        persist: true,
        action: closeSnackbarAction(closeSnackbar),
      });
      updateResult({
        dataRoomQueryId: query.dataRoomQueryId,
        isLoading: false,
      });
    }
  };
  return data ? (
    <DataRoomQueryConstructor
      mode={mode}
      queries={
        data?.dataRoomQueries?.nodes
          .map((query: any) => ({
            ...query,
            participants:
              query.dataRoomQueryShares?.nodes?.map(({ userEmail }: any) => ({
                userEmail,
              })) || [],
          }))
          .filter((q: DataRoomQuery) =>
            mode === DataRoomQueryConstructorMode.ACTION
              ? !!q.participants.find((p) => p.userEmail === user?.email)
              : true
          ) || []
      }
      onCreate={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
      // TODO: These do not belong
      onSqlSelectStatementUpdate={handleSqlSelectStatementUpdate}
      renderActions={(query, expand) => (
        <DataRoomQueryActions
          isLoading={!!results[query.dataRoomQueryId]?.isLoading}
          lastRunAt={results[query.dataRoomQueryId]?.lastRunAt}
          onRun={() => {
            handleQueryRun(query);
            expand?.();
          }}
        />
      )}
      renderResult={(query) => (
        <DataRoomQueryResultPane
          dataRoomQueryId={query.dataRoomQueryId}
          isLoading={!!results[query.dataRoomQueryId]?.isLoading}
          hasRunQuery={!!results[query.dataRoomQueryId]?.lastRunAt}
          result={results[query.dataRoomQueryId]?.result}
        />
      )}
    />
  ) : null;
};

export default DataRoomQueries;
