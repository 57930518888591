// @ts-nocheck
import { DEFAULT_REFERER } from "constants/index";
import { useAuth0 } from "@auth0/auth0-react";
import { faSignIn } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Container,
  Link,
  Paper,
  Portal,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { parse } from "query-string";
import { Navigate, useLocation } from "react-router-dom";
import { ReactComponent as DecentriqLogo } from "assets/logos/decentriq-platform-symbol-square.svg";
import { UnauthorizedDialog } from "components";

const Copyright = () => {
  return (
    <Typography
      component={Box}
      variant="body2"
      color="textSecondary"
      align="center"
      sx={{ mt: 4 }}
    >
      {"Copyright © 2021 "}
      <Link color="inherit" href="https://decentriq.com/">
        Decentriq
      </Link>
      {". All rights reserved."}
    </Typography>
  );
};

const useSignInPageStyles = makeStyles((theme) => ({
  outerWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    height: "14rem",
    marginBottom: theme.spacing(2),
    strokeWidth: 1,
  },
  signInWithButton: {
    margin: theme.spacing(2, 0, 0),
  },
}));

const SignInPage = (props) => {
  const signInPageClasses = useSignInPageStyles();
  const { outerWrapper, wrapper, logo, signInWithButton } = signInPageClasses;
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const signIn = () =>
    loginWithRedirect({
      appState: { referer: location || DEFAULT_REFERER },
    });
  const referer = props.location?.state?.referer || DEFAULT_REFERER;
  const location = useLocation();
  const searchParams = parse(location.search);
  const { error } = searchParams;
  const [isUnauthorizedDialogOpen, { setFalse: closeUnauthorizedDialog }] =
    useBoolean(error === "unauthorized");
  return isAuthenticated ? (
    <Navigate to={referer} />
  ) : (
    <div className={outerWrapper}>
      <Container maxWidth="xs">
        <Paper elevation={0}>
          <Box sx={{ p: 4 }}>
            <div className={wrapper}>
              <DecentriqLogo className={logo} />
            </div>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="outlined"
              color="inherit"
              className={signInWithButton}
              onClick={signIn}
              startIcon={<FontAwesomeIcon icon={faSignIn} />}
            >
              Sign in
            </Button>
            <Copyright />
          </Box>
        </Paper>
      </Container>
      <Portal>
        <UnauthorizedDialog
          open={isUnauthorizedDialogOpen}
          onClose={closeUnauthorizedDialog}
        />
      </Portal>
    </div>
  );
};

export default SignInPage;
