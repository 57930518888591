// @ts-nocheck
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
  useMediaQuery,
} from "@material-ui/core";
import { memo, useEffect, useMemo } from "react";
import { createDecentriqTheme } from "themes";
import { useMain } from "contexts";

const StylesWrapper = memo(({ children }: any) => {
  const prefersColorSchemeDark = useMediaQuery("(prefers-color-scheme: dark)");
  const { isDarkThemeEnabled, setPrefersColorSchemeDark } = useMain();
  useEffect(() => {
    setPrefersColorSchemeDark(isDarkThemeEnabled && prefersColorSchemeDark);
  }, [setPrefersColorSchemeDark, isDarkThemeEnabled, prefersColorSchemeDark]);
  const styles = {
    body: { backgroundColor: "#e0dfda" },
    ".svg-inline--fa.fa-fw": { width: "1em" },
    ":focus": { outline: "none" },
  };
  const theme = useMemo(
    () =>
      createDecentriqTheme({
        mode: isDarkThemeEnabled && prefersColorSchemeDark ? "dark" : "light",
      }),
    [isDarkThemeEnabled, prefersColorSchemeDark]
  );
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <GlobalStyles styles={styles} />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
});

export default StylesWrapper;
