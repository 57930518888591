import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  alpha,
  Button,
  ClickAwayListener,
  InputBase,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { memo, ReactNode, useCallback, useEffect, useState } from "react";

const useInputBaseStyles = makeStyles({
  root: {
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
  },
  input: {
    height: "auto",
    padding: 0,
    lineHeight: "inherit",
  },
});

interface CustomButtonStylesParams {
  roundedButtons?: boolean;
}

const useCustomButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: "auto",
    padding: theme.spacing(0.5),
    borderRadius: ({ roundedButtons }: CustomButtonStylesParams) =>
      roundedButtons ? theme.shape.borderRadius : 0,
  },
}));

interface InlineEditorStylesParams {
  isEditing?: boolean;
  roundedWrapper?: boolean;
  isReadOnly?: boolean;
}

const useInlineEditorStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    display: "flex",
    alignItems: ({ isEditing }: InlineEditorStylesParams) =>
      isEditing ? "center" : "baseline",
    borderRadius: ({ roundedWrapper }: InlineEditorStylesParams) =>
      roundedWrapper ? theme.shape.borderRadius : 0,
    padding: theme.spacing(0.5),
    margin: theme.spacing(-0.5),
    transition: "all 0.2s linear",
    backgroundColor: ({ isEditing }: InlineEditorStylesParams) =>
      isEditing ? alpha(theme.palette.common.black, 0.025) : "transparent",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
  content: {
    fontWeight: "inherit",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: ({ isReadOnly }: InlineEditorStylesParams) =>
      isReadOnly ? "pointer" : "text",
  },
  contentInput: {
    flex: 1,
  },
  contentPlaceholder: {
    color: "#c3c3c3",
  },
  tooltipHotkey: {
    opacity: 0.75,
  },
  startAdornmentWrapper: {
    display: "flex",
    alignItems: "baseline",
    "&:not(:empty)": {
      marginRight: theme.spacing(0.5),
    },
  },
  endAdornmentWrapper: {
    display: "flex",
    alignItems: "baseline",
    "&:not(:empty)": {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

interface BaseInlineEditorProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  roundedWrapper?: boolean;
  roundedButtons?: boolean;
  endEditing?: () => void;
}

const InlineEditor: React.FC<BaseInlineEditorProps> = memo(
  ({
    value,
    onChange,
    roundedWrapper = true,
    roundedButtons = true,
    endEditing,
  }) => {
    const [innerValue, setInnerValue] = useState(value);
    const inputBaseClasses = useInputBaseStyles();
    const customButtonClasses = useCustomButtonStyles({
      roundedButtons,
    });
    const inlineEditorClasses = useInlineEditorStyles({
      roundedWrapper,
      isEditing: true,
      isReadOnly: false,
    });
    const { contentWrapper, contentInput, tooltipHotkey } = inlineEditorClasses;
    const onContentInputChange = useCallback((event) => {
      setInnerValue(event.target.value);
    }, []);
    const onContentInputKeyDown = useCallback(
      (event) => {
        event.stopPropagation();
        if (event.key === "Enter") {
          if (innerValue !== value) onChange(innerValue);
          endEditing?.();
        } else if (event.key === "Escape") {
          setInnerValue(value);
          endEditing?.();
        }
      },
      [endEditing, innerValue, onChange, value]
    );
    const onOkayClick = useCallback(
      (event) => {
        event.stopPropagation();
        if (innerValue !== value) onChange(innerValue);
        endEditing?.();
      },
      [endEditing, innerValue, onChange, value]
    );
    const onCancelClick = useCallback(
      (event) => {
        event.stopPropagation();
        setInnerValue(value);
        endEditing?.();
      },
      [endEditing, value]
    );
    useEffect(() => setInnerValue(value), [value]);
    return (
      <ClickAwayListener onClickAway={onCancelClick}>
        <div className={contentWrapper}>
          <InputBase
            classes={inputBaseClasses}
            className={contentInput}
            placeholder={value}
            value={innerValue}
            onChange={onContentInputChange}
            onKeyDown={onContentInputKeyDown}
            autoFocus
          />
          <Tooltip
            title={
              <div>
                <div>Save</div>
                <div className={tooltipHotkey}>Return</div>
              </div>
            }
            placement="bottom-end"
            disableFocusListener
            disableTouchListener
          >
            <Button
              color="inherit"
              classes={customButtonClasses}
              onClick={onOkayClick}
            >
              <FontAwesomeIcon fixedWidth icon={faCheck} />
            </Button>
          </Tooltip>
          <Tooltip
            title={
              <div>
                <div>Cancel</div>
                <div className={tooltipHotkey}>Esc</div>
              </div>
            }
            placement="bottom-end"
            disableFocusListener
            disableTouchListener
          >
            <Button
              color="inherit"
              classes={customButtonClasses}
              onClick={onCancelClick}
            >
              <FontAwesomeIcon fixedWidth icon={faTimes} />
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }
);

export interface InlineEditorProps extends BaseInlineEditorProps {
  readOnly?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

const InlineEditorWrapper: React.FC<InlineEditorProps> = memo(
  ({
    value,
    placeholder,
    readOnly,
    onChange,
    roundedWrapper = true,
    roundedButtons = true,
    startAdornment,
    endAdornment,
  }) => {
    const isReadOnly = readOnly || !(typeof onChange === "function");
    const [isEditing, { setTrue: startEditing, setFalse: endEditing }] =
      useBoolean(false);
    const inlineEditorClasses = useInlineEditorStyles({
      roundedWrapper,
      isEditing,
      isReadOnly,
    });
    const {
      contentWrapper,
      content,
      contentPlaceholder,
      startAdornmentWrapper,
      endAdornmentWrapper,
    } = inlineEditorClasses;
    const onContentClick = useCallback(
      (event) => {
        event.stopPropagation();
        startEditing();
      },
      [startEditing]
    );
    const hasValue = value?.trim();
    return isReadOnly || !isEditing ? (
      <div className={contentWrapper}>
        {startAdornment ? (
          <div className={startAdornmentWrapper}>{startAdornment}</div>
        ) : null}
        <div
          className={content}
          onClick={!isReadOnly ? onContentClick : undefined}
        >
          {hasValue ? (
            value
          ) : (
            <i className={contentPlaceholder}>{placeholder}</i>
          )}
        </div>
        {endAdornment ? (
          <div className={endAdornmentWrapper}>{endAdornment}</div>
        ) : null}
      </div>
    ) : (
      <InlineEditor
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        roundedWrapper={roundedWrapper}
        roundedButtons={roundedButtons}
        endEditing={endEditing}
      />
    );
  }
);

export default InlineEditorWrapper;
