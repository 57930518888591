import { createContext, useContext } from "react";

export const DataContext = createContext({
  data: undefined,
  dataKeyName: undefined,
  nodeIdKeyName: undefined,
  loading: false,
  refetch: () => {},
  fetchMore: () => {},
  search: undefined,
  setSearch: () => {},
  page: undefined,
  setPage: () => {},
  pageSize: 10,
  setPageSize: () => {},
  orderBy: [],
  offset: 0,
  dataTotalCount: 0,
  setOrderBy: () => {},
  condition: undefined,
  setCondition: () => {},
  mergeCondition: () => {},
});

export const DataProvider = DataContext.Provider;

export const useData = () => useContext(DataContext);

export default useData;
