import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_EVERYTHING } from "gqls";

export const DELETE_DATA_ROOM_TABLE = gql`
  mutation deleteDataRoomTable($input: DeleteDataRoomTableInput!) {
    deleteDataRoomTable(input: $input) {
      clientMutationId
      dataRoomTable {
        ...DataRoomTableEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_EVERYTHING}
`;
