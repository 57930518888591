import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_EVERYTHING } from "gqls";

export const DELETE_DATA_ROOM_QUERY_BY_ID = gql`
  mutation deleteDataRoomQueryById($input: DeleteDataRoomQueryByIdInput!) {
    deleteDataRoomQueryById(input: $input) {
      clientMutationId
      dataRoomQuery {
        ...DataRoomQueryEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_EVERYTHING}
`;
