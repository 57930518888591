import { useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Table as SqTable } from "@decentriq/safequery";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { NormalizedStateHookOptions, useNormalizedState } from "hooks";
import {
  DataRoomTable,
  DataRoomTableColumnDefinition,
  DataRoomTablePrimitiveType,
} from "models";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DataRoomTableActions,
  DataRoomTableConstructor,
  DataRoomTableConstructorMode,
  DataRoomTableUploadDataDialog,
} from "components";
import {
  CREATE_DATA_ROOM_TABLE,
  DATA_ROOM_TABLES,
  DELETE_DATA_ROOM_TABLE,
  UPDATE_DATA_ROOM_TABLE,
} from "gqls";
import { waterfront } from "../../../../safequery-client/node_modules/@decentriq/core/lib/proto/protos";

interface DataRoomTablesProps {
  mode: DataRoomTableConstructorMode;
  onIngestData?: (table: SqTable, content: string) => Promise<Uint8Array>;
  onDatasetsStatus?: () => Promise<waterfront.IPublishedDataset[]>;
  onDatasetRemove?: (table: SqTable, manifestHash: Uint8Array) => Promise<void>;
}

interface DataRoomTableUploading {
  name: string;
  isLoading: boolean;
  uploadedAt?: string;
  user?: string;
  hash?: Uint8Array;
}

const closeSnackbarAction = (close: any) => (key: any) =>
  (
    <IconButton onClick={() => close(key)}>
      <FontAwesomeIcon
        fixedWidth
        icon={faTimes}
        color="white"
      ></FontAwesomeIcon>
    </IconButton>
  );

const DataRoomTables: React.FC<DataRoomTablesProps> = ({
  mode,
  onIngestData,
  onDatasetsStatus,
  onDatasetRemove,
}) => {
  const { dataRoomId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useAuth0();
  const [tableForIngestingData, setTableForIngestingData] =
    useState<DataRoomTable | undefined>();
  const uploadingsOptions = useMemo<
    NormalizedStateHookOptions<DataRoomTableUploading>
  >(
    () => ({
      initialValues: [],
      id: (uploading: DataRoomTableUploading) =>
        `${uploading.name}-${uploading.user}`,
    }),
    []
  );
  const {
    byId: uploadings,
    addOrUpdate: addOrUpdateUploading,
    remove: removeUploading,
  } = useNormalizedState<DataRoomTableUploading>(uploadingsOptions);
  const [isStatusChecking, setIsStatusChecking] = useState(false);
  const { data } = useQuery(DATA_ROOM_TABLES, {
    variables: {
      filter: { dataRoomId: { equalTo: dataRoomId } },
      orderBy: ["INDEX_ASC"],
    },
    onError: () => {
      enqueueSnackbar("Can't fetch data room tables", { variant: "error" });
    },
  });
  const [createDataRoomTableMutation] = useMutation(CREATE_DATA_ROOM_TABLE, {
    onError: () => {
      enqueueSnackbar("Can't create data room table", { variant: "error" });
    },
  });
  const [updateDataRoomTableMutation] = useMutation(UPDATE_DATA_ROOM_TABLE, {
    onError: () => {
      enqueueSnackbar("Can't update data room table", { variant: "error" });
    },
  });
  const [deleteDataRoomTableMutation] = useMutation(DELETE_DATA_ROOM_TABLE, {
    onError: () => {
      enqueueSnackbar("Can't delete data room table", { variant: "error" });
    },
  });
  const onCreate = ({ name }: any) => {
    createDataRoomTableMutation({
      variables: {
        input: {
          dataRoomTable: {
            dataRoomId,
            name,
            sqlCreateStatement: "",
          },
        },
      },
      refetchQueries: ["dataRoom", "dataRoomTables"],
    });
  };
  const onUpdate = ({ dataRoomTableId, name = "", columns = [] }: any) => {
    // TODO: This should be within the component
    const sqlCreateStatement = columns.length
      ? `
      CREATE TABLE ${name} (
        ${columns
          .map(
            ({ name, primitiveType, nullable }: any) =>
              `${name} ${primitiveType} ${!nullable ? "NOT NULL" : ""}`
          )
          .join(",")}
      );
    `.trim()
      : "";
    updateDataRoomTableMutation({
      variables: {
        input: {
          dataRoomTableId,
          patch: {
            name,
            sqlCreateStatement,
          },
        },
      },
    });
  };
  const onDelete = ({ dataRoomTableId }: any) => {
    deleteDataRoomTableMutation({
      variables: {
        input: {
          dataRoomTableId,
        },
      },
      refetchQueries: ["dataRoom", "dataRoomTables"],
    });
  };
  const handleDatasetsStatus = async () => {
    try {
      setIsStatusChecking(true);
      const result = await onDatasetsStatus?.();
      result?.forEach((status) => {
        addOrUpdateUploading({
          name: status.table,
          uploadedAt: new Date(
            (status.timestamp as number) * 1000
          ).toISOString(),
          hash: status.datasetHash,
          user: status?.user,
          isLoading: false,
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsStatusChecking(false);
    }
  };
  useEffect(() => {
    if (
      mode === DataRoomTableConstructorMode.ACTION ||
      mode === DataRoomTableConstructorMode.STATUS
    ) {
      handleDatasetsStatus();
    }
  }, [mode]);
  const handleIngestData = async (table: SqTable, name?: string) => {
    const input = document.createElement("input");
    input.type = "file";
    input.onchange = (event) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const content = event?.target?.result as string;
        try {
          addOrUpdateUploading({
            name,
            user: user?.email,
            isLoading: true,
          });
          const hash = await onIngestData?.(table, content);
          enqueueSnackbar("Data ingested successfully");
          addOrUpdateUploading({
            name,
            user: user?.email,
            uploadedAt: new Date().toISOString(),
            hash,
            isLoading: false,
          });
        } catch (error) {
          enqueueSnackbar(`Can't ingest data: ${error.message}`, {
            variant: "error",
            persist: true,
            action: closeSnackbarAction(closeSnackbar),
          });
          addOrUpdateUploading({
            name,
            user: user?.email,
            isLoading: false,
          });
        }
      };
      const file = (event.target as any).files[0];
      reader.readAsText(file);
      (event.target as any).value = "";
    };
    input.click();
  };
  const handleDeleteData = async (table: SqTable, name?: string) => {
    try {
      const hash = uploadings[`${name}-${user?.email}`]?.hash;
      if (!hash) {
        throw Error("hash is missing");
      }
      addOrUpdateUploading({
        name,
        user: user?.email,
        isLoading: true,
      });
      await onDatasetRemove?.(table, hash);
      removeUploading(`${name}-${user?.email}`);
    } catch (error) {
      enqueueSnackbar(`Can't remove dataset: ${error.message}`, {
        variant: "error",
        persist: true,
        action: closeSnackbarAction(closeSnackbar),
      });
      addOrUpdateUploading({
        name,
        user: user?.email,
        isLoading: false,
      });
    }
  };
  const tables: DataRoomTable[] =
    data?.dataRoomTables?.nodes
      ?.map(
        (node: {
          name?: string;
          dataRoomTableId: string;
          ownerEmail: string;
          sqlCreateStatement: string;
          dataRoomTableShares: { nodes: { userEmail: string }[] };
        }) => {
          // TODO: This should be within the component
          let name = node?.name || "";
          let columns: DataRoomTableColumnDefinition[] = [];
          const match = node?.sqlCreateStatement.match(
            /^\s*CREATE\s+TABLE\s+(?<name>.*?)\s+\((?<columns>.+?)\)\s*;\s*$/ims
          );
          if (match) {
            name = match?.groups?.name as string;
            columns =
              match?.groups?.columns
                .trim()
                .replace(/\s\s+/gs, " ")
                .split(",")
                .map((column: string) => {
                  const columnTrim = column.trim();
                  const columnSplit = columnTrim.split(" ");
                  return {
                    name: columnSplit[0],
                    primitiveType: columnSplit[1] as DataRoomTablePrimitiveType,
                    nullable: !columnTrim.toLowerCase().includes("not null"),
                  };
                }) || [];
          }
          return {
            name,
            dataRoomTableId: node?.dataRoomTableId,
            columns,
            ownerEmail: node?.ownerEmail,
            sqlCreateStatement: node?.sqlCreateStatement,
            participants:
              node?.dataRoomTableShares?.nodes?.map(({ userEmail }) => {
                const uploading = uploadings[`${node?.name}-${userEmail}`];
                return {
                  userEmail,
                  uploadedAt: uploading?.uploadedAt,
                };
              }) || [],
          } as DataRoomTable;
        }
      )
      .filter((t: DataRoomTable) =>
        mode === DataRoomTableConstructorMode.ACTION
          ? !!t.participants.find((p) => p.userEmail === user?.email)
          : true
      ) || [];
  return data ? (
    <>
      <DataRoomTableConstructor
        mode={mode}
        tables={tables}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        renderDataUploading={(table) => (
          <DataRoomTableActions
            currentUserEmail={user?.email}
            isLoading={
              isStatusChecking ||
              uploadings[`${table.name}-${user?.email}`]?.isLoading
            }
            participants={table.participants}
            onUpload={() => {
              setTableForIngestingData(table);
            }}
            onDelete={() => {
              handleDeleteData(
                {
                  name: table?.name as string,
                  sqlCreateStatement: table?.sqlCreateStatement as string,
                  dataProviders: table?.participants.map(
                    (e) => e.userEmail
                  ) as [string, ...string[]],
                },
                table?.name
              );
            }}
          />
        )}
      />
      <DataRoomTableUploadDataDialog
        open={!!tableForIngestingData}
        onClose={() => setTableForIngestingData(undefined)}
        onSelect={() => {
          handleIngestData(
            {
              name: tableForIngestingData?.name as string,
              sqlCreateStatement:
                tableForIngestingData?.sqlCreateStatement as string,
              dataProviders: tableForIngestingData?.participants.map(
                (e) => e.userEmail
              ) as [string, ...string[]],
            },
            tableForIngestingData?.name
          );
          setTableForIngestingData(undefined);
        }}
      />
    </>
  ) : null;
};

export default DataRoomTables;
