import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useCustomTableStyles = makeStyles((theme: Theme) => ({
  MuiTableContainer: {
    "&.MuiTableContainer-root": {
      [theme.breakpoints.down("lg")]: {
        "& $MuiTableCell": {
          flexBasis: "100%",
        },
      },
    },
  },
  MuiTableRow: {
    "&:last-child > .MuiTableCell-root": {
      borderBottom: "none",
    },
  },
  MuiTableCell: {
    "&.MuiTableCell-root": {
      flexBasis: (props) =>
        (props as any).forceWideCells ? "100%" : undefined,
    },
  },
  MuiTableCellAsEntity: {
    "&.MuiTableCell-root": {
      display: "block",
      padding: theme.spacing(0.25),
      border: "none",
      "&:not(:first-child)": {
        [theme.breakpoints.down("lg")]: {
          marginTop: theme.spacing(-1.5),
        },
        marginTop: (props) =>
          (props as any).forceWideCells ? theme.spacing(-1.5) : undefined,
      },
    },
  },
  columnLabel: {
    marginRight: theme.spacing(4),
  },
}));
