// @ts-nocheck
import { Children, memo } from "react";
import { useMetaStyles } from "../Meta/Meta.styles";

const MetaGroup = memo(({ children }) => {
  const metaClasses = useMetaStyles();
  return Children.count(children) ? (
    <div className={metaClasses.metaGroup}>{children}</div>
  ) : null;
});

export default MetaGroup;
