import { useAuth0 } from "@auth0/auth0-react";
import { createInstance, OptimizelyProvider } from "@optimizely/react-sdk";
import { memo } from "react";
import { useConfiguration } from "contexts";

const OptimizelyWrapper = memo(({ children }: any) => {
  const { user: auth0User } = useAuth0();
  const {
    configuration: { optimizelySdkKey, optimizelyDatafile },
  } = useConfiguration();
  const optimizely = createInstance({
    sdkKey: optimizelySdkKey,
    datafile: optimizelyDatafile,
    logLevel: 4,
  });
  const user = auth0User
    ? { id: auth0User.email || null }
    : { id: "anonymous" };
  return (
    <OptimizelyProvider optimizely={optimizely} user={user} timeout={500}>
      {children}
    </OptimizelyProvider>
  );
});

export default OptimizelyWrapper;
