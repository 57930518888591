import { Backdrop, CircularProgress } from "@material-ui/core";
import { useBackdropStyles } from "./Loading.styles";

const Loading = ({ loading }: any) => {
  const backdropClasses = useBackdropStyles();
  return (
    <Backdrop open={loading} classes={backdropClasses}>
      <CircularProgress color="inherit" thickness={1} size="3rem" />
    </Backdrop>
  );
};

export default Loading;
