import { createTheme, PaletteMode } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const createDecentriqTheme = ({
  theme = createTheme(),
  mode = "light" as PaletteMode,
}) =>
  createTheme({
    typography: {
      fontFamily: `"Helvetica Neue", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
      body2: {
        fontSize: "0.875rem",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0.5, 1),
            lineHeight: 1.7142857,
            textTransform: "none",
            letterSpacing: 0,
            fontWeight: 600,
          },
          text: {
            padding: theme.spacing(0.5, 1),
          },
          outlined: {
            padding: theme.spacing(0.375, 1),
            lineHeight: 1.7142857,
            border: "2px solid",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: "0 !important",
            fontSize: "1rem",
            padding: theme.spacing(1),
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&:hover::after": {
              content: "''",
              backgroundColor: "currentColor",
              display: "block",
              height: "100%",
              width: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              borderRadius: "inherit",
              opacity: 0.125,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "0.75rem",
            fontWeight: 700,
            lineHeight: "1rem",
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            maxWidth: theme.spacing(40),
            boxShadow:
              "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
          },
          tooltipPlacementTop: {
            margin: `${theme.spacing(0.5, 0)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
          },
          tooltipPlacementRight: {
            margin: `${theme.spacing(0, 0.5)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
          },
          tooltipPlacementBottom: {
            margin: `${theme.spacing(0.5, 0)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0.5, 0)} !important`,
            },
          },
          tooltipPlacementLeft: {
            margin: `${theme.spacing(0, 0.5)} !important`,
            [theme.breakpoints.up("sm")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
            [theme.breakpoints.up("md")]: {
              margin: `${theme.spacing(0, 0.5)} !important`,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&:not(:first-of-type)": {
              marginTop: 1,
            },
            "&.Mui-expanded": {
              margin: 0,
            },
            "&:before": {
              display: "none",
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: 0,
            cursor: "auto !important",
            minHeight: "auto",
            "&.Mui-expanded": {
              minHeight: "auto",
            },
            "&$focusVisible": {
              backgroundColor: "initial !important",
            },
          },
          content: {
            minWidth: 0,
            margin: 0,
            alignSelf: "stretch",
            flexDirection: "column",
            "&.Mui-expanded": {
              margin: 0,
            },
          },
          expandIconWrapper: {
            margin: theme.spacing(1),
            alignSelf: "flex-start",
            "&.Mui-expanded": {
              transform: "rotate(90deg)",
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0, 0, 0, 0),
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRightWidth: "2px",
          },
          docked: {
            width: "25%",
            minWidth: "480px",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            transition: "all 0.2s ease-in-out",
            padding: 0,
            "&:not(:hover)": {
              color: "inherit",
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: theme.palette.text.secondary,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: theme.palette.text.primary,
            },
          },
          input: {
            height: "1.1428575em",
            lineHeight: "1.1428575em",
            "&.Mui-disabled": {
              color: theme.palette.text.primary,
              textFillColor: "currentColor",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&:before": {
              borderBottomStyle: "dotted",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&.Mui-disabled": {
              cursor: "auto",
              userSelect: "auto",
            },
          },
          outlined: {
            minHeight: "unset",
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderBottomWidth: "2px",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            boxShadow: "none",
            border: "2px solid #000000",
          },
        },
      },
      // @ts-ignore
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              borderWidth: 2,
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: "none !important",
            border: "2px solid #000000",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    palette: {
      primary: grey,
      secondary: grey,
      mode,
      divider: "#000000",
      background: {
        paper: "#e0dfda",
        default: "#e0dfda",
      },
    },
    shape: {
      borderRadius: 0,
    },
  });

export default createDecentriqTheme;
