import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { DataRoomParticipant } from "models";
import React, { useCallback } from "react";
import { DataRoomParticipationOption } from "./DataRoomParticipantForm";

interface DataRoomParticipantTileProps {
  index: number;
  participant: DataRoomParticipant;
  queries: DataRoomParticipationOption[];
  tables: DataRoomParticipationOption[];
  readonly: boolean;
  allowEditing: boolean;
  onEdit: (index: number) => void;
  onDelete: (participant: DataRoomParticipant) => void;
}

const DataRoomParticipantTile: React.FC<DataRoomParticipantTileProps> = ({
  index,
  tables,
  queries,
  participant,
  readonly,
  allowEditing,
  onEdit,
  onDelete,
}) => {
  const participations = useCallback(
    (values: string[], options: DataRoomParticipationOption[]) =>
      options
        .filter(({ value: id }) => values.includes(id))
        .map(({ title }) => title)
        .join(", "),
    []
  );
  return (
    <ListItem
      onDoubleClick={() => {
        if (allowEditing) {
          onEdit(index);
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item container xs={4} alignItems="baseline">
          <Typography
            variant="h5"
            style={{ wordBreak: "break-all", marginLeft: "32px" }}
          >
            {participant.userEmail}
          </Typography>
          <Typography marginLeft="8px" variant="caption" color={grey["400"]}>
            is an Auditor,
          </Typography>
        </Grid>
        <Grid item xs={3} container alignItems="center">
          <Typography variant="body2">
            <Typography marginRight="8px" variant="caption" color={grey["400"]}>
              Data Provider for
            </Typography>
            {participant.tables.length
              ? participations(participant.tables, tables)
              : "0 tables"}
          </Typography>
        </Grid>
        <Grid item xs={4} container alignItems="center">
          <Typography variant="body2">
            <Typography marginRight="8px" variant="caption" color={grey["400"]}>
              and Data Analyst for
            </Typography>
            {participant.queries.length
              ? participations(participant.queries, queries)
              : "0 queries"}
          </Typography>
        </Grid>
        <Grid container item xs={1} justifyContent="flex-end">
          {!readonly && (
            <>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={onDelete.bind(null, participant)}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth
                  color={red[200]}
                ></FontAwesomeIcon>
              </IconButton>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={onEdit.bind(null, index)}
              >
                <FontAwesomeIcon fixedWidth icon={faPencil}></FontAwesomeIcon>
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default DataRoomParticipantTile;
