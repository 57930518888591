import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "components";
import {
  DataRoomPage,
  DataRoomsPage,
  FourOFourPage,
  HomePage,
  SignInPage,
} from "pages";

const PagesWrapper = memo(({ children }: any) => {
  return (
    <Routes>
      <PrivateRoute path="/datarooms/:dataRoomId" element={<DataRoomPage />} />
      <PrivateRoute path="/datarooms" element={<DataRoomsPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<FourOFourPage />} />
    </Routes>
  );
});

export default PagesWrapper;
