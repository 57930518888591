import { DataRoomTablePrimitiveType } from "models";

export const dataRoomTablePrimitiveTypePresentation = new Map<
  DataRoomTablePrimitiveType,
  string
>([
  [DataRoomTablePrimitiveType.INT, "INT"],
  [DataRoomTablePrimitiveType.VARCHAR, "VARCHAR"],
  [DataRoomTablePrimitiveType.FLOAT, "FLOAT"],
]);

export interface DataRoomTablePrimitiveTypeOption {
  label: string;
  value: DataRoomTablePrimitiveType;
}
export const dataRoomTablePrimitiveTypeOptions: DataRoomTablePrimitiveTypeOption[] =
  [
    DataRoomTablePrimitiveType.INT,
    DataRoomTablePrimitiveType.VARCHAR,
    DataRoomTablePrimitiveType.FLOAT,
  ].map((key) => ({
    value: key,
    label: dataRoomTablePrimitiveTypePresentation.get(key) as string,
  }));
