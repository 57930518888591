import { DataRoom } from "containers";
import { Main } from "layouts";

const DataRoomPage = () => {
  return (
    <Main scrollable>
      <DataRoom />
    </Main>
  );
};

export default DataRoomPage;
