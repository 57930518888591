// @ts-nocheck
import { useBoolean, useToggle } from "ahooks";
import { memo, useState } from "react";
import { MainProvider } from "contexts";

const NAVIGATION_DRAWER_VARIANT_TEMPORARY = "temporary";
const NAVIGATION_DRAWER_VARIANT_PERMANENT = "permanent";

const MainWrapper = memo(({ children }: any) => {
  const [showEntitiesIds, setShowEntitiesIds] = useState(false);
  const [isDarkThemeEnabled, setIsDarkThemeEnabled] = useState(false);
  const [prefersColorSchemeDark, setPrefersColorSchemeDark] = useState(false);
  const [
    isNavigationDrawerOpen,
    {
      toggle: toggleNavigationDrawer,
      setTrue: openNavigationDrawer,
      setFalse: closeNavigationDrawer,
    },
  ] = useBoolean(false);
  const [
    navigationDrawerVariant,
    {
      toggle: toggleNavigationDrawerVariant,
      setLeft: setNavigationDrawerVariantPermanent,
      setRight: setNavigationDrawerVariantTemporary,
    },
  ] = useToggle(
    NAVIGATION_DRAWER_VARIANT_PERMANENT,
    NAVIGATION_DRAWER_VARIANT_TEMPORARY
  );
  return (
    <MainProvider
      value={{
        showEntitiesIds,
        setShowEntitiesIds,
        isDarkThemeEnabled,
        setIsDarkThemeEnabled,
        prefersColorSchemeDark,
        setPrefersColorSchemeDark,
        isNavigationDrawerOpen,
        toggleNavigationDrawer,
        openNavigationDrawer,
        closeNavigationDrawer,
        navigationDrawerVariant,
        toggleNavigationDrawerVariant,
        setNavigationDrawerVariantTemporary,
        setNavigationDrawerVariantPermanent,
      }}
    >
      {children}
    </MainProvider>
  );
});

export default MainWrapper;
