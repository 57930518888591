// @ts-nocheck
import { faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";
import { isElement, isValidElementType } from "react-is";

const useIconButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: ({ inline }) => theme.spacing(inline ? 0.5 : 1),
    borderRadius: ({ square }) => (square ? theme.shape.borderRadius : "100%"),
    minWidth: 0,
    color: ({ isRed }) => (isRed ? "red" : "inherit"),
  },
}));

const ExpandIcon = memo(({ icon, icon: Icon, ...rest }) => {
  const iconButtonClasses = useIconButtonStyles({
    inline: true,
    square: false,
    circle: true,
    isRed: false,
  });
  return isElement(icon) ? (
    icon
  ) : isValidElementType(icon) ? (
    <Icon />
  ) : (
    <IconButton classes={iconButtonClasses}>
      <FontAwesomeIcon fixedWidth icon={icon || faCaretRight} {...rest} />
    </IconButton>
  );
});

export default ExpandIcon;
