import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useBackdropStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.tooltip + 1,
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));
