import { faFileDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { useBoolean } from "ahooks";
import { saveAs } from "file-saver";
import { parse as parseCsv } from "papaparse";
import { useCallback, useMemo } from "react";

interface DataRoomQueryResultPaneProps {
  dataRoomQueryId: string;
  result?: string;
  isLoading: boolean;
  hasRunQuery: boolean;
}

const DataRoomQueryResultPane: React.FC<DataRoomQueryResultPaneProps> = ({
  hasRunQuery,
  isLoading,
  result,
}) => {
  const handleDownloadResult = useCallback((result) => {
    const file = new File([result], `Results.csv`, {
      type: "application/octet-stream;charset=utf-8",
    });
    saveAs(file);
  }, []);
  const [asTable] = useBoolean(true);
  const logsData = useMemo<{ data: string[][]; rowLength: number }>(() => {
    if (asTable && result) {
      const { data, errors } = parseCsv<string[]>(result as string, {
        delimiter: ",",
      });
      if (!errors.length) {
        return { data, rowLength: Math.max(...data.map((r) => r.length)) };
      }
      return { data: [], rowLength: 0 };
    }
    return { data: [], rowLength: 0 };
  }, [result, asTable]);
  return (
    <Box
      style={{
        marginTop: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      {hasRunQuery && !isLoading ? (
        result ? (
          <>
            {asTable ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableBody>
                    {logsData.data.map((row, rIndex) => (
                      <TableRow key={rIndex}>
                        {Array.from({ length: logsData.rowLength }).map(
                          (_, cIndex) => (
                            <TableCell key={cIndex}>
                              {row[cIndex] || ""}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <textarea
                defaultValue={result}
                style={{
                  minHeight: "4rem",
                  width: "100%",
                  maxWidth: "100%",
                  minWidth: "100%",
                }}
              ></textarea>
            )}
            <Button
              color="inherit"
              startIcon={<FontAwesomeIcon icon={faFileDownload} fixedWidth />}
              onClick={() => handleDownloadResult(result)}
            >
              Download as CSV
            </Button>
          </>
        ) : (
          "Your query returned an empty result."
        )
      ) : undefined}
    </Box>
  );
};

export default DataRoomQueryResultPane;
