import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faKey,
  faSignOut,
  faUserSlash,
} from "@fortawesome/pro-light-svg-icons";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { forwardRef } from "react";
import { ChangePasswordDialog, DeleteAccountDialog } from "components";
import { CHANGE_PASSWORD, DELETE_ACCOUNT } from "gqls";

const useCardStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(-1),
    marginTop: theme.spacing(0.75),
    padding: theme.spacing(1, 1, 1, 1),
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  name: {
    lineHeight: "1.25em",
  },
}));

const UserCard = ({ user = {} }) => {
  const cardClasses = useCardStyles();
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'picture' does not exist on type '{}'.
  const { picture, name, email } = user || {};
  return (
    <Box className={cardClasses.wrapper}>
      <Avatar alt={name} src={picture} className={cardClasses.avatar} />
      <Box>
        <Typography noWrap variant="body1" className={cardClasses.name}>
          <strong>{name}</strong>
        </Typography>
        <Typography noWrap variant="body2">
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

const useAccountButtonStyles = makeStyles((theme: Theme) => ({
  button: {
    "&:hover $signOut": {
      opacity: 1,
    },
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: theme.spacing(-0.5),
  },
  icon: {
    fontSize: "1rem",
  },
  signOut: {
    position: "absolute",
    left: 0,
    top: 0,
    color: "white",
    background: "rgba(0, 0, 0, 0.625)",
    borderRadius: "100%",
    height: 32,
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0,
    transition: "all 0.2s linear",
  },
}));

const AccountButton = forwardRef(
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'useAccountMenu' does not exist on type '... Remove this comment to see the full error message
  ({ useAccountMenu = false, onClick, ...rest }, ref) => {
    const { button, avatar, icon, signOut } = useAccountButtonStyles();
    const { user = {} } = useAuth0();
    const { picture, name } = user;
    return (
      <Tooltip placement="left" title="Your profile">
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <IconButton
          ref={ref}
          {...rest}
          color="inherit"
          onClick={onClick}
          className={button}
        >
          {picture ? (
            <Avatar alt={name} src={picture} className={avatar} />
          ) : (
            <FontAwesomeIcon fixedWidth icon={faUserCircle} className={icon} />
          )}
          {useAccountMenu ? null : (
            <Box className={signOut}>
              <FontAwesomeIcon fixedWidth icon={faSignOut} className={icon} />
            </Box>
          )}
        </IconButton>
      </Tooltip>
    );
  }
);

const useAccountMenuStyles = makeStyles((theme: Theme) => ({
  tooltipWrapper: {
    padding: theme.spacing(0, 0, 0.5, 0),
  },
  tooltip: {
    padding: "1px 0",
  },
  tooltipLine1: {
    lineHeight: "1rem",
  },
  tooltipLine2: {
    lineHeight: "1rem",
    opacity: 0.38,
  },
  tooltipCardWrapper: {
    backgroundColor: theme.palette.grey["900"],
  },
}));

const useListItemIconStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "inherit",
    fontSize: theme.typography.htmlFontSize,
    marginRight: theme.spacing(1),
    minWidth: 0,
    width: theme.spacing(2),
  },
}));

const useListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "inherit",
  },
  dense: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  gutters: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: "transparent",
  },
}));

const AccountMenu = () => {
  const {
    tooltipWrapper,
    tooltip,
    tooltipLine1,
    tooltipLine2,
    tooltipCardWrapper,
  } = useAccountMenuStyles();
  const listItemIconClasses = useListItemIconStyles();
  const listItemClasses = useListItemStyles();
  const { user = {}, logout } = useAuth0();
  const { picture, name, email } = user;
  const {
    anchorEl: anchorElAccountMenu,
    isOpen: isOpenAccountMenu,
    open: openAccountMenu,
    close: closeAccountMenu,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ variant: "popover"; }' is not ... Remove this comment to see the full error message
  } = usePopupState({
    variant: "popover",
  });
  const onOpenAccountMenu = (event: any) => {
    event.stopPropagation();
    openAccountMenu(event.currentTarget);
  };
  const onCloseAccountMenu = (event: any) => {
    event.stopPropagation();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    closeAccountMenu(event.currentTarget);
  };
  const primaryTypographyProps = {
    sx: {
      display: "flex",
      alignItems: "center",
      fontWeight: "inherit",
      lineHeight: "1rem",
      whiteSpace: "nowrap",
    },
  };
  // Actions
  const { enqueueSnackbar } = useSnackbar();
  // Actions: Sign out
  const signOut = () =>
    logout({
      returnTo: window.location.origin,
    });
  const onSignOutClick = () => {
    signOut();
  };
  // Actions: Change password
  const [
    isChangePasswordDialogOpen,
    { setFalse: closeChangePasswordDialog, setTrue: openChangePasswordDialog },
  ] = useBoolean(false);
  const [changePassword, { loading: isChangePasswordLoading }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: () => {
        closeAccountMenu();
        openChangePasswordDialog();
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Can't generate change password link 😔", {
          variant: "error",
        });
      },
    }
  );
  const onChangePasswordClick = () => {
    changePassword();
  };
  // Actions: Delete account
  const [
    isDeleteAccountDialogOpen,
    { setFalse: closeDeleteAccountDialog, setTrue: openDeleteAccountDialog },
  ] = useBoolean(false);
  const [deleteAccount, { loading: isDeleteAccountLoading }] = useMutation(
    DELETE_ACCOUNT,
    {
      onCompleted: () => {
        closeDeleteAccountDialog();
        signOut();
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Can't delete account 😔", { variant: "error" });
      },
    }
  );
  const onDeleteAccountClick = () => {
    closeAccountMenu();
    openDeleteAccountDialog();
  };
  // Render button with either the menu popup
  // or an immediate "Sign out" action on click
  const useAccountMenu = true;
  return useAccountMenu ? (
    <>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ useAccountMenu: true; onClick: (event: any... Remove this comment to see the full error message */}
      <AccountButton useAccountMenu onClick={onOpenAccountMenu} />
      <Popover
        anchorEl={anchorElAccountMenu}
        open={isOpenAccountMenu}
        onClose={onCloseAccountMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box sx={{ pl: 2, pr: 2 }}>
          <UserCard user={user} />
        </Box>
        <MenuList>
          <MenuItem
            dense
            classes={listItemClasses}
            onClick={onChangePasswordClick}
            disabled={isChangePasswordLoading}
          >
            <ListItemIcon classes={listItemIconClasses}>
              {isChangePasswordLoading ? (
                <CircularProgress size={16} color="inherit" thickness={2} />
              ) : (
                <FontAwesomeIcon fixedWidth icon={faKey} />
              )}
            </ListItemIcon>
            <ListItemText
              primary="Change password"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ sx: { display: string; alignItems: string;... Remove this comment to see the full error message
              primaryTypographyProps={primaryTypographyProps}
            />
          </MenuItem>
          <MenuItem
            dense
            classes={listItemClasses}
            onClick={onDeleteAccountClick}
            style={{ color: "red" }}
          >
            <ListItemIcon classes={listItemIconClasses}>
              <FontAwesomeIcon fixedWidth icon={faUserSlash} />
            </ListItemIcon>
            <ListItemText
              primary="Delete account"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ sx: { display: string; alignItems: string;... Remove this comment to see the full error message
              primaryTypographyProps={primaryTypographyProps}
            />
          </MenuItem>
        </MenuList>
        <Divider />
        <MenuList>
          <MenuItem dense classes={listItemClasses} onClick={onSignOutClick}>
            <ListItemIcon classes={listItemIconClasses}>
              <FontAwesomeIcon fixedWidth icon={faSignOut} />
            </ListItemIcon>
            <ListItemText
              primary="Sign out"
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ sx: { display: string; alignItems: string;... Remove this comment to see the full error message
              primaryTypographyProps={primaryTypographyProps}
            />
          </MenuItem>
        </MenuList>
      </Popover>
      <ChangePasswordDialog
        open={isChangePasswordDialogOpen}
        onClose={closeChangePasswordDialog}
      />
      <DeleteAccountDialog
        open={isDeleteAccountDialogOpen}
        loading={isDeleteAccountLoading}
        onCancel={closeDeleteAccountDialog}
        onConfirm={deleteAccount}
      />
    </>
  ) : (
    <Tooltip
      title={
        <Box className={tooltipWrapper}>
          <Box className={tooltip}>
            <Box className={tooltipLine1}>Sign out</Box>
            <Box className={tooltipLine2}>Logged in as {email}</Box>
          </Box>
          {picture && name && email && name !== email ? (
            <Box className={tooltipCardWrapper}>
              <UserCard user={user} />
            </Box>
          ) : null}
        </Box>
      }
      placement="bottom-end"
    >
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClick: () => void; }' is not assignable ... Remove this comment to see the full error message */}
      <AccountButton onClick={onSignOutClick} />
    </Tooltip>
  );
};

export default AccountMenu;
