import { Auth0Provider } from "@auth0/auth0-react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useConfiguration } from "contexts";

const Auth0Wrapper = memo(({ children }: any) => {
  const {
    configuration: { auth0Audience, auth0ClientId, auth0Domain },
  } = useConfiguration();
  const navigate = useNavigate();
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={auth0Audience}
      redirectUri={window.location.origin}
      onRedirectCallback={(appState) => {
        navigate({ ...appState?.referer, pathname: "/" });
      }}
    >
      {children}
    </Auth0Provider>
  );
});

export default Auth0Wrapper;
