import { gql } from "@apollo/client";
import { DATA_ROOM_TABLE_EVERYTHING } from "gqls";

export const DELETE_DATA_ROOM_TABLE_BY_ID = gql`
  mutation deleteDataRoomTableById($input: DeleteDataRoomTableByIdInput!) {
    deleteDataRoomTableById(input: $input) {
      clientMutationId
      dataRoomTable {
        ...DataRoomTableEverything
      }
    }
  }
  ${DATA_ROOM_TABLE_EVERYTHING}
`;
