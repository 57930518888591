// @ts-nocheck
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useMetaStyles = makeStyles((theme: Theme) => ({
  metaGroup: {
    display: "inline-flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    height: "auto",
    "&:not(:first-child)": {
      marginTop: theme.spacing(0.25),
    },
    "& > span": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& > span:not(:last-child)": {
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.25),
    },
    "& > span:not(:last-child)::after": {
      content: "'•'",
      marginLeft: theme.spacing(0.5),
      fontSize: "2em",
      lineHeight: 0,
      opacity: 0.25,
    },
    "& > span:not(:last-child) > span": {
      display: "inline-flex",
      alignItems: "center",
    },
  },
  meta: {
    textDecoration: "none",
    color: "inherit",
    display: "inline-flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    transition: "all 0.2s linear",
    opacity: 0.33,
    background: ({ pill }) =>
      pill ? theme.palette.action.focus : "transparent",
    cursor: ({ pill }) => (pill ? "pointer" : "default"),
    borderRadius: ({ pill }) => (pill ? theme.shape.borderRadius : 0),
    padding: ({ pill }) =>
      pill ? theme.spacing(0.25, 0.5) : theme.spacing(0.25, 0),
    "&:hover": {
      opacity: 1,
      background: ({ pill }) =>
        pill ? theme.palette.background.paper : "transparent",
    },
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.875rem",
    marginRight: theme.spacing(0.25),
  },
  labelWrapper: {},
  valueWrapper: {
    marginLeft: theme.spacing(0.25),
    fontWeight: "bold",
  },
}));
