import { TableSortLabel } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { useTableSortLabelStyles } from "./TableSortLabelWithOrderBy.styles";

const TableSortLabelWithOrderBy = ({
  label,
  orderByAsc,
  orderByDesc,
  orderBy,
  setOrderBy,
}: any) => {
  const tableSortLabelClasses = useTableSortLabelStyles();
  const orderedAsc = isEqual(orderBy, orderByAsc);
  const orderedDesc = isEqual(orderBy, orderByDesc);
  const orderDirection = orderedDesc ? "desc" : "asc";
  return (
    <TableSortLabel
      active={orderedAsc || orderedDesc}
      direction={orderDirection}
      onClick={setOrderBy.bind(
        null,
        !(orderedAsc || orderedDesc)
          ? orderByAsc
          : orderDirection === "asc"
          ? orderByDesc
          : orderByAsc
      )}
      classes={tableSortLabelClasses}
    >
      {label}
    </TableSortLabel>
  );
};

export default TableSortLabelWithOrderBy;
