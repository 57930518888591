// @ts-nocheck
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useCopyToClipboard, useKeyPressEvent } from "react-use";
import { useMain } from "contexts";

const useTooltipWrapperStyles = makeStyles({
  tooltipWrapper: {
    display: "flex",
    alignItems: "center",
  },
});

const TooltipWrapper = memo(({ children }) => {
  const { tooltipWrapper } = useTooltipWrapperStyles();
  return <div className={tooltipWrapper}>{children}</div>;
});

const useTooltipButtonStyles = makeStyles((theme: Theme) => ({
  tooltipId: {
    fontWeight: "inherit",
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    whiteSpace: "nowrap",
    padding: theme.spacing(0.5, 0.75),
    margin: theme.spacing(-0.5, -1),
    minWidth: 0,
  },
}));

const TooltipButton = memo(({ text, onClick }) => {
  const { tooltipId } = useTooltipButtonStyles();
  return (
    <Button color="inherit" className={tooltipId} onClick={onClick}>
      {text}
    </Button>
  );
});

const useLinkIdStyles = makeStyles((theme: Theme) => ({
  linkWrapper: {
    display: "flex",
    alignItems: "baseline",
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkId: {
    fontWeight: 400,
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    fontSize: "0.7em",
    letterSpacing: "-0.025em",
    lineHeight: "1em",
    borderRadius: theme.shape.borderRadius,
    opacity: 0.5,
    transition: "all 0.2s linear",
    "&:hover": {
      opacity: 1,
    },
  },
}));

const LinkId = memo(({ id, href }) => {
  const { linkWrapper, linkId } = useLinkIdStyles();
  return (
    <NavLink className={linkWrapper} to={href}>
      <span>&#8203;</span>
      <div className={linkId}>{id?.substring(0, 8)}</div>
    </NavLink>
  );
});

const usePlainIdStyles = makeStyles((theme: Theme) => ({
  plainWrapper: {
    display: "flex",
    alignItems: "baseline",
  },
  plainId: {
    fontWeight: 400,
    fontFamily: "SFMono-Normal, Consolas, Liberation Mono, Menlo, monospace",
    fontSize: "0.7em",
    letterSpacing: "-0.025em",
    lineHeight: "1em",
    borderRadius: theme.shape.borderRadius,
    opacity: 0.5,
    transition: "all 0.2s linear",
    cursor: "default",
    "&:hover": {
      opacity: 1,
    },
  },
}));

const PlainId = memo(({ id }) => {
  const { plainWrapper, plainId } = usePlainIdStyles();
  return (
    <div className={plainWrapper}>
      <span>&#8203;</span>
      <div className={plainId}>{id?.substring(0, 8)}</div>
    </div>
  );
});

const Id = memo(({ id = "", href }) => {
  const { showEntitiesIds } = useMain();
  const { enqueueSnackbar } = useSnackbar();
  const [{ value: copiedToClipboard }, copyToClipboard] = useCopyToClipboard();
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  const tooltipButtonText = isShiftDown ? id.replaceAll("-", "") : id;
  const onTooltipButtonClick = (event) => {
    event.stopPropagation();
    copyToClipboard(tooltipButtonText);
  };
  useEffect(() => {
    if (copiedToClipboard)
      enqueueSnackbar(
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            fixedWidth
            icon={faCopy}
            style={{ fontSize: "1rem", marginRight: "0.5rem" }}
          />
          Copied to clipboard
        </div>
      );
  }, [enqueueSnackbar, copiedToClipboard]);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  return showEntitiesIds && id ? (
    <Tooltip
      title={
        <TooltipWrapper>
          <TooltipButton
            text={tooltipButtonText}
            onClick={onTooltipButtonClick}
          />
        </TooltipWrapper>
      }
      placement="top"
      disableFocusListener
      disableTouchListener
      enterDelay={500}
      enterNextDelay={250}
    >
      <div>{href ? <LinkId id={id} href={href} /> : <PlainId id={id} />}</div>
    </Tooltip>
  ) : null;
});

export default Id;
