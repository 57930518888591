// @ts-nocheck
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  formatDuration,
  intervalToDuration,
  isValid,
  parseISO,
} from "date-fns";
import { capitalize, lowerCase } from "lodash";
import { memo } from "react";

const useStatusTooltipTitleStyles = makeStyles((theme: Theme) => ({
  info: {
    fontWeight: 400,
  },
}));

const StatusTooltipTitle = memo(
  ({
    status,
    statusUpdatedAt,
    statusTimePrefix = "",
    statusTimeSuffix = "",
  }) => {
    const statusTooltipTitleClasses = useStatusTooltipTitleStyles();
    const statusUpdatedAtDate = parseISO(statusUpdatedAt);
    const statusUpdatedToNow = isValid(statusUpdatedAtDate)
      ? formatDuration(
          intervalToDuration({
            start: statusUpdatedAtDate,
            end: new Date(),
          })
        )
      : "";
    return (
      <div>
        <div>{capitalize(lowerCase(status))}</div>
        <div className={statusTooltipTitleClasses.info}>
          {statusTimePrefix}
          {statusUpdatedToNow}
          {statusTimeSuffix}
        </div>
      </div>
    );
  }
);

export default StatusTooltipTitle;
