// @ts-nocheck
import {
  faFileCertificate,
  faKey,
  faMicrochip,
  faShieldCheck,
  faShieldKeyhole,
} from "@fortawesome/pro-duotone-svg-icons";
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useListItemIconStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: theme.spacing(5),
  },
}));

const useDialogActionsStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 3, 2, 3),
    justifyContent: "center",
  },
}));

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    borderColor: theme.palette.text.disabled,
  },
}));

const usePublishedAndProtectedDialogStyles = makeStyles((theme: Theme) => ({
  iconTitleWrapper: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  title: {
    lineHeight: 1.25,
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  listIcon: {
    fontSize: "1.5rem",
  },
  linkButtonIcon: {
    fontSize: "1rem",
  },
}));

const useLinkButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "1rem",
    borderRadius: 0,
    padding: theme.spacing(1, 3),
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const PublishedAndProtectedDialog = ({ open, onClose }: any) => {
  const publishedAndProtectedDialogClasses =
    usePublishedAndProtectedDialogStyles();
  const listItemIconClasses = useListItemIconStyles();
  const dialogActionsClasses = useDialogActionsStyles();
  const buttonClasses = useButtonStyles();
  const linkButtonClasses = useLinkButtonStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
    >
      <Box className={publishedAndProtectedDialogClasses.iconTitleWrapper}>
        <Box className={publishedAndProtectedDialogClasses.iconWrapper}>
          <FontAwesomeIcon
            fixedWidth
            icon={faShieldKeyhole}
            size="5x"
            className={publishedAndProtectedDialogClasses.icon}
          />
        </Box>
        <Typography
          variant="h6"
          color="textPrimary"
          align="center"
          className={publishedAndProtectedDialogClasses.title}
        >
          <strong>
            This data room is now published
            <br />
            and protected by Decentriq
            <br />
            using Intel® SGX
          </strong>
        </Typography>
      </Box>
      <DialogContent
        className={publishedAndProtectedDialogClasses.dialogContent}
      >
        <Typography variant="body2" align="left" component="div">
          Data room participants can submit their data now. Intel® Software
          Guard Extensions (Intel® SGX) helps to protect this data from
          disclosure or modification by partitioning Decentriq Platform
          application into the enclaves in memory that increase security:
          <List dense>
            <ListItem disableGutters>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faShieldCheck}
                  className={publishedAndProtectedDialogClasses.listIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary="Enclaves have hardware-assisted confidentiality and
              integrity-added protections to help prevent access from processes
              at higher privilege levels."
                primaryTypographyProps={{
                  sx: {
                    fontSize: "inherit",
                  },
                }}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faMicrochip}
                  className={publishedAndProtectedDialogClasses.listIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary="Hardware-enhanced content protection capabilities help content
              owners with protecting their intellectual property through
              unaltered or unmodified streaming."
                primaryTypographyProps={{
                  sx: {
                    fontSize: "inherit",
                  },
                }}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faFileCertificate}
                  className={publishedAndProtectedDialogClasses.listIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary="Through attestation services it is also possible to receive
              verification on the identity of the application running in the
              enclave."
                primaryTypographyProps={{
                  sx: {
                    fontSize: "inherit",
                  },
                }}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon classes={listItemIconClasses}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faKey}
                  className={publishedAndProtectedDialogClasses.listIcon}
                />
              </ListItemIcon>
              <ListItemText
                primary="Data is end-to-end encrypted so nobody except data room participants — not even Decentriq — can decrypt and read it."
                primaryTypographyProps={{
                  sx: {
                    fontSize: "inherit",
                  },
                }}
              />
            </ListItem>
          </List>
        </Typography>
      </DialogContent>
      <Divider />
      <Button
        color="inherit"
        classes={linkButtonClasses}
        href="https://software.intel.com/content/dam/develop/public/us/en/documents/intel-sgx-product-brief-2019.pdf"
      >
        How Intel® SGX remote attestation works
        <FontAwesomeIcon
          fixedWidth
          icon={faExternalLinkSquare}
          className={publishedAndProtectedDialogClasses.linkButtonIcon}
        />
      </Button>
      <Divider />
      <Button color="inherit" classes={linkButtonClasses}>
        How Decentriq operates on Intel® SGX hardware
        <FontAwesomeIcon
          fixedWidth
          icon={faExternalLinkSquare}
          className={publishedAndProtectedDialogClasses.linkButtonIcon}
        />
      </Button>
      <Divider />
      <DialogActions classes={dialogActionsClasses}>
        <Button
          classes={buttonClasses}
          color="inherit"
          variant="outlined"
          onClick={onClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishedAndProtectedDialog;
