// @ts-nocheck
import { useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  faArrowLeft,
  faBarcodeAlt,
  faBell,
  faBookOpen,
  faChevronRight,
  faCog,
  faDesktopAlt,
  faGrid2,
  faKey,
  faMoon,
  faPalette,
  faQuestionCircle,
  faRocketLaunch,
  faSearch,
  faSignOut,
  faSun,
  faThumbtack,
  faUserSlash,
} from "@fortawesome/pro-light-svg-icons";
import {
  faBell as faBellSolid,
  faMoon as faMoonSolid,
  faSun as faSunSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Popover,
  Switch,
  Theme,
  ToggleButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean } from "ahooks";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback } from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import {
  ChangePasswordDialog,
  CustomBrandingLogo,
  DeleteAccountDialog,
  MainbarMenuItem,
} from "components";
import {
  ArchivedDataRoomsDrawerAccordion,
  FavoriteDataRoomsDrawerAccordion,
  MyDataRoomsDrawerAccordion,
  NewDataRoomMainbarMenuItem,
  SharedWithMeDataRoomsDrawerAccordion,
} from "containers";
import { useMain } from "contexts";
import { CHANGE_PASSWORD, DELETE_ACCOUNT } from "gqls";
import { useCustomDrawerStyles } from "styles/select";

const IS_FEATURE_SEARCH_ENABLED = false;
const IS_FEATURE_NOTIFICATIONS_ENABLED = false;
const IS_FEATURE_SETTINGS_ENABLED = false;
const IS_FEATURE_HELP_AND_SUPPORT_ENABLED = true;
const IS_FEATURE_DOCUMENTATION_ENABLED = true;
const IS_FEATURE_RELEASE_NOTES_ENABLED = true;
const IS_FEATURE_DATA_ROOMS_BROWSER_ENABLED = false;
const IS_FEATURE_FAVORITES_ENABLED = false;
const IS_FEATURE_MY_DATA_ROOMS_ENABLED = true;
const IS_FEATURE_SHARED_WITH_ME_ENABLED = true;
const IS_FEATURE_ARCHIVED_ENABLED = true;
const IS_FEATURE_THEME_ENABLED = false;
const IS_FEATURE_SHOW_ENTITIES_IDS_ENABLED = false;

// TODO: Configure MuiPaper in the <StyleWrapper />
const usePaperStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow:
      "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
  },
}));

const useBadgeStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "0.875rem",
  },
  badge: {
    position: (props) => ((props as any).inline ? "absolute" : "relative"),
    transform: (props) =>
      (props as any).inline
        ? "scale(1) translate(0%, -12.5%)"
        : "scale(1) translate(0%, -50%)",
    padding: (props) => !(props as any).inline && "0",
    minWidth: (props) => !(props as any).inline && "auto",
    height: (props) => !(props as any).inline && "1em",
  },
}));

const useSwitchStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(-0.5),
  },
}));

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    borderColor: "transparent",
    padding: theme.spacing(0, 0.5),
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    margin: theme.spacing(-0.5, 0),
    minWidth: "auto",
  },
}));

const useCardStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    border: "2px solid white",
    background: "transparent",
    fontSize: "0.75rem",
    fontWeight: 600,
    color: "white",
  },
}));

const UserCard = memo(({ user = {} }) => {
  const cardClasses = useCardStyles();
  const {
    // picture,
    name,
    email,
  } = user || {};
  return (
    <Box className={cardClasses.wrapper}>
      <Avatar
        alt={name}
        // src={picture}
        className={cardClasses.avatar}
      >
        {name.substr(0, 2).toUpperCase()}
      </Avatar>
      <Box>
        <Typography noWrap variant="body1" style={{ lineHeight: 1.125 }}>
          <strong>{name}</strong>
        </Typography>
        {email !== name ? (
          <Typography
            noWrap
            variant="body2"
            color="textSecondary"
            style={{
              lineHeight: 1.5,
              fontSize: 12,
              marginTop: -2,
              marginBottom: -2,
            }}
          >
            {email}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
});

const ThemeMainbarMenuItem = memo(() => {
  const paperClasses = usePaperStyles();
  const buttonClasses = useButtonStyles();
  const { isDarkThemeEnabled, setIsDarkThemeEnabled } = useMain();
  // Theme menu
  const {
    anchorEl: anchorElThemeMenu,
    isOpen: isOpenThemeMenu,
    open: openThemeMenu,
    close: closeThemeMenu,
  } = usePopupState({
    variant: "popover",
  });
  const onOpenThemeMenu = (event: any) => {
    event.stopPropagation();
    openThemeMenu(event.currentTarget);
  };
  const onCloseThemeMenu = (event: any) => {
    event.stopPropagation();
    closeThemeMenu(event.currentTarget);
  };
  // Actions
  const setIsDarkThemeEnabledAndCloseMenu = (value: any, event: any) => {
    setIsDarkThemeEnabled(value);
    closeThemeMenu(event.currentTarget);
  };
  return (
    <MainbarMenuItem
      wrapper={MenuItem}
      icon={faPalette}
      text={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flex: "1 1 auto",
            fontSize: "0.875rem",
          }}
        >
          <span>Theme</span>
          <Button
            classes={buttonClasses}
            color="inherit"
            variant="outlined"
            onClick={onOpenThemeMenu}
          >
            {isDarkThemeEnabled ? (
              <span>
                <FontAwesomeIcon
                  icon={faMoonSolid}
                  fixedWidth
                  style={{ marginRight: "4px" }}
                />
                Dark
              </span>
            ) : (
              <span>
                <FontAwesomeIcon
                  icon={faSunSolid}
                  fixedWidth
                  style={{ marginRight: "4px" }}
                />
                Light
              </span>
            )}
          </Button>
          <Popover
            anchorEl={anchorElThemeMenu}
            open={isOpenThemeMenu}
            onClose={onCloseThemeMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            marginThreshold={8}
            PaperProps={{ classes: paperClasses }}
          >
            <MenuList>
              <MainbarMenuItem
                wrapper={MenuItem}
                icon={faSun}
                text="Light"
                onClick={setIsDarkThemeEnabledAndCloseMenu.bind(this, false)}
              />
              <MainbarMenuItem
                wrapper={MenuItem}
                icon={faMoon}
                text="Dark"
                onClick={setIsDarkThemeEnabledAndCloseMenu.bind(this, true)}
              />
            </MenuList>
            <Divider />
            <MenuList>
              <MainbarMenuItem
                wrapper={MenuItem}
                icon={faDesktopAlt}
                text="Use system setting"
                disabled
              />
            </MenuList>
          </Popover>
        </Box>
      }
    />
  );
});

const NAVIGATION_DRAWER_VARIANT_TEMPORARY = "temporary";
const NAVIGATION_DRAWER_VARIANT_PERMANENT = "permanent";

const useDrawerStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "25%",
    minWidth: 360,
  },
  paper: {
    position: "relative",
    backgroundColor: "white",
  },
}));

const useNavigationDrawerStyles = makeStyles((theme: Theme) => ({
  navigationDrawer: {
    "&:hover $thumbtackButton": {
      opacity: 1,
    },
  },
  backButton: {
    marginRight: theme.spacing(-0.5),
  },
  thumbtackButton: {
    fontSize: "1rem",
    lineHeight: "normal",
    padding: theme.spacing(1),
    border: "none",
    borderRadius: "100%",
    transition: "opacity 0.4s ease-in-out",
    opacity: ({ isNavPermanent }) => (isNavPermanent ? 0.25 : 1),
  },
}));

const useUserCardAccordionStyles = makeStyles({
  root: {
    backgroundColor: "white",
  },
});
const useUserCardAccordionSummaryStyles = makeStyles({
  root: {
    backgroundColor: "#289090",
    color: "white",
  },
  expandIconWrapper: {
    color: "white",
  },
});

const NavigationDrawer = memo(() => {
  const drawerClasses = useDrawerStyles();
  const customDrawerClasses = useCustomDrawerStyles();
  const switchClasses = useSwitchStyles();
  const badgeInlineClasses = useBadgeStyles({ inline: true });
  const userCardAccordionClasses = useUserCardAccordionStyles();
  const userCardAccordionSummaryClasses = useUserCardAccordionSummaryStyles();
  const {
    showEntitiesIds,
    setShowEntitiesIds,
    navigationDrawerVariant,
    setNavigationDrawerVariantPermanent,
    setNavigationDrawerVariantTemporary,
    isNavigationDrawerOpen,
    closeNavigationDrawer,
  } = useMain();
  const { user = {}, logout, isAuthenticated } = useAuth0();
  const notificationsTotalCount = 6;
  // Navigation helpers
  const isNavTemporary =
    navigationDrawerVariant === NAVIGATION_DRAWER_VARIANT_TEMPORARY;
  const isNavPermanent =
    navigationDrawerVariant === NAVIGATION_DRAWER_VARIANT_PERMANENT;
  const onBackClick = closeNavigationDrawer;
  const onThumbtackClick = useCallback(() => {
    if (isNavTemporary) {
      setNavigationDrawerVariantPermanent();
    } else if (isNavPermanent) {
      closeNavigationDrawer();
      setNavigationDrawerVariantTemporary();
    }
  }, [
    closeNavigationDrawer,
    isNavPermanent,
    isNavTemporary,
    setNavigationDrawerVariantPermanent,
    setNavigationDrawerVariantTemporary,
  ]);
  // Navigation classes
  const navigationDrawerClasses = useNavigationDrawerStyles({ isNavPermanent });
  const { navigationDrawer, backButton, thumbtackButton } =
    navigationDrawerClasses;
  // Actions
  const { enqueueSnackbar } = useSnackbar();
  // Actions: Sign out
  const signOut = () =>
    logout({
      returnTo: window.location.origin,
    });
  // Actions: Change password
  const [
    isChangePasswordDialogOpen,
    { setFalse: closeChangePasswordDialog, setTrue: openChangePasswordDialog },
  ] = useBoolean(false);
  const [changePassword, { loading: isChangePasswordLoading }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: () => {
        openChangePasswordDialog();
      },
      onError: (error) => {
        enqueueSnackbar("Can't generate change password link 😔", {
          variant: "error",
        });
      },
    }
  );
  // Actions: Delete account
  const [
    isDeleteAccountDialogOpen,
    { setFalse: closeDeleteAccountDialog, setTrue: openDeleteAccountDialog },
  ] = useBoolean(false);
  const [deleteAccount, { loading: isDeleteAccountLoading }] = useMutation(
    DELETE_ACCOUNT,
    {
      onCompleted: () => {
        closeDeleteAccountDialog();
        signOut();
      },
      onError: (error) => {
        enqueueSnackbar("Can't delete account 😔", { variant: "error" });
      },
    }
  );
  // Actions: Settings
  const toggleShowEntitiesIds = (event: any) =>
    setShowEntitiesIds(event.target.checked || !showEntitiesIds);
  return (
    <Drawer
      classes={drawerClasses}
      className={navigationDrawer}
      anchor="left"
      variant={navigationDrawerVariant}
      open={isNavigationDrawerOpen}
      onClose={closeNavigationDrawer}
      ModalProps={{ disablePortal: true }}
    >
      <div className={customDrawerClasses.dialogContainer}>
        <div className={customDrawerClasses.dialogTitle}>
          <Link component={RouterLink} to="/" color="inherit">
            <CustomBrandingLogo />
          </Link>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isNavTemporary ? (
              <Tooltip
                title="Slide out"
                placement="bottom"
                disableFocusListener
                disableTouchListener
                enterDelay={750}
              >
                <IconButton className={backButton} onClick={onBackClick}>
                  <FontAwesomeIcon fixedWidth icon={faArrowLeft} />
                </IconButton>
              </Tooltip>
            ) : null}
            <Tooltip
              title={isNavTemporary ? "Pin" : isNavPermanent ? "Unpin" : ""}
              placement="bottom"
              disableFocusListener
              disableTouchListener
              enterDelay={750}
            >
              <ToggleButton
                value="pinned"
                selected={isNavPermanent}
                className={thumbtackButton}
                onClick={onThumbtackClick}
              >
                <FontAwesomeIcon fixedWidth icon={faThumbtack} />
              </ToggleButton>
            </Tooltip>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          <MenuList>
            {IS_FEATURE_SEARCH_ENABLED ? (
              <MainbarMenuItem
                wrapper={MenuItem}
                onClick={() => alert("Not implemented yet")}
                icon={faSearch}
                text="Search"
              />
            ) : null}
            {IS_FEATURE_NOTIFICATIONS_ENABLED ? (
              <MainbarMenuItem
                wrapper={MenuItem}
                onClick={() => alert("Not implemented yet")}
                icon={notificationsTotalCount > 0 ? faBellSolid : faBell}
                text={
                  <Badge
                    badgeContent={notificationsTotalCount}
                    classes={badgeInlineClasses}
                    color="error"
                    max={Infinity}
                  >
                    Notifications
                  </Badge>
                }
              />
            ) : null}
            {IS_FEATURE_SETTINGS_ENABLED ? (
              <MainbarMenuItem
                wrapper={MenuItem}
                onClick={() => alert("Not implemented yet")}
                icon={faCog}
                text="Settings"
              />
            ) : null}
            {IS_FEATURE_HELP_AND_SUPPORT_ENABLED ? (
              <MainbarMenuItem
                wrapper={MenuItem}
                onClick={() => alert("Not implemented yet")}
                icon={faQuestionCircle}
                text="Help &amp; support"
              />
            ) : null}
            {IS_FEATURE_DOCUMENTATION_ENABLED ? (
              <MainbarMenuItem
                wrapper={MenuItem}
                onClick={() => alert("Not implemented yet")}
                icon={faBookOpen}
                text="Documentation"
              />
            ) : null}
            {IS_FEATURE_RELEASE_NOTES_ENABLED ? (
              <MainbarMenuItem
                wrapper={MenuItem}
                onClick={() => alert("Not implemented yet")}
                icon={faRocketLaunch}
                text="Release notes"
              />
            ) : null}
          </MenuList>
          {IS_FEATURE_DATA_ROOMS_BROWSER_ENABLED ? (
            <Fragment>
              <MenuList style={{ paddingBottom: 0 }}>
                <MainbarMenuItem
                  wrapper={MenuItem}
                  component={NavLink}
                  to="/datarooms"
                  end
                  icon={faGrid2}
                  text="Data rooms browser"
                />
              </MenuList>
            </Fragment>
          ) : null}
          <Box sx={{ flex: "1 1 0", overflow: "auto" }}>
            {IS_FEATURE_FAVORITES_ENABLED ? (
              <Fragment>
                <FavoriteDataRoomsDrawerAccordion compact />
              </Fragment>
            ) : null}
            {IS_FEATURE_MY_DATA_ROOMS_ENABLED ? (
              <Fragment>
                <MyDataRoomsDrawerAccordion compact />
              </Fragment>
            ) : null}
            {IS_FEATURE_SHARED_WITH_ME_ENABLED ? (
              <SharedWithMeDataRoomsDrawerAccordion compact />
            ) : null}
            {IS_FEATURE_ARCHIVED_ENABLED ? (
              <ArchivedDataRoomsDrawerAccordion compact />
            ) : null}
          </Box>
          {isAuthenticated ? (
            <Fragment>
              <MenuList>
                <NewDataRoomMainbarMenuItem />
              </MenuList>
              <Accordion square classes={userCardAccordionClasses}>
                <AccordionSummary
                  classes={userCardAccordionSummaryClasses}
                  expandIcon={
                    <IconButton color="inherit">
                      <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                    </IconButton>
                  }
                >
                  <UserCard user={user} />
                </AccordionSummary>
                <AccordionDetails>
                  <MenuList>
                    {IS_FEATURE_THEME_ENABLED ? <ThemeMainbarMenuItem /> : null}
                    {IS_FEATURE_SHOW_ENTITIES_IDS_ENABLED ? (
                      <MainbarMenuItem
                        wrapper={MenuItem}
                        onClick={toggleShowEntitiesIds}
                        icon={faBarcodeAlt}
                        text={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flex: "1 1 auto",
                              fontSize: "0.875rem",
                            }}
                          >
                            <span>Show entities IDs</span>
                            <Switch
                              color="default"
                              classes={switchClasses}
                              size="small"
                              checked={showEntitiesIds}
                              onChange={toggleShowEntitiesIds}
                            />
                          </Box>
                        }
                      />
                    ) : null}
                    <MainbarMenuItem
                      wrapper={MenuItem}
                      onClick={changePassword}
                      icon={
                        isChangePasswordLoading ? (
                          <CircularProgress
                            size={16}
                            color="inherit"
                            thickness={2}
                          />
                        ) : (
                          faKey
                        )
                      }
                      text="Change password"
                    />
                    <ChangePasswordDialog
                      open={isChangePasswordDialogOpen}
                      onClose={closeChangePasswordDialog}
                    />
                    <MainbarMenuItem
                      wrapper={MenuItem}
                      onClick={openDeleteAccountDialog}
                      icon={faUserSlash}
                      text="Delete account..."
                      style={{ color: "red" }}
                    />
                    <DeleteAccountDialog
                      open={isDeleteAccountDialogOpen}
                      loading={isDeleteAccountLoading}
                      onCancel={closeDeleteAccountDialog}
                      onConfirm={deleteAccount}
                    />
                    <MainbarMenuItem
                      wrapper={MenuItem}
                      onClick={signOut}
                      icon={faSignOut}
                      text="Sign out"
                    />
                  </MenuList>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          ) : null}
        </Box>
      </div>
    </Drawer>
  );
});

export default NavigationDrawer;
