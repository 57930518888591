// @ts-nocheck
import { DataRoomsList, DataRoomsTable } from "components";

const DataRooms = ({ as, ...rest }) => {
  switch (as) {
    case "list":
      return <DataRoomsList {...rest} />;
    case "table":
    default:
      return <DataRoomsTable {...rest} />;
  }
};

DataRooms.List = (props) => <DataRoomsList {...props} />;

DataRooms.Table = (props) => <DataRoomsTable {...props} />;

export default DataRooms;
