import { DataRoomParticipantDefinition } from "models";
import * as yup from "yup";

export const dataRoomParticipantValidationScheme: yup.SchemaOf<DataRoomParticipantDefinition> =
  yup
    .object()
    .noUnknown()
    .shape({
      userEmail: yup
        .string()
        .trim()
        .required("Participant email is required")
        .email("Invalid email")
        .test(
          "is-email-unique",
          ({ value }) => `Participant email ${value} already exists`,
          (value, test) => {
            const isUnique = !(test.options.context?.emails as string[])
              .map((e) => e.toLowerCase())
              .includes((value as string).toLowerCase());
            return isUnique;
          }
        ),
      tables: yup.array(),
      queries: yup.array(),
    })
    .defined();
