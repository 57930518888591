import { useAuth0 } from "@auth0/auth0-react";
import { DisWaConnector, Key } from "@decentriq/safequery/lib/api";
import { memo, useState } from "react";
import { ApiCoreProvider, GetClientOptions } from "contexts/apicore/apicore";
import { useConfiguration } from "contexts/configuration/configuration";

export interface ApiCoreWrapperOptions {
  children?: React.ReactNode;
}

type ClientsCache = Record<string, DisWaConnector>;

const ApiCoreWrapper = memo<ApiCoreWrapperOptions>(({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    configuration: { diswaHost, diswaPort, diswaUseTls },
  } = useConfiguration();
  const { email: currentUserEmail = "" } = user || {};
  const [clientsCache, setClientsCache] = useState<ClientsCache>({});

  const getClient = async (options?: GetClientOptions) => {
    const email = currentUserEmail;
    const clientIdentifier = `${currentUserEmail}-${JSON.stringify(options)}`;
    console.log("CLients cache", clientsCache);
    if (clientIdentifier in clientsCache) {
      return clientsCache[clientIdentifier];
    }
    console.log("Connecting...");
    const token = await getAccessTokenSilently();
    const encryptionKey = await Key.create();
    const client = DisWaConnector.withUserToken(
      token,
      email,
      encryptionKey,
      diswaHost,
      diswaPort,
      diswaUseTls
    );
    await client.connect(
      {
        verificationOptions: {
          acceptDebug: true,
          acceptGroupOutOfDate: true,
          acceptConfigurationNeeded: true,
        },
      },
      options?.password
    );
    setClientsCache((prev) => {
      return {
        [clientIdentifier]: client,
        ...prev,
      };
    });
    return client;
  };

  return (
    <ApiCoreProvider value={{ getClient: getClient }}>
      {children}
    </ApiCoreProvider>
  );
});

export default ApiCoreWrapper;
