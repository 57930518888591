import { gql } from "@apollo/client";

export const DATA_ROOM_QUERY_SHARE_EVERYTHING = gql`
  fragment DataRoomQueryShareEverything on DataRoomQueryShare {
    id
    dataRoomQueryShareId
    dataRoomQueryId
    userEmail
    updatedAt
    createdAt
    ownerEmail
  }
`;
