import { Box, Divider, List, ListItem, Typography } from "@material-ui/core";
import { DataRoomTableColumnDefinition } from "models";
import { Fragment, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DataRoomTableColumnForm from "./DataRoomTableColumnForm";
import DataRoomTableColumnTile from "./DataRoomTableColumnTile";

interface DataRoomTableColumnConstructorProps {
  value: DataRoomTableColumnDefinition[];
  onChange: (value: DataRoomTableColumnDefinition[]) => void;
  readonly?: boolean;
}

export const DataRoomTableColumnConstructor: React.FC<DataRoomTableColumnConstructorProps> =
  ({ value, onChange, readonly }) => {
    const [rowToEditIndex, setRowToEditIndex] = useState<number>(-1);
    const hasEditableColumn = rowToEditIndex !== -1;
    const columnNames = useMemo(
      () => value.map((c) => c.name).filter((_, i) => i !== rowToEditIndex),
      [value, rowToEditIndex]
    );
    useEffect(() => {
      if (readonly && hasEditableColumn) {
        setRowToEditIndex(-1);
      }
    }, [readonly, setRowToEditIndex, hasEditableColumn]);
    return (
      <Box>
        <DndProvider backend={HTML5Backend}>
          {value.length > 0 ? (
            <List style={{ background: "#fff", padding: 0 }}>
              {value.map((column, index) => (
                <Fragment key={index}>
                  {rowToEditIndex === index ? (
                    <ListItem>
                      <DataRoomTableColumnForm
                        columnNames={columnNames}
                        onSubmit={(column) => {
                          setRowToEditIndex(-1);
                          const valueToSet = [...value];
                          valueToSet[index] = column;
                          onChange(valueToSet);
                        }}
                        isEditing
                        onCancel={() => setRowToEditIndex(-1)}
                        initialValues={column}
                      ></DataRoomTableColumnForm>
                    </ListItem>
                  ) : (
                    <DataRoomTableColumnTile
                      index={index}
                      column={column}
                      readonly={readonly}
                      allowEditing={!hasEditableColumn && !readonly}
                      onDelete={(index) => {
                        onChange([
                          ...value.slice(0, index),
                          ...value.slice(index + 1),
                        ]);
                      }}
                      onEdit={setRowToEditIndex}
                      onShuffle={(dI, hI) => {
                        const result = Array.from(value);
                        const [column] = result.splice(dI, 1);
                        result.splice(hI, 0, column);
                        onChange(result);
                      }}
                    />
                  )}
                  {index !== value.length - 1 && <Divider />}
                </Fragment>
              ))}
            </List>
          ) : readonly ? (
            <Typography variant="body1">This table has no columns</Typography>
          ) : null}
          {!readonly && (
            <>
              <List
                style={{
                  background: "#f9f9f9",
                  padding: "16px",
                }}
              >
                <ListItem style={{ padding: 0 }}>
                  <DataRoomTableColumnForm
                    columnNames={columnNames}
                    onSubmit={(column) => onChange([...value, column])}
                    disabled={hasEditableColumn}
                  ></DataRoomTableColumnForm>
                </ListItem>
              </List>
            </>
          )}
        </DndProvider>
      </Box>
    );
  };
