import {
  faChevronDown,
  faChevronRight,
  faPlus,
  faQuestion,
  faTable,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Theme } from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { forwardRef, useImperativeHandle, useState } from "react";

const useTreeViewStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const useContentPaneStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  labelIcon: {
    marginRight: theme.spacing(1),
  },
}));

const DataRoomContentPaneItemLabel = ({ label, icon }: any) => {
  const contentPaneClasses = useContentPaneStyles();
  return (
    <Box>
      <FontAwesomeIcon
        icon={icon}
        fixedWidth
        className={contentPaneClasses.labelIcon}
      ></FontAwesomeIcon>
      {label}
    </Box>
  );
};

const DataRoomContentPane = (
  {
    queries,
    tables,
    onTableAdded,
    onQueryAdded,
    onTableSelected,
    onQuerySelected,
  }: any,
  ref: any
) => {
  const contentPaneClasses = useContentPaneStyles();
  const treeViewClasses = useTreeViewStyles();
  const [selected, setSelected] = useState(null);
  const addTableNodeId = "add-table";
  const addQueryNodeId = "add-query";
  const handleSelect = (_: any, nodeId: any) => {
    const isRegularNode = ![addTableNodeId, addQueryNodeId].includes(nodeId);
    if (isRegularNode) {
      setSelected(nodeId);
      return;
    }

    if (nodeId === addTableNodeId) {
      onTableAdded?.();
    }

    if (nodeId === addQueryNodeId) {
      onQueryAdded?.();
    }

    setSelected(null);
  };
  useImperativeHandle(ref, () => ({
    selectTable: (id: any) => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setSelected(`t-${id}`);
    },
    selectQuerry: (id: any) => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setSelected(`q-${id}`);
    },
  }));
  return (
    <Box component="div" className={contentPaneClasses.wrapper}>
      <TreeView
        className={treeViewClasses.root}
        defaultExpandIcon={<FontAwesomeIcon icon={faChevronRight} fixedWidth />}
        defaultCollapseIcon={
          <FontAwesomeIcon icon={faChevronDown} fixedWidth />
        }
        onNodeSelect={handleSelect}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | st... Remove this comment to see the full error message
        selected={selected}
      >
        <TreeItem nodeId="tables" label="Schemas">
          {tables.map((table: any, i: any) => (
            <TreeItem
              key={i}
              nodeId={`t-${i}`}
              label={
                <DataRoomContentPaneItemLabel
                  icon={faTable}
                  label={table.name}
                />
              }
              onClick={() => onTableSelected?.(table, i)}
            />
          ))}
          <TreeItem
            key={addTableNodeId}
            nodeId={addTableNodeId}
            label={
              <DataRoomContentPaneItemLabel icon={faPlus} label="Add schema" />
            }
          />
        </TreeItem>
        <TreeItem nodeId="queries" label="Queries">
          {queries.map((query: any, i: any) => (
            <TreeItem
              key={i}
              nodeId={`q-${i}`}
              label={
                <DataRoomContentPaneItemLabel
                  icon={faQuestion}
                  label={query.name}
                />
              }
              onClick={() => onQuerySelected?.(query, i)}
            />
          ))}
          <TreeItem
            key={addQueryNodeId}
            nodeId={addQueryNodeId}
            label={
              <DataRoomContentPaneItemLabel icon={faPlus} label="Add query" />
            }
          />
        </TreeItem>
      </TreeView>
    </Box>
  );
};

export default forwardRef(DataRoomContentPane);
