// @ts-nocheck
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({ element }: any) => {
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuth0();
  return isLoading ? null : isAuthenticated ? (
    <Route element={element} />
  ) : (
    <Navigate to={{ pathname: "/sign-in", state: { referer: location } }} />
  );
};

export default PrivateRoute;
