import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_EVERYTHING } from "./dataRoomQueryEverything";
import { DATA_ROOM_SHARE_EVERYTHING } from "./dataRoomShareEverything";
import { DATA_ROOM_TABLE_EVERYTHING } from "./dataRoomTableEverything";

export const DATA_ROOM_EVERYTHING = gql`
  fragment DataRoomEverything on DataRoom {
    id
    dataRoomId
    name
    description
    isPublished
    isOwner
    isParticipant
    isFavorite
    isArchived
    hasPassword
    updatedAt
    createdAt
    mrenclave
    ownerEmail
    dataRoomShares(orderBy: [USER_EMAIL_ASC]) {
      nodes {
        ...DataRoomShareEverything
      }
    }
    dataRoomTables(orderBy: [INDEX_ASC]) {
      nodes {
        ...DataRoomTableEverything
      }
    }
    dataRoomQueries(orderBy: [INDEX_ASC]) {
      nodes {
        ...DataRoomQueryEverything
      }
    }
    dataRoomHash
    mrenclave
    dataRoomShareId
  }
  ${DATA_ROOM_SHARE_EVERYTHING}
  ${DATA_ROOM_TABLE_EVERYTHING}
  ${DATA_ROOM_QUERY_EVERYTHING}
`;
