// @ts-nocheck
import {
  faEnvelope,
  faPhone,
  faUserCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@material-ui/core";
import { memo } from "react";
import {
  BlockLoading,
  ContactActions,
  Id,
  InlineEditor,
  Meta,
  MetaGroup,
  MetaWrapper,
} from "components";
import { useBlockStyles } from "styles/block";
import { useContactBlockStyles } from "./ContactBlock.styles";

const ContactBlock = ({
  loading = false,
  contact,
  withMeta = false,
  withAttributes = false,
  nameWeight,
}: any) => {
  const blockClasses = useBlockStyles();
  const contactBlockClasses = useContactBlockStyles();
  const { id, contactId, name, phone, email } = contact || {};
  return loading ? (
    <BlockLoading withMeta={withMeta} withAttributes={withAttributes} />
  ) : (
    <div className={blockClasses.wrapper}>
      {withAvatar && (
        <Avatar className={contactBlockClasses.avatar}>
          <FontAwesomeIcon fixedWidth icon={faUserCircle} />
        </Avatar>
      )}
      <div className={contactBlockClasses.info}>
        <div className={blockClasses.wrapper}>
          <div className={blockClasses.nameMetaActionsWrapper}>
            <div className={blockClasses.nameActionsWrapper}>
              <div className={blockClasses.nameWrapper}>
                <InlineEditor
                  value={name || ""}
                  endAdornment={<Id id={contactId} />}
                />
              </div>
              <div className={blockClasses.actionsWrapper}>
                <ContactActions id={id} contactId={contactId} inline />
              </div>
            </div>
            {(withAttributes || withMeta) && (
              <MetaWrapper>
                <MetaGroup>
                  {Boolean(phone) && (
                    <Meta
                      icon={<FontAwesomeIcon icon={faPhone} />}
                      value={phone}
                    />
                  )}
                  {Boolean(email) && (
                    <Meta
                      icon={<FontAwesomeIcon icon={faEnvelope} />}
                      value={email}
                    />
                  )}
                </MetaGroup>
              </MetaWrapper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ContactBlock);
