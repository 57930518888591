// @ts-nocheck
import { faCheck, faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Fragment, memo } from "react";

const usePaginationMenuIconStyles = makeStyles((theme: Theme) => ({
  moreButton: {
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
  },
}));

const PaginationMenuIcon = memo(({ onClick }) => {
  const paginationMenuIconClasses = usePaginationMenuIconStyles();
  return (
    <IconButton
      color="inherit"
      onClick={onClick}
      className={paginationMenuIconClasses.moreButton}
      TouchRippleProps={{ center: false }}
    >
      <FontAwesomeIcon fixedWidth icon={faEllipsisV} />
    </IconButton>
  );
});

const usePaginationMenuItemStyles = makeStyles((theme: Theme) => ({
  menuItemIcon: {
    margin: theme.spacing(0.375, 0),
  },
  menuItemLabel: {
    marginLeft: (props) =>
      props.isSelected ? theme.spacing(1) : theme.spacing(3),
  },
  menuItemLabelTitle: {
    fontWeight: (props) => (props.isSelected ? 500 : 400),
  },
}));

const PaginationMenuItem = memo(({ isSelected, label, onClick }) => {
  const paginationMenuItemClasses = usePaginationMenuItemStyles({
    isSelected,
  });
  return (
    <MenuItem button dense selected={isSelected} onClick={onClick}>
      {isSelected && (
        <FontAwesomeIcon
          fixedWidth
          icon={faCheck}
          className={paginationMenuItemClasses.menuItemIcon}
        />
      )}
      <div className={paginationMenuItemClasses.menuItemLabel}>
        <div className={paginationMenuItemClasses.menuItemLabelTitle}>
          {label} items
        </div>
      </div>
    </MenuItem>
  );
});

// TODO: Configure MuiPaper in the <StyleWrapper />
const usePaperStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow:
      "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
  },
}));

const usePaginationMenuStyles = makeStyles((theme: Theme) => ({
  groupName: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    lineHeight: 1,
    fontWeight: 500,
    fontSize: "small",
  },
}));

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const PaginationMenu = memo(({ pageSize, setPageSize }) => {
  const paperClasses = usePaperStyles();
  const paginationMenuClasses = usePaginationMenuStyles();
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const menuOpen = (event) => {
    event.stopPropagation();
    open(event.currentTarget);
  };
  const menuClose = (event) => {
    event.stopPropagation();
    close();
  };
  const menuItemClick = (pageSizeOption, event) => {
    event.stopPropagation();
    setPageSize(pageSizeOption);
    close();
  };
  return (
    <Fragment>
      <Tooltip
        title="Settings"
        placement="top-start"
        disableFocusListener
        disableTouchListener
      >
        <div>
          <PaginationMenuIcon onClick={menuOpen} />
        </div>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        marginThreshold={8}
        PaperProps={{ classes: paperClasses }}
      >
        <div className={paginationMenuClasses.groupName}>Show up to</div>
        <MenuList>
          {PAGE_SIZE_OPTIONS.map((pageSizeOption) => (
            <PaginationMenuItem
              key={pageSizeOption}
              isSelected={pageSize === pageSizeOption}
              label={pageSizeOption}
              onClick={menuItemClick.bind(null, pageSizeOption)}
            />
          ))}
        </MenuList>
      </Popover>
    </Fragment>
  );
});

export default PaginationMenu;
