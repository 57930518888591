// @ts-nocheck
import { faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { Meta, MetaGroup, MetaWrapper } from "components";

const DataRoomMeta = memo(
  ({ withAttributes = true, attributes = {}, withMeta = true, meta = {} }) => {
    const { dataRoomTablesTotalCount } = meta;
    return (
      <MetaWrapper>
        {withAttributes && attributes ? (
          <MetaGroup>
            {attributes.nodes?.map(({ name, value }) => (
              <Meta key={`${name}:${value}`} label={name} value={value} pill />
            ))}
          </MetaGroup>
        ) : null}
        {withMeta && meta ? (
          <MetaGroup>
            {Boolean(hivecellsTotalDiskSpace) && (
              <Meta
                tooltip="Tables"
                icon={<FontAwesomeIcon icon={faTable} />}
                value={dataRoomTablesTotalCount}
              />
            )}
          </MetaGroup>
        ) : null}
      </MetaWrapper>
    );
  }
);

export default DataRoomMeta;
