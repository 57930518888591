// @ts-nocheck
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Skeleton,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";
import { ExpandIcon } from "components";
import { DataRooms } from "containers";
import { DATA_ROOMS_TOTAL_COUNT } from "gqls";
import {
  useDrawerAccordionDetailsContentStyles,
  useDrawerAccordionDetailsStyles,
  useDrawerAccordionStyles,
  useDrawerAccordionSummaryContentStyles,
  useDrawerAccordionSummaryStyles,
} from "styles/drawerAccordion";
import MyDataRoomsActions from "./MyDataRoomsActions";

const useSkeletonStyles = makeStyles((theme: Theme) => ({
  rectangular: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const SHOW_DATA_ROOMS_DIVIDER = false;

const MyDataRoomsDrawerAccordion = memo(({ compact = false }) => {
  const drawerAccordionClasses = useDrawerAccordionStyles();
  const drawerAccordionSummaryClasses = useDrawerAccordionSummaryStyles({
    compact,
    hasOwnExpandIcon: false,
  });
  const drawerAccordionDetailsClasses = useDrawerAccordionDetailsStyles();
  const skeletonClasses = useSkeletonStyles();
  const drawerAccordionSummaryContentClasses =
    useDrawerAccordionSummaryContentStyles();
  const {
    wrapper: summaryWrapper,
    nameWrapper,
    totalCountActionsWrapper,
    totalCountWrapper,
    actionsWrapper,
  } = drawerAccordionSummaryContentClasses;
  const drawerAccordionDetailsContentClasses =
    useDrawerAccordionDetailsContentStyles();
  const { wrapper: detailsWrapper } = drawerAccordionDetailsContentClasses;
  const { user = {} } = useAuth0();
  const { email: currentEmail } = user || {};
  const dataRoomsVariables = {
    filter: {
      isArchived: { equalTo: false },
      ownerEmail: { equalTo: currentEmail },
    },
  };
  const { data, loading } = useQuery(DATA_ROOMS_TOTAL_COUNT, {
    variables: dataRoomsVariables,
  });
  const dataRoomsTotalCount = data?.dataRooms?.totalCount || 0;
  return (
    <Accordion
      classes={drawerAccordionClasses}
      defaultExpanded={dataRoomsTotalCount > 0}
      disabled={dataRoomsTotalCount === 0}
      elevation={0}
      square
    >
      <AccordionSummary
        classes={drawerAccordionSummaryClasses}
        expandIcon={<ExpandIcon />}
      >
        <div className={summaryWrapper}>
          <div className={nameWrapper}>My data rooms</div>
          <div className={totalCountActionsWrapper}>
            <div className={actionsWrapper}>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={24}
                  width={24}
                  classes={skeletonClasses}
                />
              ) : (
                <MyDataRoomsActions inline />
              )}
            </div>
            <div className={totalCountWrapper}>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={16}
                  width={20}
                  classes={skeletonClasses}
                />
              ) : (
                dataRoomsTotalCount
              )}
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={drawerAccordionDetailsClasses}>
        {SHOW_DATA_ROOMS_DIVIDER ? <Divider /> : null}
        <div className={detailsWrapper}>
          <DataRooms
            variables={dataRoomsVariables}
            views={[
              {
                name: "compact",
                label: "Compact",
                isDisabled: true,
                isSelected: true,
              },
              {
                name: "detailed",
                label: "Detailed",
                info: "Includes meta info and tags",
                isDisabled: true,
                isSelected: false,
              },
            ]}
            columns={[
              {
                name: "dataRoom",
                label: "DataRoom",
                isDisabled: true,
                isSelected: true,
              },
              {
                name: "owner",
                label: "Owner",
                isDisabled: true,
                isSelected: false,
              },
              {
                name: "participants",
                label: "Participants",
                isDisabled: true,
                isSelected: false,
              },
            ]}
            withSelections={false}
            withMultiselections={false}
            TableHeadRow={() => null}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
});

export default MyDataRoomsDrawerAccordion;
