// @ts-nocheck
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useBoolean, useHover } from "ahooks";
import { get, set } from "lodash";
import { usePopupState } from "material-ui-popup-state/hooks";
import { forwardRef, Fragment, memo, useCallback, useRef } from "react";
import { isElement, isValidElementType } from "react-is";
import { useKeyPressEvent } from "react-use";

const figureOutTooltipTitle = (name, tooltipTitle) =>
  typeof name === "string" && typeof tooltipTitle === "string"
    ? name.replace(/\.\.\.$/, "") === tooltipTitle.replace(/\.\.\.$/, "")
      ? ""
      : tooltipTitle || ""
    : tooltipTitle || "";

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: ({ inline }) =>
      inline ? theme.spacing(0, 0.5) : theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    minWidth: 0,
    color: ({ isRed }) => (isRed ? "red" : "inherit"),
  },
  startIcon: {
    marginLeft: 0,
  },
}));

const ActionsButtonInner = forwardRef(({ states = {} }, ref) => {
  const isHovering = useHover(ref);
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { normal, onHover, onShift } = states;
  const {
    icon,
    icon: Icon,
    inline,
    handle,
    name,
    isRed,
  } = {
    ...normal,
    ...(isHovering ? onHover : {}),
    ...(isShiftDown ? onShift : {}),
  };
  const buttonClasses = useButtonStyles({ inline, isRed });
  return (
    <Button
      classes={buttonClasses}
      color="inherit"
      onClick={handle}
      ref={ref}
      startIcon={
        isElement(icon) ? (
          icon
        ) : isValidElementType(icon) ? (
          <Icon />
        ) : (
          <FontAwesomeIcon icon={icon} />
        )
      }
    >
      {name}
    </Button>
  );
});

const ActionsButton = memo(({ children = null, states = {} }) => {
  const ref = useRef();
  const isHovering = useHover(ref);
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { normal, onHover, onShift } = states;
  const { name, tooltipTitle, tooltipPlacement } = {
    ...normal,
    ...(isHovering ? onHover : {}),
    ...(isShiftDown ? onShift : {}),
  };
  return (
    <Fragment>
      <Tooltip
        title={figureOutTooltipTitle(name, tooltipTitle)}
        placement={tooltipPlacement}
        disableFocusListener
        disableTouchListener
      >
        <div style={{ display: "flex" }}>
          <ActionsButtonInner ref={ref} states={states} />
        </div>
      </Tooltip>
      {children}
    </Fragment>
  );
});

const useIconButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: ({ inline }) => theme.spacing(inline ? 0.5 : 1),
    borderRadius: ({ square }) => (square ? theme.shape.borderRadius : "100%"),
    minWidth: 0,
    color: ({ isRed }) => (isRed ? "red" : "inherit"),
  },
}));

const ActionsIconButtonInner = forwardRef(({ states = {} }, ref) => {
  const isHovering = useHover(ref);
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { normal, onHover, onShift } = states;
  const {
    icon,
    icon: Icon,
    inline,
    handle,
    square,
    circle,
    isRed,
  } = {
    ...normal,
    ...(isHovering ? onHover : {}),
    ...(isShiftDown ? onShift : {}),
  };
  const iconButtonClasses = useIconButtonStyles({
    inline,
    square,
    circle,
    isRed,
  });
  return (
    <IconButton
      classes={iconButtonClasses}
      color="inherit"
      onClick={handle}
      ref={ref}
      TouchRippleProps={{ center: !inline }}
    >
      {isElement(icon) ? (
        icon
      ) : isValidElementType(icon) ? (
        <Icon />
      ) : (
        <FontAwesomeIcon fixedWidth icon={icon} />
      )}
    </IconButton>
  );
});

const ActionsIconButton = memo(({ children = null, states = {} }) => {
  const ref = useRef();
  const isHovering = useHover(ref);
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { normal, onHover, onShift } = states;
  const { tooltipTitle, tooltipPlacement } = {
    ...normal,
    ...(isHovering ? onHover : {}),
    ...(isShiftDown ? onShift : {}),
  };
  return (
    <Fragment>
      <Tooltip
        title={tooltipTitle}
        placement={tooltipPlacement}
        disableFocusListener
        disableTouchListener
      >
        <div style={{ display: "flex" }}>
          <ActionsIconButtonInner ref={ref} states={states} />
        </div>
      </Tooltip>
      {children}
    </Fragment>
  );
});

const useMenuItemStyles = makeStyles((theme: Theme) => ({
  root: {
    color: ({ isRed }) => (isRed ? "red" : "inherit"),
  },
}));

const useActionsMenuItemStyles = makeStyles((theme: Theme) => ({
  iconNameWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    height: "1.25rem",
    marginRight: theme.spacing(1),
  },
  nameWrapper: {
    lineHeight: "1.25rem",
  },
}));

const ActionsMenuItemInner = forwardRef(({ states = {} }, ref) => {
  const isHovering = useHover(ref);
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { normal, onHover, onShift } = states;
  const {
    icon,
    icon: Icon,
    name,
    handle,
    isRed,
  } = {
    ...normal,
    ...(isHovering ? onHover : {}),
    ...(isShiftDown ? onShift : {}),
  };
  const menuItemClasses = useMenuItemStyles({
    isRed,
  });
  const actionsMenuItemClasses = useActionsMenuItemStyles();
  const { iconNameWrapper, iconWrapper, nameWrapper } = actionsMenuItemClasses;
  return (
    <MenuItem
      button={typeof handle === "function"}
      classes={menuItemClasses}
      dense
      onClick={handle}
      ref={ref}
      style={{ display: "block" }}
    >
      <div className={iconNameWrapper}>
        {icon ? (
          <div className={iconWrapper}>
            {isElement(icon) ? (
              icon
            ) : isValidElementType(icon) ? (
              <Icon />
            ) : (
              <FontAwesomeIcon fixedWidth icon={icon} />
            )}
          </div>
        ) : null}
        {name ? <div className={nameWrapper}>{name}</div> : null}
      </div>
    </MenuItem>
  );
});

const ActionsMenuItem = memo(({ children = null, states = {} }) => {
  const ref = useRef();
  const isHovering = useHover(ref);
  const [
    isShiftDown,
    { setTrue: setIsShiftDownTrue, setFalse: setIsShiftDownFalse },
  ] = useBoolean(false);
  useKeyPressEvent("Shift", setIsShiftDownTrue, setIsShiftDownFalse);
  const { normal, onHover, onShift } = states;
  const { name, tooltipTitle, tooltipPlacement } = {
    ...normal,
    ...(isHovering ? onHover : {}),
    ...(isShiftDown ? onShift : {}),
  };
  return (
    <Fragment>
      <Tooltip
        title={figureOutTooltipTitle(name, tooltipTitle)}
        placement={tooltipPlacement}
        disableFocusListener
        disableTouchListener
      >
        <div>
          <ActionsMenuItemInner ref={ref} states={states} />
        </div>
      </Tooltip>
      {children}
    </Fragment>
  );
});

const useActionsStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    display: "flex",
  },
}));

// TODO: Configure MuiPaper in the <StyleWrapper />
const usePaperStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow:
      "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
  },
}));

// TODO: perhaps add focused state too 🥵
// TODO: split this module by components
// TODO: add memoization, figure out refs
// TODO: add text blocks for simple info like in Notion
// TODO: dedup buttons and menu items, i.e. if button is present then hide the menu item
const Actions = memo(
  ({
    inline = false,
    moreTooltipTitle = "Actions",
    moreTooltipPlacement = "top",
    moreIcon = faEllipsisV,
    actions = {},
    children,
  }) => {
    const actionsClasses = useActionsStyles();
    const { actionsWrapper } = actionsClasses;
    const paperClasses = usePaperStyles();
    const { anchorEl, isOpen, open, close } = usePopupState({
      variant: "popover",
    });
    const menuOpen = useCallback(
      (event) => {
        event.stopPropagation();
        open(event.currentTarget);
      },
      [open]
    );
    const menuClose = useCallback(
      (event) => {
        event.stopPropagation();
        close(event.currentTarget);
      },
      [close]
    );
    const menuItemClick = useCallback(
      (handle, event) => {
        event.stopPropagation();
        close(event.currentTarget);
        handle(event);
      },
      [close]
    );
    const buttonClick = useCallback((handle, event) => {
      event.stopPropagation();
      handle(event);
    }, []);
    const wrapStatesHandles = useCallback((states, parentHandle = () => {}) => {
      ["normal.handle", "onHover.handle", "onShift.handle"].forEach(
        (handlePath) => {
          const handle = get(states, handlePath);
          if (typeof handle === "function") {
            set(states, handlePath, parentHandle.bind(this, handle));
          }
        }
      );
    }, []);
    const stopPropagation = useCallback((event) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }, []);
    const { buttons = [], menuLists = [] } = actions;
    return (
      <div
        className={actionsWrapper}
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
        onMouseUp={stopPropagation}
      >
        {buttons
          .filter(({ isEnabled }) => isEnabled)
          .map((action, index) => {
            const {
              component = IconButton,
              children = null,
              states = {},
            } = action;
            wrapStatesHandles(states, buttonClick);
            switch (component) {
              case Button: {
                return (
                  <ActionsButton
                    key={index}
                    children={children}
                    states={states}
                  />
                );
              }
              case IconButton:
              default:
                return (
                  <ActionsIconButton
                    key={index}
                    children={children}
                    states={states}
                  />
                );
            }
          })}
        {menuLists.flat(Infinity).filter(({ isEnabled }) => isEnabled).length >
        0 ? (
          <ActionsIconButton
            states={{
              normal: {
                icon: moreIcon,
                handle: menuOpen,
                inline,
                square: true,
                tooltipTitle: moreTooltipTitle,
                tooltipPlacement: moreTooltipPlacement,
              },
            }}
          >
            <Popover
              anchorEl={anchorEl}
              open={isOpen}
              onClose={menuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              marginThreshold={8}
              PaperProps={{ classes: paperClasses }}
            >
              {menuLists
                .map((menuList, index) => (
                  <MenuList key={index}>
                    {menuList
                      .filter(({ isEnabled }) => isEnabled)
                      .map((action, index) => {
                        const { children = null, states = {} } = action;
                        wrapStatesHandles(states, menuItemClick);
                        return (
                          <ActionsMenuItem
                            key={index}
                            children={children}
                            states={states}
                          />
                        );
                      })}
                  </MenuList>
                ))
                .reduce((menuLists, menuList, index) => [
                  menuLists,
                  <Divider key={`divider-${index}`} />,
                  menuList,
                ])}
            </Popover>
          </ActionsIconButton>
        ) : null}
        {children}
      </div>
    );
  }
);

export default Actions;
