import { gql } from "@apollo/client";

export const DATA_ROOM_TABLE_SHARE_EVERYTHING = gql`
  fragment DataRoomTableShareEverything on DataRoomTableShare {
    id
    dataRoomTableShareId
    dataRoomTableId
    userEmail
    updatedAt
    createdAt
    ownerEmail
  }
`;
