import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useDataRoomsTableCellStyles = makeStyles((theme: Theme) => ({
  dataRoomWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "33.33%",
  },
  contactWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    display: "flex",
    alignItems: "flex-start",
    minWidth: theme.spacing(35),
  },
}));
