// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";

const useLabelIconStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    lineHeight: 1,
    fontSize: "0.5625em",
    top: "-0.111em",
    position: "relative",
    color: "inherit",
    "&:not(:only-child)": {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const LabelIcon = memo(({ icon }) => {
  const lockClasses = useLabelIconStyles();
  const { wrapper } = lockClasses;
  return (
    <div className={wrapper}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
});

export default LabelIcon;
