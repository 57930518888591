import {
  faChevronDown,
  faChevronRight,
  faPencil,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DataRoomQuery } from "models";
import React from "react";
import { DataRoomParticipantAvatar } from "components";

interface DataRoomQueryTileProps {
  index: number;
  query: DataRoomQuery;
  readonly: boolean;
  allowEditing: boolean;
  isCollapsed: boolean;
  onEdit: (index: number) => void;
  onDelete: (query: DataRoomQuery, index: number) => void;
  onToggleCollapsion: (index: number) => void;
  expand: (index: number) => void;
  renderActions:
    | ((query: DataRoomQuery, expand?: () => void) => React.ReactNode)
    | undefined;
}

const DataRoomQueryTile: React.FC<DataRoomQueryTileProps> = ({
  index,
  query,
  readonly,
  allowEditing,
  isCollapsed,
  onDelete,
  onEdit,
  onToggleCollapsion,
  expand,
  renderActions,
}) => {
  return (
    <ListItem>
      <Grid container spacing={1}>
        <Grid item container xs={6} alignItems="center">
          <IconButton
            size="small"
            disabled={!allowEditing}
            onClick={() => onToggleCollapsion(index)}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={isCollapsed ? faChevronRight : faChevronDown}
            ></FontAwesomeIcon>
          </IconButton>
          <Typography variant="h5">{query.name}</Typography>
          <Box style={{ display: "flex" }}>
            {query.participants.map((p, i) => (
              <DataRoomParticipantAvatar key={i} name={p.userEmail} />
            ))}
          </Box>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          {!readonly && (
            <>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={() => {
                  onDelete(query, index);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth
                  color={red[200]}
                ></FontAwesomeIcon>
              </IconButton>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={() => onEdit(index)}
              >
                <FontAwesomeIcon fixedWidth icon={faPencil}></FontAwesomeIcon>
              </IconButton>
            </>
          )}
          {renderActions && renderActions(query, () => expand(index))}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default DataRoomQueryTile;
