import { faCircle } from "@fortawesome/pro-light-svg-icons";
import { faDotCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo, useMemo } from "react";

const useRadioboxStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    color: "inherit",
  },
}));

const useFontAwesomeRadioboxStyles = makeStyles({
  label: {
    width: "1rem",
    height: "1rem",
    display: "flex",
    alignItems: "inherit",
    justifyContent: "inherit",
  },
});

const FontAwesomeRadiobox = memo((props) => {
  const checkboxClasses = useRadioboxStyles();
  const fontAwesomeRadioboxClasses = useFontAwesomeRadioboxStyles();
  const { label } = fontAwesomeRadioboxClasses;
  const icon = useMemo(
    () => (
      <span className={label}>
        <FontAwesomeIcon fixedWidth icon={faCircle} />
      </span>
    ),
    [label]
  );
  const checkedIcon = useMemo(
    () => (
      <span className={label}>
        <FontAwesomeIcon fixedWidth icon={faDotCircle} />
      </span>
    ),
    [label]
  );
  const TouchRippleProps = { center: false };
  return (
    <Radio
      color="default"
      classes={checkboxClasses}
      icon={icon}
      checkedIcon={checkedIcon}
      TouchRippleProps={TouchRippleProps}
      {...props}
    />
  );
});

export default FontAwesomeRadiobox;
