import { createContext, useContext } from "react";

export const DataViewContext = createContext({
  selections: () => {},
  groupsSelections: () => {},
  columnsSelections: () => {},
  viewsSelections: () => {},
});

export const DataViewProvider = DataViewContext.Provider;

export const useDataView = () => useContext(DataViewContext);

export default useDataView;
