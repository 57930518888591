// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import { memo } from "react";
import { isElement, isValidElementType } from "react-is";
import { NavLink } from "react-router-dom";
import { useMetaStyles } from "./Meta.styles";

const Block = memo(({ children, ...props }) => (
  <span {...props}>{children}</span>
));

const Meta = memo(({ pill, icon, icon: Icon, label, value }) => {
  const metaClasses = useMetaStyles({ pill });
  return (
    <span className={metaClasses.meta}>
      {icon ? (
        <span className={metaClasses.iconWrapper}>
          {isElement(icon) ? (
            icon
          ) : isValidElementType(icon) ? (
            <Icon />
          ) : (
            <FontAwesomeIcon icon={icon} />
          )}
        </span>
      ) : null}
      <span>
        {label ? (
          <span className={metaClasses.labelWrapper}>
            {label}
            {value ? ":" : null}
          </span>
        ) : null}
        {value ? (
          <span className={metaClasses.valueWrapper}>{value}</span>
        ) : null}
      </span>
    </span>
  );
});

const MetaWrapper = memo(
  ({ tooltip = "", icon, label, value, pill, color, ...rest }) => {
    const Wrapper = rest.to ? NavLink : Block;
    return (
      <Wrapper {...rest}>
        {tooltip ? (
          <Tooltip
            title={tooltip}
            placement="top"
            disableFocusListener
            disableTouchListener
            enterDelay={1000}
            enterNextDelay={250}
          >
            <span>
              <Meta pill={pill} icon={icon} label={label} value={value} />
            </span>
          </Tooltip>
        ) : (
          <span>
            <Meta pill={pill} icon={icon} label={label} value={value} />
          </span>
        )}
      </Wrapper>
    );
  }
);

export default MetaWrapper;
