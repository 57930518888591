import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_SHARE_EVERYTHING } from "gqls";

export const DELETE_DATA_ROOM_QUERY_SHARE = gql`
  mutation deleteDataRoomQueryShare($input: DeleteDataRoomQueryShareInput!) {
    deleteDataRoomQueryShare(input: $input) {
      clientMutationId
      dataRoomQueryShare {
        ...DataRoomQueryShareEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_SHARE_EVERYTHING}
`;
