"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisWaConnector = exports.proto = exports.Schema = exports.Key = void 0;
const core_1 = require("@decentriq/core");
Object.defineProperty(exports, "Key", { enumerable: true, get: function () { return core_1.Key; } });
Object.defineProperty(exports, "Schema", { enumerable: true, get: function () { return core_1.Schema; } });
Object.defineProperty(exports, "proto", { enumerable: true, get: function () { return core_1.Proto; } });
function pollSqlQuery(session, dataRoomHash, queryName) {
    return __awaiter(this, void 0, void 0, function* () {
        while (true) {
            const result = yield session.makeSqlQuery(dataRoomHash, queryName);
            if (result === null) {
                yield new Promise(r => setTimeout(r, 1000));
            }
            else {
                return result;
            }
        }
    });
}
class DisWaConnector {
    constructor(client, email, key) {
        this.client = client;
        this.userId = email.toLowerCase();
        this.key = key;
        this.session = null;
        this.mrenclave = undefined;
    }
    static withToken(token, email, key, host, port, useTLS, clientId = "MHyVW112w7Ql95G96fn9rnLWkYuOLmdk") {
        const client = new core_1.Client({
            type: "app",
            value: token,
        }, clientId, host, port, useTLS);
        return new DisWaConnector(client, email.toLowerCase(), key);
    }
    static withUserToken(token, email, key, host, port, useTLS, clientId = "MHyVW112w7Ql95G96fn9rnLWkYuOLmdk") {
        const client = new core_1.Client({
            type: "user",
            value: token,
        }, clientId, host, port, useTLS);
        return new DisWaConnector(client, email.toLowerCase(), key);
    }
    connect(options, password, enclaveIdentifier) {
        return __awaiter(this, void 0, void 0, function* () {
            this.mrenclave = enclaveIdentifier;
            if (this.mrenclave === undefined) {
                const mrenclaves = yield this.client.getEnclaveIdentifiers();
                this.mrenclave = mrenclaves.reduce((prev, curr) => prev = +prev.version > +curr.version ? prev : curr);
            }
            const auth = yield this.client.createAuth(this.userId, password);
            const session = yield this.client.createSession(this.mrenclave, { [this.userId]: auth }, options);
            this.session = session;
        });
    }
    getRootCaCert() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.client.getCARootCertificate();
        });
    }
    createDataRoom(dr) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            const response = yield this.session.createDataRoom(dr);
            return response;
        });
    }
    ingestData(sqlCreateStatement, csv, dataRoomHash) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            const schema = new core_1.Schema(sqlCreateStatement);
            const data = new TextEncoder().encode(csv);
            const manifestHash = yield this.client.uploadDataset(this.userId, schema.tableName, data, schema, this.key);
            // always validate.
            const validate = yield this.session.validateDataset(manifestHash, this.key);
            if (validate.failure == null) {
                // only publish if the table passes
                yield this.session.publishDatasetToDataRoom(manifestHash, dataRoomHash, schema.tableName, this.key);
                return manifestHash;
            }
            // here, we got a validation failure
            throw new Error(`Table validation failed, got ${validate.failure.message}`);
        });
    }
    runQuery(sqlSelectStatement, dataRoomHash) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            const results = yield pollSqlQuery(this.session, dataRoomHash, sqlSelectStatement);
            return results;
        });
    }
    getStatus(dataRoomHash) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            const result = yield this.session.retrieveDataRoomStatus(dataRoomHash);
            return result.status.toString();
        });
    }
    updateStatus(dataRoomHash, status) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            yield this.session.updateDataRoomStatus(dataRoomHash, status);
        });
    }
    getPublishedDatasets(dataRoomHash) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            return (yield this.session.retrieveProvisionedDatasets(dataRoomHash)).publishedDatasets || [];
        });
    }
    removePublishedDataset(dataRoomHash, manifestHash, tableName) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.session === null)
                throw new Error("Not connected. Call connect() first.");
            yield this.session.removePublishedDataset(manifestHash, dataRoomHash, tableName);
        });
    }
}
exports.DisWaConnector = DisWaConnector;
