// @ts-nocheck
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Divider,
  IconButton,
  Popover,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Fragment, memo } from "react";
import {
  AttributesMenu,
  GroupsMenu,
  ShowColumnsMenu,
  ViewsMenu,
} from "components";
import { useDataView } from "contexts";
import { useViewSettingsStyles } from "./ViewSettingsMenu.styles";

const ViewSettingsMenuButton = memo(({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <FontAwesomeIcon fixedWidth icon={faCog} />
    </IconButton>
  );
});

const isGroupByMenuEnabled = false;

// TODO: Configure MuiPaper in the <StyleWrapper />
const usePaperStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow:
      "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
  },
}));

const ViewSettingsMenuWithTooltip = memo(() => {
  const { groupsSelections, columnsSelections, viewsSelections } =
    useDataView();
  const paperClasses = usePaperStyles();
  const viewSettingsClasses = useViewSettingsStyles();
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
  });
  const menuOpen = (event) => {
    event.stopPropagation();
    open(event.currentTarget);
  };
  const menuClose = (event) => {
    event.stopPropagation();
    close();
  };
  return (
    <Fragment>
      <Tooltip
        title="Settings"
        placement="top-end"
        disableFocusListener
        disableTouchListener
      >
        <div>
          <ViewSettingsMenuButton onClick={menuOpen} />
        </div>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        marginThreshold={8}
        PaperProps={{ classes: paperClasses }}
      >
        <Box className={viewSettingsClasses.groupName}>View density</Box>
        <ViewsMenu viewsWithSelections={viewsSelections} />
        <Divider />
        <Box className={viewSettingsClasses.groupName}>Show columns</Box>
        <ShowColumnsMenu columnsWithSelections={columnsSelections} />
        {isGroupByMenuEnabled ? (
          <>
            <Divider />
            <Box className={viewSettingsClasses.groupName}>
              Group by location
            </Box>
            <GroupsMenu groupsWithSelections={groupsSelections} />
            <Divider />
            <Box className={viewSettingsClasses.groupName}>
              Group by attribute
            </Box>
            <AttributesMenu />
          </>
        ) : null}
      </Popover>
    </Fragment>
  );
});

export default ViewSettingsMenuWithTooltip;
