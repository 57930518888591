import {
  faEllipsisVertical,
  faPencil,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton, ListItem, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import {
  DataRoomTableColumnDefinition,
  DataRoomTablePrimitiveType,
} from "models";
import React, { memo, useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { dataRoomTablePrimitiveTypePresentation } from "./DataRoomTableConstructorModels";

interface DataRoomTableColumnTileProps {
  index: number;
  column: DataRoomTableColumnDefinition;
  allowEditing: boolean;
  readonly?: boolean;
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  onShuffle: (dragIndex: number, hoverIndex: number) => void;
}

const DataRoomTableColumnTile: React.FC<DataRoomTableColumnTileProps> = ({
  index,
  column,
  readonly,
  allowEditing,
  onDelete,
  onEdit,
  onShuffle,
}) => {
  const ref = useRef<HTMLLIElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "column-tile",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: { index: number }, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onShuffle(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "column-tile",
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !readonly,
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <ListItem
      style={{ opacity }}
      ref={ref}
      onDoubleClick={() => {
        if (allowEditing) {
          onEdit(index);
        }
      }}
    >
      <Grid container spacing={1}>
        <Grid item container xs={5} alignItems="center">
          {!readonly && (
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              style={{ marginRight: "16px", cursor: "grab" }}
              fixedWidth
              data-handler-id={handlerId}
            ></FontAwesomeIcon>
          )}
          <Typography variant="body1">{column.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">
            {dataRoomTablePrimitiveTypePresentation.get(
              column.primitiveType as DataRoomTablePrimitiveType
            )}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1">
            {column.nullable ? "NULL" : "NOT NULL"}
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          {!readonly && (
            <>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={onDelete.bind(null, index)}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  fixedWidth
                  color={red[200]}
                ></FontAwesomeIcon>
              </IconButton>
              <IconButton
                size="small"
                disabled={!allowEditing}
                onClick={onEdit.bind(null, index)}
              >
                <FontAwesomeIcon fixedWidth icon={faPencil}></FontAwesomeIcon>
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default memo(DataRoomTableColumnTile);
