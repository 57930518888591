import { Container, Typography } from "@material-ui/core";

const FourOFourPage = () => {
  return (
    <Container maxWidth={false}>
      <Typography variant="h6">
        <strong>404</strong>
      </Typography>
    </Container>
  );
};

export default FourOFourPage;
