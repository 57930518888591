import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_SHARE_EVERYTHING } from "./dataRoomQueryShareEverything";

export const DATA_ROOM_QUERY_EVERYTHING = gql`
  fragment DataRoomQueryEverything on DataRoomQuery {
    id
    dataRoomQueryId
    dataRoomId
    name
    index
    sqlSelectStatement
    dataAnalysts
    updatedAt
    createdAt
    ownerEmail
    dataRoom {
      id
    }
    dataRoomQueryShares {
      totalCount
      nodes {
        ...DataRoomQueryShareEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_SHARE_EVERYTHING}
`;
