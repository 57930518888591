import { DisWaConnector } from "@decentriq/safequery/lib/api";
import { createContext, useContext } from "react";

export interface GetClientOptions {
  password?: string;
}

export interface ApiCoreContextInterface {
  getClient: (options?: GetClientOptions) => Promise<DisWaConnector>;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <ApiCoreProvider>.");
};

const initialContext = {
  getClient: stub,
};

export const ApiCoreContext =
  createContext<ApiCoreContextInterface>(initialContext);

export const ApiCoreProvider = ApiCoreContext.Provider;

export const useApiCore = () => useContext(ApiCoreContext);

export default useApiCore;
