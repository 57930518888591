import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { memo } from "react";

const useSnackbarStyles = makeStyles((theme: Theme) => ({
  containerRoot: {
    pointerEvents: "all",
  },
}));

const SnackbarWrapper = memo(({ children }: any) => {
  const snackbarClasses = useSnackbarStyles();
  return (
    <SnackbarProvider
      maxSnack={3}
      classes={snackbarClasses}
      iconVariant={{
        error: (
          <Box
            sx={{
              marginRight: "8px",
            }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faCircleExclamation}
            ></FontAwesomeIcon>
          </Box>
        ),
      }}
    >
      {children}
    </SnackbarProvider>
  );
});

export default SnackbarWrapper;
