// @ts-nocheck
import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { Select, TableSortLabelWithOrderBy } from "components";
import { useDataRoomsTableCellStyles } from "components/entities/dataRooms/DataRoomsTable/DataRoomsTable.styles";
import { useData, useDataView } from "contexts";
import { useCustomTableStyles } from "styles/table";

const DataRoomsTableHeadRow = ({
  withSelections = false,
  withMultiselections = false,
  forceWideCells = false,
}: any) => {
  const customTableClasses = useCustomTableStyles({ forceWideCells });
  const dataRoomsTableCellClasses = useDataRoomsTableCellStyles();
  const { orderBy, setOrderBy } = useData();
  const { columnsSelections } = useDataView();
  const { isSelected } = columnsSelections;
  return (
    <TableRow component="div">
      {(withMultiselections || withSelections) && (
        <TableCell component="div" padding="checkbox">
          {withMultiselections && <Select.All />}
        </TableCell>
      )}
      <TableCell component="div" padding="none">
        {isSelected("dataRoom") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.dataRoomWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            <TableSortLabelWithOrderBy
              {...{
                label: "DataRoom",
                orderByAsc: ["NAME_ASC"],
                orderByDesc: ["NAME_DESC"],
                orderBy,
                setOrderBy,
              }}
            />
          </TableCell>
        )}
        {isSelected("owner") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            Owner
          </TableCell>
        )}
        {isSelected("participants") && (
          <TableCell
            component="div"
            className={clsx(
              dataRoomsTableCellClasses.contactWrapper,
              customTableClasses.MuiTableCell,
              customTableClasses.MuiTableCellAsEntity
            )}
          >
            Participants
          </TableCell>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DataRoomsTableHeadRow;
