// @ts-nocheck
import { useMutation, useQuery } from "@apollo/client";
import { difference } from "lodash";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import DataRoomParticipantsConstructor from "components/entities/dataRoom/DataRoomParticipants/DataRoomParticipants";
import {
  CREATE_DATA_ROOM_QUERY_SHARE,
  CREATE_DATA_ROOM_SHARE,
  CREATE_DATA_ROOM_TABLE_SHARE,
  DATA_ROOM,
  DELETE_DATA_ROOM_QUERY_SHARE,
  DELETE_DATA_ROOM_SHARE,
  DELETE_DATA_ROOM_TABLE_SHARE,
} from "gqls";

const DataRoomParticipants = ({ mode }: any) => {
  const { dataRoomId } = useParams();
  const { data } = useQuery(DATA_ROOM, {
    variables: {
      dataRoomId,
    },
    onError: (error) => {
      enqueueSnackbar("Can't fetch data room from the database", {
        variant: "error",
      });
    },
  });
  const getCurrentDataRoomTableIds = (userEmail: any) =>
    data?.dataRoom?.dataRoomTables?.nodes
      ?.map(({ dataRoomTableShares }: any) =>
        dataRoomTableShares?.nodes
          ?.filter(
            ({ userEmail: dataTableShareUserEmail }: any) =>
              dataTableShareUserEmail === userEmail
          )
          .map(({ dataRoomTableId }: any) => dataRoomTableId)
      )
      .flat(Infinity) || [];
  const getCurrentDataRoomQueryIds = (userEmail: any) =>
    data?.dataRoom?.dataRoomQueries?.nodes
      ?.map(({ dataRoomQueryShares }: any) =>
        dataRoomQueryShares?.nodes
          ?.filter(
            ({ userEmail: dataQueryShareUserEmail }: any) =>
              dataQueryShareUserEmail === userEmail
          )
          .map(({ dataRoomQueryId }: any) => dataRoomQueryId)
      )
      .flat(Infinity) || [];
  const { enqueueSnackbar } = useSnackbar();
  const [createDataRoomShareMutation] = useMutation(CREATE_DATA_ROOM_SHARE, {
    onError: () => {
      enqueueSnackbar("Can't create data room table", { variant: "error" });
    },
  });
  const [createDataRoomTableShareMutation] = useMutation(
    CREATE_DATA_ROOM_TABLE_SHARE,
    {
      onError: () => {
        enqueueSnackbar("Can't create data room table share", {
          variant: "error",
        });
      },
    }
  );
  const [createDataRoomQueryShareMutation] = useMutation(
    CREATE_DATA_ROOM_QUERY_SHARE,
    {
      onError: () => {
        enqueueSnackbar("Can't create data room query share", {
          variant: "error",
        });
      },
    }
  );
  const [deleteDataRoomShareMutation] = useMutation(DELETE_DATA_ROOM_SHARE, {
    onError: () => {
      enqueueSnackbar("Can't create data room table", { variant: "error" });
    },
  });
  const [deleteDataRoomTableShareMutation] = useMutation(
    DELETE_DATA_ROOM_TABLE_SHARE,
    {
      onError: () => {
        enqueueSnackbar("Can't delete data room table share", {
          variant: "error",
        });
      },
    }
  );
  const [deleteDataRoomQueryShareMutation] = useMutation(
    DELETE_DATA_ROOM_QUERY_SHARE,
    {
      onError: () => {
        enqueueSnackbar("Can't delete data room table share", {
          variant: "error",
        });
      },
    }
  );
  const onCreate = ({ userEmail, tables, queries }: any) => {
    createDataRoomShareMutation({
      variables: {
        input: {
          dataRoomShare: {
            dataRoomId,
            userEmail,
          },
        },
      },
      refetchQueries: ["dataRoom"],
    });
    tables.forEach((dataRoomTableId: any) =>
      createDataRoomTableShareMutation({
        variables: {
          input: {
            dataRoomTableShare: {
              dataRoomTableId,
              userEmail,
            },
          },
        },
        refetchQueries: ["dataRoom"],
      })
    );
    queries.forEach((dataRoomQueryId: any) =>
      createDataRoomQueryShareMutation({
        variables: {
          input: {
            dataRoomQueryShare: {
              dataRoomQueryId,
              userEmail,
            },
          },
        },
        refetchQueries: ["dataRoom"],
      })
    );
  };
  const onUpdate = ({ userEmail, tables, queries }: any) => {
    const currentDataRoomTableIds = getCurrentDataRoomTableIds(userEmail);
    const currentDataRoomQueryIds = getCurrentDataRoomQueryIds(userEmail);
    const dataRoomTableIdsToDelete = difference(
      currentDataRoomTableIds,
      tables
    );
    const dataRoomQueryIdsToDelete = difference(
      currentDataRoomQueryIds,
      queries
    );
    const dataRoomTableIdsToAdd = difference(tables, currentDataRoomTableIds);
    const dataRoomQueryIdsToAdd = difference(queries, currentDataRoomQueryIds);
    dataRoomTableIdsToDelete.forEach((dataRoomTableIdToDelete: any) => {
      data?.dataRoom?.dataRoomTables?.nodes?.map(
        ({ dataRoomTableShares }: any) =>
          dataRoomTableShares?.nodes?.forEach(
            ({
              userEmail: dataTableShareUserEmail,
              dataRoomTableShareId,
              dataRoomTableId,
            }: any) => {
              if (
                dataTableShareUserEmail === userEmail &&
                dataRoomTableId === dataRoomTableIdToDelete
              ) {
                deleteDataRoomTableShareMutation({
                  variables: {
                    input: {
                      dataRoomTableShareId,
                    },
                  },
                  refetchQueries: ["dataRoom"],
                });
              }
            }
          )
      );
    });
    dataRoomQueryIdsToDelete.forEach((dataRoomQueryIdToDelete: any) => {
      data?.dataRoom?.dataRoomQueries?.nodes?.map(
        ({ dataRoomQueryShares }: any) =>
          dataRoomQueryShares?.nodes?.forEach(
            ({
              userEmail: dataQueryShareUserEmail,
              dataRoomQueryShareId,
              dataRoomQueryId,
            }: any) => {
              if (
                dataQueryShareUserEmail === userEmail &&
                dataRoomQueryId === dataRoomQueryIdToDelete
              ) {
                deleteDataRoomQueryShareMutation({
                  variables: {
                    input: {
                      dataRoomQueryShareId,
                    },
                  },
                  refetchQueries: ["dataRoom"],
                });
              }
            }
          )
      );
    });
    dataRoomTableIdsToAdd.forEach((dataRoomTableId: any) =>
      createDataRoomTableShareMutation({
        variables: {
          input: {
            dataRoomTableShare: {
              dataRoomTableId,
              userEmail,
            },
          },
        },
        refetchQueries: ["dataRoom"],
      })
    );
    dataRoomQueryIdsToAdd.forEach((dataRoomQueryId: any) =>
      createDataRoomQueryShareMutation({
        variables: {
          input: {
            dataRoomQueryShare: {
              dataRoomQueryId,
              userEmail,
            },
          },
        },
        refetchQueries: ["dataRoom"],
      })
    );
  };
  const onDelete = ({ userEmail }: any) => {
    data?.dataRoom?.dataRoomShares?.nodes?.forEach(
      ({ dataRoomShareId, userEmail: dataRoomShareUserEmail }: any) => {
        if (dataRoomShareUserEmail === userEmail) {
          deleteDataRoomShareMutation({
            variables: {
              input: {
                dataRoomShareId,
              },
            },
            refetchQueries: ["dataRoom"],
          });
        }
      }
    );
    data?.dataRoom?.dataRoomTables?.nodes?.map(({ dataRoomTableShares }: any) =>
      dataRoomTableShares?.nodes?.forEach(
        ({ userEmail: dataTableShareUserEmail, dataRoomTableShareId }: any) => {
          if (dataTableShareUserEmail === userEmail) {
            deleteDataRoomTableShareMutation({
              variables: {
                input: {
                  dataRoomTableShareId,
                },
              },
              refetchQueries: ["dataRoom"],
            });
          }
        }
      )
    );
    data?.dataRoom?.dataRoomQueries?.nodes?.map(
      ({ dataRoomQueryShares }: any) =>
        dataRoomQueryShares?.nodes?.forEach(
          ({
            userEmail: dataQueryShareUserEmail,
            dataRoomQueryShareId,
          }: any) => {
            if (dataQueryShareUserEmail === userEmail) {
              deleteDataRoomQueryShareMutation({
                variables: {
                  input: {
                    dataRoomQueryShareId,
                  },
                },
                refetchQueries: ["dataRoom"],
              });
            }
          }
        )
    );
  };
  const participants =
    data?.dataRoom?.dataRoomShares?.nodes?.map(({ userEmail }: any) => ({
      userEmail,
      tables: getCurrentDataRoomTableIds(userEmail),
      queries: getCurrentDataRoomQueryIds(userEmail),
    })) || [];
  return data ? (
    <DataRoomParticipantsConstructor
      mode={mode}
      participants={participants}
      tables={data?.dataRoom?.dataRoomTables?.nodes || []}
      queries={data?.dataRoom?.dataRoomQueries?.nodes || []}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  ) : null;
};

export default DataRoomParticipants;
