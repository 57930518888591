// @ts-nocheck
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useGroupsMenuItemStyles = makeStyles((theme: Theme) => ({
  menuItem: {},
  menuItemIcon: {
    margin: theme.spacing(0.375, 0),
  },
  menuItemLabel: {
    marginLeft: theme.spacing(1),
  },
  menuItemLabelTitle: {
    fontWeight: (props) => (props.isSelected ? 500 : 400),
  },
  menuItemLabelInfo: {
    fontSize: "smaller",
    lineHeight: 1,
    color: "grey",
  },
}));
