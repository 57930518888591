import { gql } from "@apollo/client";
import { DATA_ROOM_QUERY_SHARE_EVERYTHING } from "gqls";

export const CREATE_DATA_ROOM_QUERY_SHARE = gql`
  mutation createDataRoomQueryShare($input: CreateDataRoomQueryShareInput!) {
    createDataRoomQueryShare(input: $input) {
      clientMutationId
      dataRoomQueryShare {
        ...DataRoomQueryShareEverything
      }
    }
  }
  ${DATA_ROOM_QUERY_SHARE_EVERYTHING}
`;
