import { memo } from "react";
import { EventsWorker } from "workers";

const WorkersWrapper = memo(({ children }: any) => {
  return (
    <>
      <EventsWorker />
      {children}
    </>
  );
});

export default WorkersWrapper;
