import { faSortAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { usePopupState } from "material-ui-popup-state/hooks";

const OrderByMenu = ({
  orderBy: currentOrderBy,
  orderByOptions = [],
  onChange,
}: any) => {
  const { anchorEl, isOpen, open, close } = usePopupState({
    variant: "popover",
    popupId: "orderby-menu",
  });
  const menuOpen = (event: any) => open(event.currentTarget);
  const menuClose = () => close();
  const optionClick = (option: any) => {
    onChange(option);
    close();
  };
  return orderByOptions.length > 0 ? (
    <>
      <Button onClick={menuOpen}>
        <FontAwesomeIcon fixedWidth icon={faSortAlt} />
        {
          // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'orderBy' implicitly has an 'any' ... Remove this comment to see the full error message
          orderByOptions.find(({ orderBy }) => isEqual(currentOrderBy, orderBy))
            ?.label
        }
      </Button>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={menuClose}
      >
        {/* @ts-expect-error ts-migrate(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message */}
        {orderByOptions.map(({ label, orderBy }) => (
          <MenuItem
            key={label}
            selected={isEqual(currentOrderBy, orderBy)}
            onClick={optionClick.bind(null, orderBy)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};

export default OrderByMenu;
