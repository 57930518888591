import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCheck, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import { useDebounceFn, useSelections } from "ahooks";
import { Field, Form, Formik } from "formik";
import { DataRoomQuery, DataRoomQueryDefinition } from "models";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { dataRoomQueryInitialValues } from "./DataRoomQueryConstructorInitialValues";
import { dataRoomQueryValidationSchema } from "./DataRoomQueryConstructorValidationScheme";
import DataRoomQueryEditor from "./DataRoomQueryEditor";
import DataRoomQueryTile from "./DataRoomQueryTile";

export enum DataRoomQueryConstructorMode {
  EDIT = "edit",
  READONLY = "readonly",
  ACTION = "action",
}

interface DataRoomQueryConstructorModeFlags {
  readonly: boolean;
  withActions: boolean;
}

interface DataRoomQueryConstructorProps {
  queries: DataRoomQuery[];
  mode: DataRoomQueryConstructorMode;
  onCreate: (queryDefinition: DataRoomQueryDefinition) => void;
  onUpdate: (query: DataRoomQuery) => void;
  onSqlSelectStatementUpdate: (
    dataRoomQueryId: string,
    sqlSelectStatement: string
  ) => void;
  onDelete: (query: DataRoomQuery) => void;
  renderActions?: (
    query: DataRoomQuery,
    expand?: () => void
  ) => React.ReactNode;
  renderResult?: (query: DataRoomQuery) => React.ReactNode;
}

interface DataRoomQueryFormProps {
  onSubmit: (tableDefinition: DataRoomQueryDefinition) => void;
  onCancel?: () => void;
  isEditing?: boolean;
  disabled?: boolean;
  initialValues?: DataRoomQueryDefinition;
}

interface DataRoomQueryFormProps {
  onSubmit: (queryDefinition: DataRoomQueryDefinition) => void;
  onCancel?: () => void;
  isEditing?: boolean;
  disabled?: boolean;
  initialValues?: DataRoomQueryDefinition;
}

const DataRoomQueryForm: React.FC<DataRoomQueryFormProps> = ({
  initialValues = dataRoomQueryInitialValues,
  isEditing = false,
  onSubmit,
  onCancel,
  disabled,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(query, helpers) => {
      onSubmit(query);
      helpers.resetForm();
    }}
    validationSchema={dataRoomQueryValidationSchema}
    validateOnMount
  >
    {(formProps) => (
      <Form style={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item container xs={12} flexWrap="nowrap" alignItems="flex-end">
            <Field<
              DataRoomQueryDefinition["name"],
              DataRoomQueryDefinition
            > name="name">
              {({ field }) => (
                <FormControl fullWidth>
                  <FormLabel component="legend" style={{ fontSize: ".75rem" }}>
                    Query name
                  </FormLabel>
                  <TextField
                    fullWidth
                    disabled={disabled}
                    autoComplete="off"
                    size="small"
                    {...field}
                    variant="outlined"
                  ></TextField>
                </FormControl>
              )}
            </Field>
            {isEditing && (
              <IconButton size="small" onClick={onCancel}>
                <FontAwesomeIcon icon={faTimes} fixedWidth></FontAwesomeIcon>
              </IconButton>
            )}
            <IconButton
              size="small"
              disabled={!formProps.isValid}
              type="submit"
            >
              <FontAwesomeIcon
                icon={isEditing ? faCheck : faPlus}
                fixedWidth
              ></FontAwesomeIcon>
            </IconButton>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
);

const DataRoomQueryConstructor: React.FC<DataRoomQueryConstructorProps> = ({
  queries,
  onCreate,
  onUpdate,
  onSqlSelectStatementUpdate,
  onDelete,
  mode,
  renderActions,
  renderResult,
}) => {
  const [rowToEditIndex, setRowToEditIndex] = useState<number>(-1);
  const indexes = useMemo(
    () => Array.from({ length: queries.length }).map((_, i) => i),
    [queries.length]
  );
  const { isSelected, select, toggle } = useSelections(indexes, []);
  const hasEditableQuery = rowToEditIndex !== -1;
  const { readonly, withActions }: DataRoomQueryConstructorModeFlags = useMemo(
    () => ({
      readonly: mode !== DataRoomQueryConstructorMode.EDIT,
      withActions: mode === DataRoomQueryConstructorMode.ACTION,
    }),
    [mode]
  );
  const handleDelete = useCallback(
    (query: DataRoomQuery) => {
      onDelete(query);
    },
    [onDelete]
  );
  const { run: debouncedStatementUpdate } = useDebounceFn(
    onSqlSelectStatementUpdate,
    {
      wait: 750,
    }
  );
  return (
    <Box>
      {queries.length > 0 && (
        <List
          style={{ background: "#f9f9f9", padding: 0, marginBottom: "8px" }}
        >
          {queries.map((query, index) => (
            <Fragment key={index}>
              {rowToEditIndex === index ? (
                <ListItem>
                  <DataRoomQueryForm
                    onSubmit={(queryDefinition) => {
                      onUpdate({
                        ...query,
                        ...queryDefinition,
                      });
                      setRowToEditIndex(-1);
                    }}
                    isEditing
                    onCancel={() => setRowToEditIndex(-1)}
                    initialValues={query}
                  ></DataRoomQueryForm>
                </ListItem>
              ) : (
                <DataRoomQueryTile
                  index={index}
                  query={query}
                  readonly={readonly}
                  allowEditing={!hasEditableQuery}
                  isCollapsed={!isSelected(index)}
                  onEdit={setRowToEditIndex}
                  onDelete={handleDelete}
                  onToggleCollapsion={toggle}
                  expand={select}
                  renderActions={withActions ? renderActions : undefined}
                />
              )}
              <Collapse in={isSelected(index)} timeout="auto" unmountOnExit>
                <ListItem>
                  <Grid container>
                    <Grid item xs={12}>
                      <DataRoomQueryEditor
                        dataRoomQueryId={query.dataRoomQueryId}
                        defaultValue={query.sqlSelectStatement}
                        isSaving={!!query.isSaving}
                        readonly={readonly}
                        updatedAt={query.updatedAt}
                        onChange={debouncedStatementUpdate}
                      />
                      {renderResult && renderResult(query)}
                    </Grid>
                  </Grid>
                </ListItem>
              </Collapse>
              {index !== queries.length - 1 && (
                <Divider style={{ borderBottomWidth: "1px" }} />
              )}
            </Fragment>
          ))}
        </List>
      )}
      {!readonly && (
        <>
          <List style={{ background: "#f9f9f9", padding: "8px" }}>
            <ListItem style={{ padding: 0 }}>
              <DataRoomQueryForm
                onSubmit={(query) => {
                  onCreate(query);
                  select(queries.length);
                }}
                disabled={hasEditableQuery}
              ></DataRoomQueryForm>
            </ListItem>
          </List>
        </>
      )}
    </Box>
  );
};

export default DataRoomQueryConstructor;
