// @ts-nocheck
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const useDrawerAccordionStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "white",
    "&:not(:first-of-type)": {
      marginTop: 0,
    },
    "&.Mui-disabled": {
      backgroundColor: "transparent",
    },
  },
}));

export const useDrawerAccordionSummaryStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "auto",
    padding: ({ compact }) =>
      compact ? theme.spacing(0.25, 0) : theme.spacing(0.5, 0),
    "&.Mui-expanded": {
      minHeight: "auto",
    },
    "&:hover:not(.Mui-disabled)": {
      background: theme.palette.action.hover,
    },
  },
  content: {
    order: 2,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  expandIconWrapper: {
    order: 1,
    margin: theme.spacing(0, 0.5, 0, 1.5),
    "&.Mui-expanded": {
      transform: ({ hasOwnExpandIcon }) =>
        hasOwnExpandIcon ? "none" : "rotate(90deg)",
    },
  },
}));

export const useDrawerAccordionDetailsStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
}));

export const useDrawerAccordionSummaryContentStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      flex: "1 1 auto",
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.25px",
      lineHeight: 1,
      textTransform: "uppercase",
      opacity: 0.75,
      display: "flex",
      justifyContent: "space-between",
      marginRight: theme.spacing(1.5),
    },
    nameWrapper: {
      display: "flex",
      alignItems: "center",
    },
    totalCountActionsWrapper: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "center",
      opacity: 0.75,
      transition: "all 0.4s ease-in-out",
      "&:hover": {
        opacity: 1,
      },
    },
    totalCountWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:only-child": {
        minWidth: theme.spacing(3),
      },
    },
    actionsWrapper: {
      display: "flex",
      "&:not(:empty):not(:only-child)": {
        marginLeft: theme.spacing(0.75),
      },
      "&:empty ~ $totalCountWrapper": {
        minWidth: theme.spacing(3),
      },
    },
  })
);

export const useDrawerAccordionDetailsContentStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      padding: theme.spacing(0, 0.75, 0.75),
    },
  })
);
