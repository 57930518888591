// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemIcon, ListItemText, MenuItem, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";
import { isElement, isValidElementType } from "react-is";
import { NavLink } from "react-router-dom";

const useListItemStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "inherit",
  },
  dense: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  gutters: {
    paddingLeft: ({ wrapper }) => theme.spacing(wrapper === MenuItem ? 2 : 1),
    paddingRight: ({ wrapper }) => theme.spacing(wrapper === MenuItem ? 2 : 1),
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const useListItemIconStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "inherit",
    fontSize: theme.typography.htmlFontSize,
    marginRight: theme.spacing(1),
    minWidth: 0,
    width: theme.spacing(2),
  },
}));

const MainbarMenuItem = memo(
  ({
    wrapper: Wrapper = MenuItem,
    component: Component,
    icon,
    icon: Icon,
    text,
    ...rest
  }) => {
    const listItemClasses = useListItemStyles({ wrapper: Wrapper });
    const listItemIconClasses = useListItemIconStyles();
    return text ? (
      <Wrapper
        dense
        classes={listItemClasses}
        component={Component}
        {...(Component === NavLink
          ? { activeClassName: listItemClasses.selected }
          : {})}
        {...rest}
      >
        {isElement(icon) ? (
          <ListItemIcon classes={listItemIconClasses}>{icon}</ListItemIcon>
        ) : isValidElementType(icon) ? (
          <ListItemIcon classes={listItemIconClasses}>
            <Icon />
          </ListItemIcon>
        ) : icon ? (
          <ListItemIcon classes={listItemIconClasses}>
            <FontAwesomeIcon fixedWidth icon={icon} />
          </ListItemIcon>
        ) : null}
        {text ? (
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              sx: {
                display: "flex",
                alignItems: "center",
                fontWeight: "inherit",
                lineHeight: "1rem",
                whiteSpace: "nowrap",
                fontSize: "0.875rem",
              },
            }}
          />
        ) : null}
      </Wrapper>
    ) : null;
  }
);

export default MainbarMenuItem;
